import React, { useEffect, useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Header from "@socion-cordio/common/src/components/organisms/topNavbar";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import StaticList from "@socion-cordio/common/src/components/molecules/staticList";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { useSelector } from "react-redux";
import Loader from "@socion-cordio/common/src/components/atoms/loader";

interface IProps {
  customLoadingContainer?: StyleProp<ViewStyle>;
}

const StaticPage = (props: IProps) => {
  const { customLoadingContainer } = props;

  const [entityName, setEntityName] = useState("SPRINGSHED NAVBAR");
  const [widthCollapse, setWidthCollapse] = useState(undefined);
  const programsState = useSelector((state: any) => state.programs);

  useEffect(() => {
    setEntityName(programsState?.selectedProgramDetails?.program?.entityData?.entity?.name);
  }, []);

  const getSidePanelWidth = (width: any, isExpanded: boolean) => {
    setWidthCollapse(width);
  };

  const DATA = [];

  DATA.push(
    {
      id: 1,
      title: "Entity",
      iconName: IconNames.entity,
      path: `${routeNames.app}${routeNames.ENTITY}`,
      eventAnalytics: "Workspace_Sidebar_Entity"
    },
    {
      id: 2,
      title: "Program",
      iconName: IconNames.program,
      path: `${routeNames.app}${routeNames.PROGRAM}`,
      eventAnalytics: "Workspace_Sidebar_Program"
    },
    {
      id: 3,
      title: "Topics",
      iconName: IconNames.topics,
      path: `${routeNames.app}${routeNames.TOPICS}`,
      eventAnalytics: "Workspace_Sidebar_Topics"
    },
    {
      id: 4,
      title: "Sessions",
      iconName: IconNames.session,
      path: `${routeNames.app}${routeNames.SESSION}`,
      eventAnalytics: "Workspace_Sidebar_Sessions"
    }
  );

  const PROFILE_DATA = [
    {
      id: 1,
      title: "Personal Information",
      iconName: IconNames.personalInfo,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PERSONALINFO}`,
      eventAnalytics: "SideBar_Personal_Information"
    },
    {
      id: 2,
      title: "My Account",
      iconName: IconNames.UserAccount,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.MYACCOUNT}`,
      eventAnalytics: "SideBar_Change_Password"
    },
    {
      id: 3,
      title: "About",
      iconName: IconNames.about,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.ABOUT}`,
      eventAnalytics: "SideBar_About"
    },
    {
      id: 4,
      title: "Privacy Policy",
      iconName: IconNames.privacyPolicy,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.PRIVACYPOLICY}`,
      eventAnalytics: "SideBar_Privacy_Policy"
    },
    {
      id: 5,
      title: "Terms of Use",
      iconName: IconNames.termsOfUse,
      path: `${routeNames.app}${routeNames.PROFILE}${routeNames.TERMSOFUSE}`,
      eventAnalytics: "SideBar_Terms_Of_Use"
    },
    {
      id: 99,
      title: "Logout",
      iconName: IconNames.logOut,
      eventAnalytics: "SideBar_Logout"
    }
  ];

  return (
    <View>
      {window.location.pathname.includes("/new/app") &&
      !window.location.pathname.includes("/new/app/accomplishment") ? (
        <View style={[styles.loadingContainer, customLoadingContainer]}>
          <div>
            <Header currentEntityName={entityName} />
          </div>
          {!window.location.pathname.includes("/new/app/workspace") && (
            <div>
              <StaticList
                getSidePanelWidth={getSidePanelWidth}
                data={window.location.pathname.includes("/new/app/profile") ? PROFILE_DATA : DATA}
              />
            </div>
          )}
        </View>
      ) : (
        <View style={[styles.loadingContainer2, customLoadingContainer]}>
          <Loader />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    height: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    top: 76
  },
  loadingContainer2: {
    height: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    top: 70
  }
});

export default StaticPage;
