import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import moment from "moment";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
interface Props {
  data?: any;
  programDetails?: any;
}

export default function QPDetails(props: Props): ReactElement {
  const { data, programDetails } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const renderSessionInfo = (title: any, value: any) => {
    return (
      <View style={styles.container}>
        <View style={{ flex: 1.5 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="forgot"
            textSize={TextSize.Small}
            textStyle={styles.message}
          >
            {title}
          </Text>
        </View>
        <View style={{ flex: 3 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="forgot"
            textSize={TextSize.Small}
            textStyle={[styles.message, { fontFamily: FontFamily.Regular }]}
          >
            {value}
          </Text>
        </View>
      </View>
    );
  };

  const renderQpDescriptionInfo = (title: any, value: any) => {
    return (
      <View style={styles.container}>
        <View style={{ flex: 1.5 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="forgot"
            textSize={TextSize.Small}
            textStyle={styles.message}
          >
            {title}
          </Text>
        </View>
        <View style={{ flex: 3 }}>
          <ScrollContainer height="50px" scrollable={value?.length > 50 ? true : false}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="forgot"
              textSize={TextSize.Small}
              textStyle={[styles.message, { fontFamily: FontFamily.Regular }]}
            >
              {value}
            </Text>
          </ScrollContainer>
        </View>
      </View>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <View style={styles.mainContainer}>
      {renderSessionInfo("Qualification Pack name", data?.name)}
      {renderQpDescriptionInfo("Description", data?.description)}
      {renderSessionInfo("Program", programDetails?.programName)}
      {renderSessionInfo("Entity", programDetails?.entityName)}
      {renderSessionInfo("Created on", moment(data?.createdAt).format("DD-MMM-YYYY"))}
    </View>
  );
}

const styles = StyleSheet.create({
  otpContainer: {
    marginTop: 20,
    marginBottom: 40,
    marginRight: 112
  },
  message: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    borderRightColor: "#675850"
  },
  container: {
    flexDirection: "row",
    marginBottom: 10
  },
  textIcon: {
    color: colorPallete.cordioTaupe,
    fontSize: 12,
    fontFamily: "Montserrat-Regular",
    marginRight: 30
  },
  mainContainer: {
    width: "100%",
    backgroundColor: colorPallete.white,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 14,
    paddingRight: 14,
    borderRadius: 10,
    marginTop: 41
  }
});
