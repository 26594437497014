import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View, TouchableOpacity, TextInput } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import {
  RadioButton,
  RadioButtonType
} from "@socion-cordio/common/src/components/atoms/radioButton";
import Select, { components, SingleValueProps } from "react-select";

import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Geolocation from "react-native-geolocation-service";
import axios from "axios";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { SignupRepository } from "@socion-cordio/common/src/repositories/signup/signup";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { useTranslation } from "react-i18next";
import CountryCodeDropdown from "@socion-cordio/common/src/components/molecules/countryCodeDropdown";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import PasswordField from "@socion-cordio/common/src/components/molecules/passwordField";

interface Props {
  fetchSignup?: Function;
  updateSignup?: Function;
  isCaptchaDisabled?: boolean;
  setIsCaptchaDisabled?: Function;
  setSelectedCountryObject?: Function;
  setEmailVerification?: Function;
  selectedCountryObject?: any;
}

const OPTIONS_MODE = [
  {
    value: "Mobile number",
    label: "Mobile number",
    icon: IconNames.mobile
  },
  {
    value: "Email ID",
    label: "Email ID",
    icon: IconNames.email
  }
];

const customStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    minWidth: "100px",
    backgroundColor: "white",
    border: "none",
    boxShadow: "none",
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    fontSize: 14,
    cursor: "pointer",
    // marginLeft: -10,  // to adjust the dropdown
    ":hover": {
      border: "none"
    }
  }),
  menuList: (base: any) => ({
    ...base,
    fontFamily: FontFamily.Bold,
    backgroundColor: colorPallete.lightGrey,
    marginTop: -10
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    padding: 8,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: isSelected ? colorPallete.lightGreyThree : colorPallete.lightGrey,
    color: colorPallete.textBlack,
    cursor: "pointer",
    ":hover": {
      backgroundColor: colorPallete.lightGreyTwo
    }
  }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    fontSize: 14
  })
};

export const SignupFormNew = React.forwardRef((props: Props, ref: any): ReactElement => {
  const { setIsCaptchaDisabled, isCaptchaDisabled } = props;
  const { t } = useTranslation();
  const siteKey = "6Lc76q4ZAAAAAC9ar-nPZhWwrLcnNuZas1NHulv_";
  const [captchaValue, setCaptchaValue] = useState(null);
  const [validate, setValidate] = useState([
    {
      label: "+91",
      phoneNumberLength: 10,
      phoneNumberSizeMax: 10,
      phoneNumberSizeMin: 10,
      value: 91
    }
  ]);
  const [minLength, setMinLength] = useState(10);
  const [maxLength, setMaxLength] = useState(10);
  const [IslocationAccess, setIslocationAccess] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [showCountryCodeList, setShowCountryCodeList] = useState(false);
  const [buttonTitleValue, setbuttonTitleValue] = useState("Select your country");
  const [isEmailVerification, setisEmailVerification] = useState(false);
  const [isPhoneVerification, setisPhoneVerification] = useState(true);

  const [dropdownVal, setdropdownVal] = useState(0);
  const [updateddropdownCountryCode, setUpdateddropdownCountryCode] = useState(91);
  const [selectedCountryObject, setSelectedCountryObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [countryCodeInput, setCountryCodeInput] = useState<string>("");
  const [filteredCountryCodesList, setFilteredCountryCodesList] = useState<Array<any>>([]);
  const [showPassword, setShowPassword] = useState({ create: false, reEnter: false });
  const [password, setPassword] = useState(null);
  const [customError, setCustomError] = useState({ password: "", reEnterPassword: "" });
  const [checkboxValue, setCheckboxValue] = useState(false);

  const [loginModeList, setLoginModeList] = useState(null);
  const [isMobileInputSelected, setIsMobileInputSelected] = useState(true);
  const [loginOptionsList, setLoginOptionsList] = useState(null);

  const history: any = useHistory();
  const formikRef = useRef(null);
  let captchaRef: any;

  const saveSignup = (
    values: {
      name: string;
      countryCode: number;
      phoneNumber: number;
      email: string;
      createPassword: string;
    },
    isMobileVerificationType: boolean
  ) => {
    let payload = {
      name: values.name,
      countryCode: `${selectedCountryObject?.code}`,
      grecaptchaResponse: captchaValue,
      verificationType: values.phoneNumber ? "PHONE_NUMBER" : "EMAIL_ID",
      userName: values.phoneNumber
        ? values?.phoneNumber?.toString().replace(/[^0-9]/g, "")
        : values.email
    };
    captchaRef?.reset();
    let response = SignupRepository.registerNewUser(payload);
    response
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          setLoading(false);
          // toast.success(res?.message);
          history.push("/new/signup/otp", {
            name: values.name,
            userName: values.phoneNumber ? values.phoneNumber : values.email,
            countryCode: `${selectedCountryObject?.code}`,
            verificationType: values.phoneNumber ? "PHONE_NUMBER" : "EMAIL_ID",
            registrationKey: res.response,
            signupPayload: payload,
            selectedCountryObject: selectedCountryObject,
            isEmailVerification: isEmailVerification,
            password: password
          });
        } else {
          if (res?.message === "User already exists") {
            toast.error("This user is already registered in the system.");
          } else {
            toast.error(res?.message);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoginOptionsList(OPTIONS_MODE);
    setLoginModeList(OPTIONS_MODE[0]);
    fetchData();
    // onPressForLocation();
  }, []);

  useEffect(() => {
    if (validate[0]?.phoneNumberSizeMax != validate[0]?.phoneNumberSizeMin) {
      setMinLength(validate[0]?.phoneNumberSizeMin);
      setMaxLength(validate[0]?.phoneNumberSizeMax);
    } else {
      setMinLength(10);
      setMaxLength(10);
    }
  }, [validate]);

  useEffect(() => {
    getCountryodeList();
  }, []);

  async function fetchData() {
    let countryCode: any = await LocalStorage.getStorage("countryCode");
    let countryCodeObj: any = await LocalStorage.getStorage("countryCodeObj");

    countryCode === null || countryCodeObj === null
      ? onPressForLocation()
      : serCountryCodeFromLocalStorage(countryCodeObj);
  }

  const serCountryCodeFromLocalStorage = async (countryCodeObject: any) => {
    let countryCodeList: any = await LocalStorage.getStorage("countryCodeList");
    const countryData = countryCodeList.filter(
      (element: any) => element?.sequence === countryCodeObject?.sequence
    );
    actionOnRow(countryData[0]);
  };

  const onPressForLocation = (toggle = false) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (navigator.permissions.query({ name: "geolocation" })) {
        if (result.state == "granted") {
          Geolocation.getCurrentPosition(
            (position: any) => {
              const params = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
                lang: "en"
              };
              setIslocationAccess(true);
              setDefaultLocation(params);
            },
            (error: any) => {
              console.log(error);
            },
            {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 10000,
              showLocationDialog: false
            }
          );
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        } else if (result.state == "denied") {
          setIslocationAccess(false);
        } else {
          setIslocationAccess(false);
        }
      }
    });
  };

  const revealPosition = () => {
    onPressForLocation();
  };

  const positionDenied = () => {
    setIslocationAccess(false);
  };

  const setDefaultLocation = async (params: any) => {
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${params.lang}`
      );
      const result = response.data.results[0];
      let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
      setCountryCodeList(countryCodeListResoponse.response);
      setFilteredCountryCodesList(countryCodeListResoponse.response);
      result.address_components.map((item: any) => {
        if (item.types[0] === "country") {
          const shortName = item.short_name;

          const IfCountryExist = countryCodeListResoponse.response.filter(
            (element: any) => element.isoShortCode === shortName
          );
          actionOnRow(IfCountryExist[0]);
        }
      });
    } catch (error) {}
  };

  const getCountryodeList = async () => {
    let countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
    setCountryCodeList(countryCodeListResoponse.response);
    setFilteredCountryCodesList(countryCodeListResoponse.response);
    LocalStorage.setStorage("countryCodeList", countryCodeListResoponse.response);
    return countryCodeListResoponse;
  };

  const getCountry = (data: any, IslocationAccess: boolean) => {
    setIslocationAccess(IslocationAccess);
  };
  const handleCountryCodePress = (item: any) => {
    actionOnRow(item);
    setFilteredCountryCodesList(countryCodeList);
  };
  //Srimaraj todo: clean up : Do we really need the isPhoneVerification & isEmailVerification now?

  const actionOnRow = (item: any) => {
    if (item !== undefined) {
      formikRef?.current?.resetForm({
        values: {
          name: name,
          phoneNumber: "",
          email: "",
          countryCode: "+91"
        }
      }); //reset formik values
      captchaRef?.reset();
      setCaptchaValue(null);
      setIsCaptchaDisabled(true);
      let validationCountryCodeObject: any = [];
      validationCountryCodeObject.push(item);
      setShowCountryCodeList(false);
      setbuttonTitleValue(`${item.country}(${item.code})`);
      if (item.verificationPriority == "SMS") {
        setisPhoneVerification(true);
        setisEmailVerification(false);
        props.setEmailVerification(false);
      } else {
        setisEmailVerification(true);
        props.setEmailVerification(true);
        setisPhoneVerification(false);
      }
      setValidate(validationCountryCodeObject);
      // setisEmailVerification(item.emailVerification);
      // props.setEmailVerification(item.emailVerification);
      // setisPhoneVerification(item.smsVerification);
      setIslocationAccess(true);
      props.setSelectedCountryObject(item);
      setdropdownVal(item.code);
      setUpdateddropdownCountryCode(item.code);
      setSelectedCountryObject(item);
    }
  };

  const validateEmail = (email: string) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateEmailPhone = (values: any) => {
    const errors: any = {};
    if (values.phoneNumber) {
      if (!values.countryCode) {
        errors.countryCode = "Country code required";
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "Please enter a valid mobile number";
      }
      if (values?.phoneNumber) {
        if (
          minLength == maxLength &&
          (values?.phoneNumber?.replace(/[^0-9]/g, "").length > 10 ||
            values?.phoneNumber?.replace(/[^0-9]/g, "").length < 10)
        )
          errors.phoneNumber = "The phone number should be of 10 digits";
        else if (minLength !== maxLength && values?.phoneNumber?.replace(/[^0-9]/g, "").length > 20)
          errors.phoneNumber = `The phone number should be between ${minLength} to ${maxLength} digits.`;
      }
      if (!values.name) {
        errors.name = `Please enter your name`;
      }
    } else {
      if (isPhoneVerification && !values?.phoneNumber) {
        //errors.email = "Email Id is required";
        errors.phoneNumber = "Mobile number is required";
      }
      if (isEmailVerification && !values?.email) {
        errors.email = "Email Id is required";
      }
      if (
        values.email &&
        !validateEmail(values?.email?.trim().replace(" ", "")) &&
        values?.email?.trim().replace(" ", "")
      ) {
        errors.email = "Email entered is not valid";
      }
    }
    return errors;
  };

  const onChangeCaptchaValue = (value: any) => {
    setCaptchaValue(value);
    setIsCaptchaDisabled(false);
  };

  const captchaHandler = () => {
    // on error from captcha, disable button, display error
    setIsCaptchaDisabled(true);
    toast.error("Something went wrong.");
  };

  const filterCountryCodes = (text: string): void => {
    if (text.trim() === "") {
      setCountryCodeInput("");
      setFilteredCountryCodesList(countryCodeList);
      return;
    }
    setCountryCodeInput(text);

    const temp: Array<any> = countryCodeList?.filter((item: any) =>
      (item.country + item.code)?.toLowerCase()?.includes(text.toLowerCase())
    );
    setFilteredCountryCodesList(temp);
  };

  const resetFormikFormHandler = () => {
    formikRef?.current?.resetForm({
      values: {
        name: formikRef?.current?.values?.name,
        countryCode: "+91",
        phoneNumber: "",
        email: "",
        createPassword: ""
      }
    }); //reset formik values
  };

  const renderHeaderComponent = () => (
    <>
      <View style={styles.mainHeading}>
        <Text
          fontWeight={FontWeight.Regular}
          testId="program"
          textSize={TextSize.Regular}
          textStyle={styles.subHeading}
        >
          {t("common:pdaProgram")}
        </Text>
        <View style={{ marginTop: 10 }}>
          <Text
            fontWeight={FontWeight.Bold}
            testId="login"
            textSize={TextSize.Large}
            textStyle={{ fontSize: 26 }}
          >
            {t("common:signUpSecondary")}
          </Text>
        </View>
      </View>
    </>
  );

  const handleDropdown = (item: any, e: any) => {
    if (item.value === "Mobile number") {
      setIsMobileInputSelected(true);
      setisPhoneVerification(true);
      setisEmailVerification(false);
      setLoginOptionsList([OPTIONS_MODE[1]]);
    } else {
      setIsMobileInputSelected(false);
      setisPhoneVerification(false);
      setisEmailVerification(true);
      setLoginOptionsList([OPTIONS_MODE[0]]);
    }
    setLoginModeList([item]);
    resetFormikFormHandler();
    setPassword("");
  };

  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon testID="home" name={props.data.icon} customStyle={{}} />
        <View style={{ marginLeft: 5 }}>{props.data.label}</View>
      </View>
    </Option>
  );

  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Icon testID="home" name={props.data.icon} customStyle={{}} />
        <View style={{ marginLeft: 10 }}> {children}</View>
      </View>
    </components.SingleValue>
  );

  const renderDropdownHandler = () => (
    <>
      <Select
        isClearable={false}
        value={loginModeList}
        defaultValue={loginModeList}
        onChange={(item, e) => handleDropdown(item, e)}
        placeholder={"Select Mode"}
        options={loginOptionsList}
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
          SingleValue
        }}
      />
    </>
  );

  return (
    <View>
      {IslocationAccess ? (
        <WrapperForm
          footerText={t("common:alreadyHaveAnAccount")}
          footerTextType={t("common:loginText")}
          onPressFooterTextType={() => history.push("/new/iam/login")}
          component={
            <View style={styles.mainContainer}>
              <View style={styles.subContainer}>
                <>{renderHeaderComponent()}</>

                <View style={styles.subFormContainer}>
                  <Formik
                    initialValues={{
                      name: "",
                      countryCode: "+91",
                      phoneNumber: "",
                      email: "",
                      createPassword: ""
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      saveSignup(values, true);
                      setSubmitting(false);
                      // Srimaraj : Removed reset formik on submit. On error: everything should stay as is, only captcha is resetted
                      // resetForm({
                      //   values: {
                      //     name: "",
                      //     countryCode: "+91",
                      //     phoneNumber: "",
                      //     email: "",
                      //     createPassword: ""
                      //   }
                      // });
                      setIsCaptchaDisabled(true);
                      setCaptchaValue(null);
                      captchaRef?.reset();
                    }}
                    validate={validateEmailPhone}
                    enableReinitialize={false}
                    innerRef={formikRef}
                    validateOnBlur
                  >
                    {(
                      formikProps: FormikProps<{
                        name: string;
                        countryCode: number;
                        phoneNumber: string;
                        email: string;
                        createPassword: string;
                      }>
                    ) => (
                      <Form>
                        <View style={styles.actionContainer}>
                          <View style={{ height: 98 }}>
                            <UserInput
                              label=""
                              handleChange={formikProps.handleChange("name")}
                              handleBlur={() => formikProps.setFieldTouched}
                              userStyle={[styles.userInputStyle]}
                              textIcon={styles.userInputStyleHeader}
                              icon={IconNames.userName}
                              textValue="Name"
                              name="name"
                              placeholder="Enter name"
                              value={formikProps.values.name}
                              id="name"
                              handleKeyPress={(e: any) => {
                                if (e.key === "Enter") {
                                  formikProps.handleSubmit();
                                }
                              }}
                              onChange={(e: any) => {
                                setName(e.target.value);
                              }}
                              formikPropsTouched={
                                formikProps.touched.name === undefined
                                  ? false
                                  : formikProps.touched.name
                              }
                              customChange={() => formikProps.setFieldTouched("name", false)}
                            />
                          </View>
                          <View style={{ width: 304 }}>
                            <View style={styles.countryCodeInputContainer}>
                              <Icon testID="home" name={IconNames.userName} />
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="internal"
                                textSize={TextSize.Small}
                                style={styles.userInputStyleHeader}
                              >
                                Country code
                              </Text>
                            </View>
                            <TouchableOpacity
                              onPress={() => {
                                setShowCountryCodeList(!showCountryCodeList);
                              }}
                              style={[
                                styles.countryCodeButton,
                                {
                                  borderColor: showCountryCodeList
                                    ? colorPallete.cordioRed
                                    : colorPallete.black
                                }
                              ]}
                            >
                              <Text
                                fontWeight={FontWeight.Regular}
                                testId="counrtyCodeBtn"
                                textSize={TextSize.Regular}
                                textStyle={{ fontSize: 14 }}
                              >
                                {buttonTitleValue}
                              </Text>
                            </TouchableOpacity>
                          </View>
                          {showCountryCodeList && (
                            <CountryCodeDropdown
                              data={filteredCountryCodesList}
                              onPress={handleCountryCodePress}
                              extraData={selectedSequence}
                              filterCountryCodes={filterCountryCodes}
                              mainContainerStyle={{ top: -7 }}
                            />
                          )}

                          <View
                            style={[
                              styles.formField,
                              { marginLeft: -28, marginTop: 15, marginBottom: 0 }
                            ]}
                          >
                            {renderDropdownHandler()}{" "}
                          </View>

                          <View style={styles.formField}>
                            {isPhoneVerification && (
                              <View style={{ width: 304, marginTop: 2 }}>
                                <MobileInput
                                  label=""
                                  handleChange={(value: any) => {
                                    formikProps.setFieldValue("phoneNumber", value);
                                    formikProps.setFieldValue("email", "");
                                  }}
                                  handleBlur={() => formikProps.setFieldTouched}
                                  userInputStyleHeader={styles.userInputStyleHeader}
                                  icon={IconNames.mobile}
                                  name="phoneNumber"
                                  placeholder="Enter number"
                                  value={formikProps?.values?.phoneNumber?.toString()}
                                  id="phoneNumber"
                                  handleKeyPress={(e: any) => {
                                    if (e.key === "Enter") {
                                      formikProps.handleSubmit();
                                    }
                                  }}
                                  setValidate={setValidate}
                                  disableDropDown={true}
                                  updateddropdownCountryCode={updateddropdownCountryCode}
                                  formikPropsTouched={
                                    formikProps.touched.phoneNumber === undefined
                                      ? false
                                      : formikProps.touched.phoneNumber
                                  }
                                  customChange={() => {
                                    formikProps.setFieldTouched("phoneNumber", false);
                                  }}
                                  isFormikReinitialised={true}
                                  hideCountryCodeSection={false}
                                />
                              </View>
                            )}

                            <View style={styles.formField}>
                              {isEmailVerification && (
                                <View style={{ width: 304, marginTop: 2 }}>
                                  <UserInput
                                    label=""
                                    handleChange={(value: any) => {
                                      formikProps.setFieldValue("email", value);
                                      formikProps.setFieldValue("phoneNumber", "");
                                    }}
                                    handleBlur={() => formikProps.setFieldTouched}
                                    userStyle={styles.userInputStyle}
                                    textIcon={styles.userInputStyleHeader}
                                    icon={IconNames.email}
                                    name="email"
                                    // input={{ width: 280 }}
                                    placeholder="Enter Email"
                                    value={formikProps?.values?.email?.replace(/\s/g, "")}
                                    id="username"
                                    handleKeyPress={(e: any) => {
                                      if (e.key === "Enter") {
                                        formikProps.handleSubmit();
                                      }
                                    }}
                                    formikPropsTouched={
                                      formikProps.touched.email === undefined
                                        ? false
                                        : formikProps.touched.email
                                    }
                                    customChange={() => {
                                      formikProps.setFieldTouched("email", false);
                                    }}
                                    onChange={(e: any) => {
                                      setEmail(e.target.value);
                                    }}
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                          {/* Note: Below text input is not visible to the user: its purpose is to maek itself as
                          the target for browser autofill, which earlier was targetting the email
                          field. and incorrectly autofilled email filled with phone numbers */}
                          <View pointerEvents="none">
                            <TextInput
                              value="some dummy value"
                              focusable={false}
                              style={{ color: "white" }}
                              onChangeText={(value): void => {}}
                            />
                          </View>
                          <View style={{ height: 98 }}>
                            <PasswordField
                              label=""
                              handleChange={(value: any) => {
                                setPassword(value);
                                setCustomError({
                                  ...customError,
                                  password: ""
                                });
                              }}
                              handleBlur={() => {}}
                              // userStyle={[styles.userInputStyle]}
                              // textIcon={styles.userInputStyleHeader}
                              textValueIcon={IconNames.password}
                              textValue="Create password"
                              name="createPassword"
                              placeholder="Create Password"
                              value={password}
                              id="createPassword"
                              // input={{ width: 270 }}
                              isSecure={showPassword.create ? false : true}
                            />
                          </View>
                          <View style={styles.termsContainer}>
                            <Checkbox
                              testId=""
                              selected={checkboxValue}
                              type={CheckboxType.Square}
                              text=""
                              textSize={TextSize.Large}
                              textFontWeight={FontWeight.Regular}
                              iconSize={15}
                              onPress={() => setCheckboxValue(!checkboxValue)}
                            />
                            <Text style={styles.termsOfUseCheckbox}>
                              I accept the
                              <a href="/new/terms-of-use" target="_blank">
                                {" "}
                                Terms of Use
                              </a>
                            </Text>
                          </View>
                          <ReCAPTCHA
                            sitekey={siteKey}
                            // ref={ref}
                            ref={(el: any) => {
                              captchaRef = el;
                            }}
                            onChange={onChangeCaptchaValue}
                            onExpired={() => setIsCaptchaDisabled(true)}
                            onErrored={captchaHandler}
                          />
                          <Button
                            type={ButtonType.Primary}
                            buttonStyles={styles.signUpButton}
                            title="Get Verification code"
                            onPress={() => {
                              formikProps.handleSubmit();
                            }}
                            disabled={
                              !password ||
                              !checkboxValue ||
                              isCaptchaDisabled ||
                              loading ||
                              !name ||
                              (!formikProps?.values?.phoneNumber?.toString().replace(/\D/g, "") &&
                                isEmailVerification &&
                                !email)
                            }
                          />
                        </View>
                      </Form>
                    )}
                  </Formik>
                </View>
              </View>
            </View>
          }
        />
      ) : (
        <WrapperForm
          footerText={t("common:alreadyHaveAnAccount")}
          footerTextType={t("common:loginText")}
          onPressFooterTextType={() => history.push("/new/iam/login")}
          component={
            <View style={styles.mainContainer}>
              <View style={styles.mainContainerSecondary}>
                <View style={styles.subContainer}>
                  <>{renderHeaderComponent()}</>
                </View>
              </View>
            </View>
          }
        />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
    backgroundColor: colorPallete.white,
    width: "100%",
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  mainContainerSecondary: {
    height: 550
  },
  subContainer: {
    marginTop: 20,
    alignItems: "center"
  },
  subHeading: {
    fontSize: 19
  },
  mainHeading: {
    alignItems: "center"
    // marginTop: 50,
    // marginLeft: 80
  },
  formContainer: {
    width: 480,
    backgroundColor: colorPallete.cordioBeige,
    // position: "absolute",
    borderRadius: 20,
    // border: "1px solid rgb(194, 188, 185)",
    alignItems: "center",
    borderColor: colorPallete.cordioRed,
    borderStyle: "solid",
    borderWidth: 1
  },
  formField: {
    width: 304,
    // borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 0,
    marginTop: 0,
    marginBottom: 5,
    borderColor: colorPallete.cordioTaupeLight2
  },
  formContainerSecondary: {
    width: 480,
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    alignItems: "center",
    borderColor: colorPallete.cordioRed,
    borderStyle: "solid",
    borderWidth: 1
  },
  countryCodeButton: {
    height: 40,
    width: 304,
    padding: 6,
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  countryCodeInputContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15
  },
  heading: {
    // marginTop: 50,
    // marginLeft: 80,
    fontSize: 26,
    marginBottom: 140
  },
  button: {
    marginTop: 0,
    height: 40,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  signUpButton: {
    marginTop: 15,
    height: 50,
    width: 304,
    zIndex: -1,
    borderRadius: 10
  },
  actionContainer: {
    // marginTop: 50,
    // marginLeft: 80
    marginBottom: 10,
    alignItems: "center"
  },
  userInputStyle: {
    marginBottom: 0,
    alignSelf: "center"
  },

  userInputStyleHeader: {
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    marginLeft: 5
  },
  forgot: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  footer: {
    // marginStart: 155,
    // zIndex: -1,
    // marginVertical: 40
    marginVertical: 7
  },
  login: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  subFormContainer: {
    zIndex: -1,
    alignItems: "center",
    marginTop: 40
  },
  dropDownContainerStyle: {
    marginTop: 10,
    width: 303,
    zIndex: 10
  },
  containerStyles: {
    borderColor: colorPallete.lightGreyTwo,
    borderWidth: 1,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: 40,
    marginBottom: 30,
    borderRightWidth: 0
  },
  termsContainer: {
    flexDirection: "row",
    width: 304,
    marginBottom: 20
  },

  checkboxText: {
    alignItems: "center",
    display: "flex",
    fontSize: 12,
    fontFamily: "Montserrat-Regular"
  },
  termsOfUseCheckbox: {
    alignItems: "center",
    display: "flex",
    fontSize: 12,
    fontFamily: "Montserrat-Regular"
  },
  dividerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10
  },
  dividerLine: {
    borderWidth: 1,
    width: 98,
    borderColor: colorPallete.cordioTaupeLight4,
    height: 1
  }
});
export default SignupFormNew;
