import { BADGES } from "@socion-cordio/common/src/modules/badges/actions/actionTypes";
import { BadgeTypes } from "@socion-cordio/common/src/models/badgeType";

export interface IBadgesLoading {
  getBadgeTypes: boolean;
}
export interface IBadgesError {
  getBadgeTypes: string;
}
export interface IBadgesState {
  badgeTypesData: BadgeTypes;
  error: IBadgesError;
  loading: IBadgesLoading;
}

export const topicsInitialState: IBadgesState = {
  badgeTypesData: null,
  error: {
    getBadgeTypes: ""
  },
  loading: { getBadgeTypes: false }
};

const BadgesReducer = (
  state = topicsInitialState,
  action: { payload: any; type: string }
): IBadgesState => {
  switch (action.type) {
    case BADGES.GET_BADGES_TYPES.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getBadgeTypes: true },
        error: { ...state.error, getBadgeTypes: null }
      };

    case BADGES.GET_BADGES_TYPES.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBadgeTypes: false },
        error: { ...state.error, getBadgeTypes: null },
        badgeTypesData: action.payload
      };

    case BADGES.GET_BADGES_TYPES.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getBadgeTypes: false },
        error: { ...state.error, getBadgeTypes: action.payload }
      };
    default:
      return state;
  }
};
export default BadgesReducer;
