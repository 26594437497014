import { all } from "redux-saga/effects";
import profileWatcherSaga from "@socion-cordio/common/src/modules/profile/saga/saga";
import signupWatcherSaga from "@socion-cordio/common/src/modules/signup/saga/saga";
import ProgramWatcherSaga from "@socion-cordio/common/src/modules/program/saga/saga";
import TopicsWatcherSaga from "@socion-cordio/common/src/modules/topic/saga/saga";
import UserRoleProgramWatcherSaga from "@socion-cordio/common/src/modules/user-role/saga/saga";
import SessionWatcherSaga from "@socion-cordio/common/src/modules/session/saga/saga";
import participationDetailsWatcherSaga from "@socion-cordio/common/src/modules/participationDetails/saga/saga";
import QualificatioPackWatcherSaga from "@socion-cordio/common/src/modules/qualificationPacks/saga/saga";
import BadgesWatcherSaga from "@socion-cordio/common/src/modules/badges/saga/saga";

export default function* rootSaga() {
  yield all([
    profileWatcherSaga(),
    signupWatcherSaga(),
    ProgramWatcherSaga(),
    UserRoleProgramWatcherSaga(),
    SessionWatcherSaga(),
    TopicsWatcherSaga(),
    participationDetailsWatcherSaga(),
    QualificatioPackWatcherSaga(),
    BadgesWatcherSaga()
  ]);
}
