import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import { LicenseAndPricing } from "@socion-cordio/common/src/models/licensePricing";
import { LicenseAndPricingCredits } from "@socion-cordio/common/src/models/licenseModelCredits";
import { LicenseAndPricingDebits } from "@socion-cordio/common/src/models/licenseModelDebits";
import { LicenseAndPricingAll } from "@socion-cordio/common/src/models/licenseModelAll";
import {
  PROGRAMS,
  SAVE_PROGRAM_DETAILS,
  EXTERNALREDIRECTION,
  SELECTED_PROGRAM_DETAILS
} from "@socion-cordio/common/src/modules/program/actions/actionTypes";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";

const getPrograms = (data: any): IFluxStandardAction<{}> => {
  return {
    type: PROGRAMS.GET_PROGRAMS.LOADING,
    payload: data
  };
};

const getProgramsSucess = (data: any): IFluxStandardAction<Program> => {
  data.response.forEach((entityData: any) => {
    entityData.programs.forEach((program: any, index: number) => {
      program.serialNo = `${index + 1}.`;
    });
  });
  return {
    type: PROGRAMS.GET_PROGRAMS.SUCCESS,
    payload: data.response
  };
};

const getProgramsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_PROGRAMS.ERROR,
    payload: error
  };
};

const saveProgramDetails = (data: any): IFluxStandardAction<{}> => {
  return {
    type: SAVE_PROGRAM_DETAILS,
    payload: data
  };
};

const getEntityInvite = (): IFluxStandardAction<string> => {
  return {
    type: PROGRAMS.GET_ENTITY_INVITE.LOADING
  };
};
const getEntityInviteSuccess = (data: EntityInvite): IFluxStandardAction<EntityInvite> => {
  return {
    type: PROGRAMS.GET_ENTITY_INVITE.SUCCESS,
    payload: data
  };
};
const getEntityInviteError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_ENTITY_INVITE.ERROR,
    payload: error
  };
};

const getLicenseAndPricing = (data: {
  programId: number;
}): IFluxStandardAction<{ programId: number }> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING.LOADING,
    payload: data
  };
};

const getLicenseAndPricingSuccess = (
  data: LicenseAndPricing
): IFluxStandardAction<LicenseAndPricing> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING.SUCCESS,
    payload: data
  };
};

const getLicenseAndPricingError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING.ERROR,
    payload: error
  };
};

const getLicenseAndPricingCredits = (data: {
  licenseId: number;
}): IFluxStandardAction<{ licenseId: number }> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.LOADING,
    payload: data
  };
};

const getLicenseAndPricingCreditsSuccess = (
  data: LicenseAndPricingCredits
): IFluxStandardAction<LicenseAndPricingCredits> => {
  data.response.forEach((data: any, index: number) => {
    data.serialNo = `${index + 1}.`;
  });
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.SUCCESS,
    payload: data
  };
};

const getLicenseAndPricingCreditsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.ERROR,
    payload: error
  };
};

const getLicenseAndPricingDebits = (data: {
  licenseId: number;
}): IFluxStandardAction<{ licenseId: number }> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.LOADING,
    payload: data
  };
};

const getLicenseAndPricingDebitsSuccess = (
  data: LicenseAndPricingDebits
): IFluxStandardAction<LicenseAndPricingDebits> => {
  data.response.forEach((data: any, index: number) => {
    data.serialNo = `${index + 1}.`;
  });
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.SUCCESS,
    payload: data
  };
};

const getLicenseAndPricingDebitsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.ERROR,
    payload: error
  };
};

const getLicenseAndPricingAll = (data: {
  licenseId: number;
}): IFluxStandardAction<{ licenseId: number }> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_ALL.LOADING,
    payload: data
  };
};

const getLicenseAndPricingAllSuccess = (
  data: LicenseAndPricingAll
): IFluxStandardAction<LicenseAndPricingAll> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_ALL.SUCCESS,
    payload: data
  };
};

const getLicenseAndPricingAllError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: PROGRAMS.GET_LICENSE_AND_PRICING_ALL.ERROR,
    payload: error
  };
};

const getExternalRedirectionData = (data: any): IFluxStandardAction<any> => {
  return {
    type: EXTERNALREDIRECTION.GET_EXTERNAL_REDIRECTION.LOADING,
    payload: data
  };
};

const getExternalRedirectionDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: EXTERNALREDIRECTION.GET_EXTERNAL_REDIRECTION.SUCCESS,
    payload: data
  };
};

const getSelectedProgramData = (data: any): IFluxStandardAction<any> => {
  return {
    type: SELECTED_PROGRAM_DETAILS.GET_SELECTED_PROGRAM_DETAILS.LOADING,
    payload: data
  };
};

const getSelectedProgramDataSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: SELECTED_PROGRAM_DETAILS.GET_SELECTED_PROGRAM_DETAILS.SUCCESS,
    payload: data
  };
};

export const ProgramActions = {
  getPrograms,
  getProgramsSucess,
  getProgramsError,
  saveProgramDetails,
  getEntityInvite,
  getEntityInviteSuccess,
  getEntityInviteError,
  getLicenseAndPricing,
  getLicenseAndPricingSuccess,
  getLicenseAndPricingError,
  getLicenseAndPricingCredits,
  getLicenseAndPricingCreditsSuccess,
  getLicenseAndPricingCreditsError,
  getLicenseAndPricingDebits,
  getLicenseAndPricingDebitsSuccess,
  getLicenseAndPricingDebitsError,
  getLicenseAndPricingAll,
  getLicenseAndPricingAllSuccess,
  getLicenseAndPricingAllError,
  getExternalRedirectionData,
  getExternalRedirectionDataSuccess,
  getSelectedProgramData,
  getSelectedProgramDataSuccess
};
