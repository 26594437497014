import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import { LicenseAndPricing } from "@socion-cordio/common/src/models/licensePricing";
import { LicenseAndPricingCredits } from "@socion-cordio/common/src/models/licenseModelCredits";
import { LicenseAndPricingDebits } from "@socion-cordio/common/src/models/licenseModelDebits";
import { LicenseAndPricingAll } from "@socion-cordio/common/src/models/licenseModelAll";

import {
  EXTERNALREDIRECTION,
  PROGRAMS,
  SAVE_PROGRAM_DETAILS,
  SELECTED_PROGRAM_DETAILS
} from "@socion-cordio/common/src/modules/program/actions/actionTypes";

export interface IGetExternalRedirectionData {
  getExternalRedirectionData: null;
}

export interface IGetExternalRedirectionDataLoading {
  getExternalRedirectionDataFlagLoading: boolean;
}

export interface IProgramLoading {
  getPrograms: boolean;
  getEntityInvite: boolean;
  getLicenseAndPricing: boolean;
  getLicenseAndPricingCredits: boolean;
  getLicenseAndPricingDebits: boolean;
  getLicenseAndPricingAll: boolean;
}

export interface IProgramError {
  getPrograms: string;
  getEntityInvite: string;
  getLicenseAndPricing: string;
  getLicenseAndPricingCredits: string;
  getLicenseAndPricingDebits: string;
  getLicenseAndPricingAll: string;
}
export interface ISelectedProgramDetails {
  selectedProgramDetails: null;
}

export interface ISelectedProgramDetailsLoading {
  selectedProgramDetailsFlagLoading: boolean;
}

export interface IProgramState {
  programsData: Program;
  entityInviteData: EntityInvite;
  licenseAndPricing: LicenseAndPricing;
  licenseAndPricingCredits: LicenseAndPricingCredits;
  licenseAndPricingDebits: LicenseAndPricingDebits;
  licenseAndPricingAll: LicenseAndPricingAll;
  error: IProgramError;
  loading: IProgramLoading;
  programDetails: object;
  getExternalRedirectionData: IGetExternalRedirectionData;
  getExternalRedirectionDataLoading: IGetExternalRedirectionDataLoading;
  selectedProgramDetails: ISelectedProgramDetails;
  selectedProgramDetailsLoading: ISelectedProgramDetailsLoading;
}

export const programsInitialState: IProgramState = {
  programsData: null,
  entityInviteData: null,
  licenseAndPricing: null,
  licenseAndPricingCredits: null,
  licenseAndPricingDebits: null,
  licenseAndPricingAll: null,
  error: {
    getPrograms: "",
    getEntityInvite: "",
    getLicenseAndPricing: "",
    getLicenseAndPricingCredits: "",
    getLicenseAndPricingDebits: "",
    getLicenseAndPricingAll: ""
  },
  loading: {
    getPrograms: false,
    getEntityInvite: false,
    getLicenseAndPricing: true,
    getLicenseAndPricingCredits: true,
    getLicenseAndPricingDebits: true,
    getLicenseAndPricingAll: true
  },
  programDetails: null,
  getExternalRedirectionData: null,
  getExternalRedirectionDataLoading: { getExternalRedirectionDataFlagLoading: true },
  selectedProgramDetails: null,
  selectedProgramDetailsLoading: { selectedProgramDetailsFlagLoading: false }
};

const programsReducer = (
  state = programsInitialState,
  action: { payload: any; type: string }
): IProgramState => {
  switch (action.type) {
    case PROGRAMS.GET_PROGRAMS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: true },
        error: { ...state.error, getPrograms: null }
      };
    case PROGRAMS.GET_PROGRAMS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: false },
        error: { ...state.error, getPrograms: null },
        programsData: action.payload
      };
    case PROGRAMS.GET_PROGRAMS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getPrograms: false },
        error: { ...state.error, getPrograms: action.payload }
      };
    case SAVE_PROGRAM_DETAILS:
      return {
        ...state,
        programDetails: action.payload
      };

    case PROGRAMS.GET_ENTITY_INVITE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getEntityInvite: true },
        error: { ...state.error, getEntityInvite: null }
      };
    case PROGRAMS.GET_ENTITY_INVITE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getEntityInvite: false },
        error: { ...state.error, getEntityInvite: null },
        entityInviteData: action.payload
      };
    case PROGRAMS.GET_ENTITY_INVITE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getEntityInvite: false },
        error: { ...state.error, getEntityInvite: action.payload }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricing: false },
        error: { ...state.error, getLicenseAndPricing: null },
        licenseAndPricing: action.payload
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricing: true },
        error: { ...state.error, getLicenseAndPricing: null }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricing: false },
        error: { ...state.error, getLicenseAndPricing: action.payload }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingCredits: false },
        error: { ...state.error, getLicenseAndPricingCredits: null },
        licenseAndPricingCredits: action.payload
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingCredits: true },
        error: { ...state.error, getLicenseAndPricingCredits: null }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingCredits: false },
        error: { ...state.error, getLicenseAndPricingCredits: action.payload }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingDebits: false },
        error: { ...state.error, getLicenseAndPricingDebits: null },
        licenseAndPricingDebits: action.payload
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingDebits: true },
        error: { ...state.error, getLicenseAndPricingDebits: null }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingDebits: false },
        error: { ...state.error, getLicenseAndPricingDebits: action.payload }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_ALL.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingAll: false },
        error: { ...state.error, getLicenseAndPricingAll: null },
        licenseAndPricingAll: action.payload
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_ALL.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingAll: true },
        error: { ...state.error, getLicenseAndPricingAll: null }
      };

    case PROGRAMS.GET_LICENSE_AND_PRICING_ALL.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getLicenseAndPricingAll: false },
        error: { ...state.error, getLicenseAndPricingAll: action.payload }
      };

    case EXTERNALREDIRECTION.GET_EXTERNAL_REDIRECTION.LOADING:
      return {
        ...state,
        getExternalRedirectionData: { ...state.getExternalRedirectionData },
        getExternalRedirectionDataLoading: {
          ...state.getExternalRedirectionDataLoading,
          getExternalRedirectionDataFlagLoading: true
        }
      };
    case EXTERNALREDIRECTION.GET_EXTERNAL_REDIRECTION.SUCCESS:
      return {
        ...state,
        getExternalRedirectionData: action.payload,
        getExternalRedirectionDataLoading: {
          ...state.getExternalRedirectionDataLoading,
          getExternalRedirectionDataFlagLoading: false
        }
      };

    case SELECTED_PROGRAM_DETAILS.GET_SELECTED_PROGRAM_DETAILS.LOADING:
      return {
        ...state,
        selectedProgramDetails: action.payload,
        selectedProgramDetailsLoading: {
          ...state.selectedProgramDetailsLoading,
          selectedProgramDetailsFlagLoading: true
        }
      };
    case SELECTED_PROGRAM_DETAILS.GET_SELECTED_PROGRAM_DETAILS.SUCCESS:
      return {
        ...state,
        selectedProgramDetails: action.payload,
        selectedProgramDetailsLoading: {
          ...state.selectedProgramDetailsLoading,
          selectedProgramDetailsFlagLoading: false
        }
      };

    default:
      return state;
  }
};
export default programsReducer;
