import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
// import { Login } from "@socion-cordio/common/src/models/login";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { participationDetailsEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class ParticipationDetailsRepository {
  getParticipantList = async (body: { programId: string; userType: string }): Promise<any> => {
    const participantListData = await ApiClient.post(
      participationDetailsEndPoints.getParticipantTrainerList(),
      body
    );
    // const participantListData: Login = deserialize(Login, data);
    return participantListData;
  };
  getTrainersList = async (body: { programId: string; userType: string }): Promise<any> => {
    const trainersListData = await ApiClient.post(
      participationDetailsEndPoints.getParticipantTrainerList(),
      body
    );
    // const trainersListData: Login = deserialize(Login, data);
    return trainersListData;
  };
  getParticipantAttestationList = async (body: {
    userType: string;
    startDate: string;
    endDate: string;
    programId: string;
    locations: any;
    badgeId: any;
  }): Promise<any> => {
    // const programId = body.programId;
    // delete body["programId"];
    const participantAttestationData = await ApiClient.post(
      participationDetailsEndPoints.getParticipantTrainerAttestationList(),
      body
    );
    // const participantAttestationData: Login = deserialize(Login, data);
    return participantAttestationData;
  };
  getTrainerAttestationList = async (body: {
    userType: string;
    startDate: string;
    endDate: string;
    programId: string;
  }): Promise<any> => {
    // const programId = body.programId;
    // delete body["programId"];
    const participantAttestationData = await ApiClient.post(
      participationDetailsEndPoints.getParticipantTrainerAttestationList(),
      body
    );
    // const participantAttestationData: Login = deserialize(Login, data);
    return participantAttestationData;
  };
  getTrainerByTopicList = async (body: { programId: string }): Promise<any> => {
    const programId = body.programId;
    const trainerByTopicData = await ApiClient.get(
      participationDetailsEndPoints.getTrainerByTopicList(programId)
    );
    // const trainerByTopicData: Login = deserialize(Login, data);
    return trainerByTopicData;
  };
}
const participationDetailsRepository = new ParticipationDetailsRepository();
export { participationDetailsRepository as ParticipationDetailsRepository };
