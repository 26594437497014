import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";
import { ViewBundle } from "@socion-cordio/common/src/models/qualificationPacks";
import { QualificationPackList } from "@socion-cordio/common/src/models/qualificationPackList";
import {
  BUNDLE,
  QUALFICATIONPACKLIST
} from "@socion-cordio/common/src/modules/qualificationPacks/actions/actionTypes";

const getBundle = (data: { bundleId: number }): IFluxStandardAction<{ bundleId: number }> => {
  return {
    type: BUNDLE.GET_BUNDLE.LOADING,
    payload: data
  };
};

const getBundleSuccess = (data: ViewBundle): IFluxStandardAction<ViewBundle> => {
  return {
    type: BUNDLE.GET_BUNDLE.SUCCESS,
    payload: data
  };
};

const getBundleError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: BUNDLE.GET_BUNDLE.ERROR,
    payload: error
  };
};

const getQualficationPackList = (data: {
  programId: number;
}): IFluxStandardAction<{ programId: number }> => {
  return {
    type: QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.LOADING,
    payload: data
  };
};

const getQualficationPackListSuccess = (
  data: QualificationPackList
): IFluxStandardAction<QualificationPackList> => {
  return {
    type: QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.SUCCESS,
    payload: data
  };
};

const getQualficationPackListError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.ERROR,
    payload: error
  };
};

export const QualificationPackActions = {
  getBundle,
  getBundleSuccess,
  getBundleError,
  getQualficationPackList,
  getQualficationPackListSuccess,
  getQualficationPackListError
};
