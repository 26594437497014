import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import TextField from "@socion-cordio/common/src/components/atoms/textField";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import React, { ReactElement, useEffect, useState } from "react";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { loginEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { View, StyleSheet, StyleProp, TextStyle, FlatList, TouchableOpacity } from "react-native";
import Geolocation from "react-native-geolocation-service";
import axios from "axios";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";

interface IProps {
  icon?: IconNames;
  label?: string;
  textValue?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  id?: string;
  handleKeyPress?: Function;
  handleChange?: Function;
  handleBlur?: Function;
  errorStyle?: StyleProp<TextStyle>;
  onChangeValue?: (value: any) => void;
  noFormik?: boolean;
  editable?: boolean;
  disableDropDown?: boolean;
  setValidate?: Function;
  errors?: any;
  updateddropdownCountryCode?: any;
  setCountryCodeObject?: any;
  formikPropsTouched?: boolean;
  customChange?: Function;
  userInputStyleHeader?: StyleProp<TextStyle>;
  isFormikReinitialised?: boolean;
  hideCountryCodeSection?: boolean | undefined;
}

const styles = StyleSheet.create({
  textIcon: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  input: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    height: 40
  },
  label: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 10
  },
  icon: {},
  inputContainer: {
    flex: 1,
    flexDirection: "row",
    marginLeft: 0
    // zIndex: -1
  },
  listItems: {
    paddingHorizontal: 5,
    paddingVertical: 2
  },
  placeholderStyle: {
    marginLeft: 5
  },
  arrowIconStyle: {
    top: -1
  },
  hideArrowIconStyle: {
    top: -1,
    display: "none"
  },
  containerStyle: {
    marginBottom: 0,
    display: "flex",
    width: "37ch",
    alignItems: "center",
    height: 20
  },
  countryCodeOpenDropdownButton: {
    height: 40,
    width: 55,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: colorPallete.lightGreyTwo,
    borderWidth: 0.5
  },
  dropdownOverflowListContainer: {
    height: 280,
    width: 303,
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: colorPallete.cordioRed,
    borderRadius: 5,
    paddingBottom: 5
  },
  dropdownItemsMainContainer: {
    marginTop: 45,
    zIndex: 10000
  }
});

export default function MobileInput(props: IProps): ReactElement {
  const {
    icon,
    label,
    placeholder,
    textValue,
    name,
    value,
    id,
    handleBlur,
    handleChange,
    handleKeyPress,
    onChangeValue,
    errorStyle = { start: -70 },
    noFormik,
    editable = true,
    setValidate,
    disableDropDown,
    updateddropdownCountryCode,
    formikPropsTouched,
    customChange,
    userInputStyleHeader,
    isFormikReinitialised = false,
    hideCountryCodeSection = false
  } = props;
  const [open, setOpen] = useState(false);
  const [val, setVal] = useState("");
  const [updatedCountryCode, setUpdatedCountryCode] = useState("+91");
  const [items, setItems] = useState([]);

  const [enteredValue, setNumberInputValue] = useState(null);
  const [filteredCountryCodesList, setFilteredCountryCodesList] = useState<Array<any>>([]);
  const [countryCodeInput, setCountryCodeInput] = useState<string>("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = items.filter((i) => i.code == val);
    if (val) {
      setUpdatedCountryCode(val);
    }
    if (filteredData.length !== 0) {
      setSelectedCountryCodeHandler(filteredData[0].code);
      setUpdatedCountryCode(filteredData[0].code);
    }
    if (filteredData?.length && setValidate) setValidate(filteredData);
  }, [val]);

  async function fetchData() {
    let countryCode: any = await LocalStorage.getStorage("countryCode");
    let countryCodeObj: any = await LocalStorage.getStorage("countryCodeObj");
    countryCode === null || countryCodeObj === null
      ? [onPressForLocation()]
      : [setUpdatedCountryCode(`+${countryCode}`), await getCountryCode(`+${countryCode}`)];
  }

  const setSelectedCountryCodeHandler = (selectedCountryCode: any) => {
    setUpdatedCountryCode(selectedCountryCode);
    setVal(selectedCountryCode);
  };
  const onPressForLocation = (toggle = false) => {
    navigator.permissions.query({ name: "geolocation" }).then(function (result) {
      if (navigator.permissions.query({ name: "geolocation" })) {
        if (result.state == "granted") {
          Geolocation.getCurrentPosition(
            (position: any) => {
              const params = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
                lang: "en"
              };
              getUserCountryLocation(params);
            },
            (error: any) => {
              console.log(error);
            },
            {
              enableHighAccuracy: true,
              timeout: 15000,
              maximumAge: 10000,
              showLocationDialog: false
            }
          );
        } else if (result.state == "prompt") {
          navigator.geolocation.getCurrentPosition(revealPosition, positionDenied);
        } else if (result.state == "denied") {
          getCountryCode("+91", "");
        } else {
          positionDenied();
          console.log("==============error on press loc====in else part on press loc =====");
        }
      }
    });
  };

  const revealPosition = () => {
    onPressForLocation();
  };

  const positionDenied = () => {
    // call api default value +91
    getCountryCode("+91", undefined);
  };

  const getUserCountryLocation = async (params: any) => {
    try {
      const response: any = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDVf-To9hehz4ORteAxgsP-yzHxsahVcDo&latlng=${params.lat},${params.long}&language=${params.lang}`
      );
      const result = response.data.results[0];
      result.address_components.map((item: any) => {
        if (item.types[0] === "country") {
          // console.log("item:::", item);
          const shortName = item.short_name;
          getCountryCode(null, shortName); //sending some default value 91.
        }
      });
    } catch (error) {}
  };

  const getCountryCode = async (selectedCountryCode?: any, isoShortCode?: string) => {
    let localStorageCountryCode = await LocalStorage.getStorage("countryCode");
    let countryCodeListResoponse: any;
    if (localStorageCountryCode === null) {
      countryCodeListResoponse = await ApiClient.get(loginEndPoints.getCountryCode());
      LocalStorage.setStorage("countryCodeList", countryCodeListResoponse.response);
      if (selectedCountryCode === null) {
        const IfCountryExist = countryCodeListResoponse.response.filter(
          (element: any) => element.isoShortCode === isoShortCode
        );
        if (IfCountryExist) {
          //when we find cc in response list.
          setUpdatedCountryCode(IfCountryExist);
          setVal(IfCountryExist);
        } else {
          setUpdatedCountryCode("+91");
          setVal("+91");
        }
        setItems(countryCodeListResoponse.response);
        setFilteredCountryCodesList(countryCodeListResoponse.response);
      } else {
        const defaultCountryExist = countryCodeListResoponse.response.filter(
          (element: any) => element.isoShortCode === "IN"
        );
        setUpdatedCountryCode("+91");
        setVal("+91");
        setItems(countryCodeListResoponse.response);
        setFilteredCountryCodesList(countryCodeListResoponse.response);
        LocalStorage.setStorage("countryCodeObj", defaultCountryExist[0]);
      }
    } else {
      let localStorageCountryCodeList: any = await LocalStorage.getStorage("countryCodeList");
      setItems(localStorageCountryCodeList);
      setFilteredCountryCodesList(localStorageCountryCodeList);
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    const enteredValue = e.target.value.replace(/\D/g, "");
    setNumberInputValue(enteredValue);
  };
  const filterCountryCodes = (text: string): void => {
    if (text.trim() === "") {
      setCountryCodeInput("");
      setFilteredCountryCodesList(items);
      return;
    }
    setCountryCodeInput(text);
    const temp: Array<any> = items?.filter((item: any) =>
      (item.country + item.code)?.toLowerCase()?.includes(text.toLowerCase())
    );
    setFilteredCountryCodesList(temp);
  };

  const renderEmptyComponent = () => (
    <Text
      fontWeight={FontWeight.Regular}
      testId="internal"
      textSize={TextSize.Small}
      style={[
        styles.textIcon,
        { fontSize: 12, color: colorPallete.textBlack, alignSelf: "center" }
      ]}
    >
      No Country Found
    </Text>
  );
  return (
    <View>
      {icon && textValue && (
        <View style={styles.label}>
          <Icon testID="home" name={icon} customStyle={styles.icon} />
          <Text
            fontWeight={FontWeight.Regular}
            testId="internal"
            textSize={TextSize.Small}
            style={[styles.textIcon, userInputStyleHeader]}
          >
            {" "}
            {textValue}
            {""}
          </Text>
        </View>
      )}
      <View style={{ height: 45 }}>
        <View style={styles.inputContainer}>
          {!hideCountryCodeSection ? (
            <TouchableOpacity
              style={styles.countryCodeOpenDropdownButton}
              disabled={disableDropDown}
              onPress={() => setOpen(!open)}
            >
              <Text
                fontWeight={FontWeight.Regular}
                testId="internal"
                textSize={TextSize.Small}
                style={[styles.textIcon, userInputStyleHeader, { color: colorPallete.textBlack }]}
              >
                {disableDropDown ? `${updateddropdownCountryCode}` : `${updatedCountryCode}`}
              </Text>
              {!disableDropDown ? (
                <Icon testID="home" name={open ? IconNames.up : IconNames.down} size={6} />
              ) : (
                <></>
              )}
            </TouchableOpacity>
          ) : (
            <></>
          )}
          <View style={{ height: 40, width: "100%" }}>
            <TextField
              inputStyle={[styles.input, { width: hideCountryCodeSection ? 280 : 248 }]}
              name={name}
              placeholder={placeholder}
              label={label}
              value={isFormikReinitialised ? value : !editable ? value : enteredValue}
              id={id}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleKeyPress={handleKeyPress}
              errorStyle={errorStyle}
              noFormik={noFormik}
              editable={editable}
              onChange={onChange}
              formikPropsTouched={formikPropsTouched}
              customChange={customChange}
              isNumeric
            />
          </View>
        </View>
        {open ? (
          <View style={styles.dropdownItemsMainContainer}>
            <View style={styles.dropdownOverflowListContainer}>
              <View style={{ margin: 10 }}>
                <SearchFilterSeondary
                  placeHolder={"Search Country / code"}
                  customWidthStyle={{ width: "100%", height: 35 }}
                  filterList={filterCountryCodes}
                />
              </View>
              <FlatList
                data={filteredCountryCodesList}
                ListEmptyComponent={renderEmptyComponent}
                keyExtractor={(item: any, index: number) => index.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={{ width: 300, paddingHorizontal: 10, marginVertical: 6 }}
                    onPress={() => {
                      setOpen(false);
                      onChangeValue(item.code);
                      setVal(item.code);
                      setFilteredCountryCodesList(items);
                    }}
                  >
                    <Text
                      fontWeight={FontWeight.Regular}
                      testId="internal"
                      textSize={TextSize.Small}
                      style={[styles.textIcon, { fontSize: 12, color: colorPallete.textBlack }]}
                    >
                      {`${item.country}(${item.code})`}
                    </Text>
                  </TouchableOpacity>
                )}
              />
            </View>
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
}
