import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): any {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  }
  deserialize(date: any): Date {
    return new Date(date);
  }
}

@JsonObject("Profile")
export class Profile {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("firstName", String)
  fName: string = "";
  @JsonProperty("lastName", String)
  lName: string = "";
  @JsonProperty("emailId", String)
  email: string = "";
  @JsonProperty("age", Number)
  age: number = 0;
}

@JsonObject("UpdateUserDetails")
export class UpdateUserDetails {
  @JsonProperty("response", Object)
  response: object = null;
  @JsonProperty("localeKey", String)
  localeKey: string = null;
  @JsonProperty("message", String)
  message: string = null;
  @JsonProperty("messageDetails", String)
  messageDetails: string = null;
  @JsonProperty("responseCode", Number)
  responseCode: number = null;
}
