import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import QPDetails from "@socion-cordio/common/src/components/organisms/qpWebView/scanner/qpDetails";
import SelectionDetails from "@socion-cordio/common/src/components/organisms/qpWebView/scanner/selectionDetails";
import { toast } from "react-toastify";

const Styles = styled.div`
  @media only screen and (max-width: 526px) {
    margin-top: 140px;
  }
  @media only screen and (min-width: 527px) and (max-width: 534px) {
    margin-top: 120px;
  }
  @media only screen and (min-width: 535px) {
    margin-top: 100px;
  }
`;

export default function QPScanner(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [scannerData, setScanner] = useState(null);
  const { t } = useTranslation();
  const { bundleId } = useParams();

  useEffect(() => {
    getScannerDetails();
  }, []);

  const getScannerDetails = async () => {
    try {
      setIsLoading(true);
      let response = await ApiClient.get(qualificationPackEndPoints.getScannerData(bundleId));
      if (response?.responseCode == HTTP_STATUS_CODES.ok) {
        let idArray = response?.response?.bundleTopiMapping.map((i: any) => {
          return i.selectionId;
        });
        idArray = [...new Set(idArray)];
        let result: any = [];
        idArray.map((i: number) => {
          let temp = response?.response?.bundleTopiMapping?.filter((k: any) => k.selectionId == i);
          result.push(temp);
        });
        response.response.formattedBundleMapping = result;
        setScanner(response?.response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <View>
      <View style={styles.headerConatiner}>
        <View style={styles.headingContainer}>
          <View style={styles.flexConatiner}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="details"
              textSize={TextSize.Small}
              style={styles.headingStyles}
            >
              Qualification Pack
            </Text>
          </View>
          <QPDetails data={scannerData?.bundle} programDetails={scannerData?.programDetails} />
        </View>
      </View>
      <View style={styles.bodyStyles}>
        <Styles>
          <SelectionDetails data={scannerData?.formattedBundleMapping} />
        </Styles>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headerConatiner: {
    backgroundColor: "#B7504A",
    height: 146,
    paddingRight: 16
  },
  headingContainer: {
    position: "absolute",
    top: 16,
    width: "100%",
    paddingHorizontal: 16
  },
  headingStyles: {
    color: colorPallete.white,
    fontSize: 18,
    fontFamily: FontFamily.Bold
  },
  bodyStyles: {
    backgroundColor: "#EEE5DA",
    zIndex: -1,
    paddingHorizontal: 16,
    paddingTop: 80,
    paddingBottom: 200
  },
  flexConatiner: {
    display: "flex",
    flexDirection: "row"
  },
  closeIcon: {
    color: colorPallete.white,
    fontSize: 18
  },
  mr22: {
    marginRight: 22
  }
});
