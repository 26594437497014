import { ViewBundle } from "@socion-cordio/common/src/models/qualificationPacks";
import { QualificationPackList } from "@socion-cordio/common/src/models/qualificationPackList";
import {
  BUNDLE,
  QUALFICATIONPACKLIST
} from "@socion-cordio/common/src/modules/qualificationPacks/actions/actionTypes";

export interface IBundleloading {
  getBundle: boolean;
  getQualificationPackList: boolean;
}
export interface IBundleerror {
  getBundle: string;
  getQualificationPackList: string;
}
export interface IBundleState {
  bundleData: ViewBundle;
  error: IBundleerror;
  loading: IBundleloading;
  qualificationPackData: QualificationPackList;
}

export const BundleinitialState: IBundleState = {
  bundleData: null,
  qualificationPackData: null,
  error: {
    getBundle: "",
    getQualificationPackList: ""
  },
  loading: {
    getBundle: false,
    getQualificationPackList: false
  }
};

const qualificationPackReducer = (
  state = BundleinitialState,
  action: { payload: any; type: string }
): IBundleState => {
  switch (action.type) {
    case BUNDLE.GET_BUNDLE.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getBundle: true },
        error: { ...state.error, getBundle: null }
      };
    case BUNDLE.GET_BUNDLE.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getBundle: false },
        error: { ...state.error, getBundle: null },
        bundleData: action.payload
      };
    case BUNDLE.GET_BUNDLE.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getBundle: false },
        error: { ...state.error, getBundle: action.payload }
      };

    case QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.LOADING:
      return {
        ...state,
        loading: { ...state.loading, getQualificationPackList: true },
        error: { ...state.error, getQualificationPackList: null }
      };
    case QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getQualificationPackList: false },
        error: { ...state.error, getQualificationPackList: null },
        qualificationPackData: action.payload
      };
    case QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.ERROR:
      return {
        ...state,
        loading: { ...state.loading, getQualificationPackList: false },
        error: { ...state.error, getQualificationPackList: action.payload }
      };
    default:
      return state;
  }
};
export default qualificationPackReducer;
