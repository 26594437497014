import { allRoutesNames as routeNames } from "navigation/allRouteNames";
import MapRoutes from "navigation/mapRoutes";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
interface Props {
  children: Array<{}>;
}

export default function Index(props: Props): ReactElement {
  let history = useHistory();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MapRoutes
        {...props}
        routes={[...props.children]}
        basePath={`${routeNames.IAM.root}`}
        history={history}
        translate={t}
      />
    </React.Fragment>
  );
}
