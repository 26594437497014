import App from "./App";
import "./index.css";
import "@socion-cordio/common/src/assets/fonts/fonts";
import React from "react";
import ReactDOM from "react-dom";
import { persistStore } from "redux-persist";
import store from "@socion-cordio/common/src/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import Loader from "@socion-cordio/common/src/components/atoms/loader";

// import firebase from "@socion-cordio/web/src/utils/firebaseConfig"
// import { analytics } from "./utils/firebaseConfig";
// import { getAnalytics, logEvent } from "firebase/analytics";
// import firebase from 'firebase/analytics'

// getAnalytics(analytics);
// // logEvent(analytics, 'notification_received');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Initialize store
StoreProviderService.init(store);
const reduxStore = StoreProviderService.getStore();
let persistor = persistStore(reduxStore);

ReactDOM.render(
  <React.Suspense fallback="Loading...">
    <Provider store={reduxStore}>
      <PersistGate persistor={persistor} loading={<App />}>
        <App />
      </PersistGate>
    </Provider>
  </React.Suspense>,
  document.getElementById("root")
);
