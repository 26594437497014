import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useParams } from "react-router-dom";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { sessionPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import UserCard from "@socion-cordio/common/src/components/organisms/attestation/userCards";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import SessionInfo from "@socion-cordio/common/src/components/organisms/attestation/sessionInfo";
import ContentList from "@socion-cordio/common/src/components/organisms/attestation/contentList";
import MemberList from "@socion-cordio/common/src/components/organisms/attestation/memberList";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";

export default function AttestationDetails(): ReactElement {
  const [attestationData, setAttestationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { sessionId, userId, role } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getAttestationDetails(sessionId, userId, role);
  }, []);

  const getAttestationDetails = async (sessionId: string, userId: string, role: string) => {
    setIsLoading(true);
    let response = await ApiClient.get(
      sessionPackEndPoints.attestationDetails(sessionId, userId, role)
    );
    if (response?.responseCode == HTTP_STATUS_CODES.ok) {
      setAttestationData(response?.response);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <View>
      <View style={styles.headerConatiner}>
        <View style={{ marginLeft: "auto" }}>
          <Icon name={IconNames.socion} customStyle={styles.logoStyle} />
        </View>
        <View style={styles.headingContainer}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="details"
            textSize={TextSize.Small}
            style={styles.headingStyles}
          >
            {t("attestation:attestationDetails")}
          </Text>
          <View style={{ marginTop: 12 }}>
            {attestationData != [] && (
              <SessionInfo attestationData={attestationData} isUiChanged={true} />
            )}
          </View>
        </View>
      </View>
      <View style={styles.bodyStyles}>
        <View style={{ marginTop: 160, marginBottom: 20 }}>
          {attestationData != [] && (
            <SessionInfo attestationData={attestationData} isUiChanged={false} />
          )}
        </View>
        <View>
          <UserCard attestationData={attestationData} />
        </View>
        <View style={{ marginTop: 20, marginBottom: 9 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="content"
            textSize={TextSize.Small}
            style={styles.contentText}
          >
            {t("attestation:content")}
          </Text>
        </View>
        {attestationData != [] && <ContentList attestationData={attestationData} />}
        <View style={{ marginTop: 20, marginBottom: 9 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="members"
            textSize={TextSize.Small}
            style={styles.contentText}
          >
            {t("attestation:members")}
          </Text>
        </View>
        {attestationData != [] && <MemberList attestationData={attestationData} />}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headerConatiner: {
    backgroundColor: "#B7504A",
    height: 135,
    paddingRight: 16
  },
  logoStyle: {
    color: colorPallete.white,
    marginTop: 10,
    marginLeft: "auto"
  },
  partnerContainer: {
    marginTop: 4,
    marginRight: 55,
    marginLeft: "auto"
  },
  textIcon: {
    color: colorPallete.white,
    fontSize: 10,
    fontFamily: FontFamily.Light
  },
  headingContainer: {
    position: "absolute",
    top: 45,
    paddingLeft: 16,
    width: "100%",
    paddingRight: 16
  },
  headingStyles: {
    color: colorPallete.white,
    fontSize: 18,
    fontFamily: FontFamily.Bold
  },
  bodyStyles: {
    backgroundColor: "#EEE5DA",
    zIndex: -1,
    paddingLeft: 16,
    paddingRight: 16
  },
  contentText: {
    color: colorPallete.cordioTaupe,
    fontSize: 16,
    fontFamily: FontFamily.Bold
  }
});
