import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";

const prefix = "programs";
const externalRedirection = "externalRedirection";
const PROGRAMS = {
  GET_PROGRAMS: ActionHelper.actionTypesCreator(prefix, "GET_PROGRAMS"),
  GET_ENTITY_INVITE: ActionHelper.actionTypesCreator(prefix, "GET_ENTITY_INVITE"),
  GET_LICENSE_AND_PRICING: ActionHelper.actionTypesCreator(prefix, "GET_LICENSE_AND_PRICING"),
  GET_LICENSE_AND_PRICING_CREDITS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LICENSE_AND_PRICING_CREDITS"
  ),
  GET_LICENSE_AND_PRICING_DEBITS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LICENSE_AND_PRICING_DEBITS"
  ),
  GET_LICENSE_AND_PRICING_ALL: ActionHelper.actionTypesCreator(
    prefix,
    "GET_LICENSE_AND_PRICING_ALL"
  )
};
const SAVE_PROGRAM_DETAILS = "SAVE_PROGRAM_DETAILS";

const EXTERNALREDIRECTION = {
  GET_EXTERNAL_REDIRECTION: ActionHelper.actionTypesCreator(
    externalRedirection,
    "GET_EXTERNAL_REDIRECTION"
  )
};

const SELECTED_PROGRAM_DETAILS = {
  GET_SELECTED_PROGRAM_DETAILS: ActionHelper.actionTypesCreator(
    prefix,
    "GET_SELECTED_PROGRAM_DETAILS"
  )
  // GET_NEW_SESSION_DATA: ActionHelper.actionTypesCreator(prefix, "GET_NEW_SESSION_DATA")
};

export { PROGRAMS, SAVE_PROGRAM_DETAILS, EXTERNALREDIRECTION, SELECTED_PROGRAM_DETAILS };
