import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  getBearerToken,
  getRefreshToken,
  setNewBearerToken
} from "@socion-cordio/common/src/network/authHelper";

export const bearerTokenReqInterceptor = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(async function (config) {
    try {
      const token = await getBearerToken();
      config.headers["Accept-Language"] = "en";
      config.headers["Access-Control-Allow-Origin"] = "*";
      if (token) {
        config.headers["access-token"] = `${token}`;
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  });
  return axiosInstance;
};

export const refreshTokenInterceptor = (
  axiosInstance: AxiosInstance,
  baseURL: string
): AxiosInstance => {
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async function (error) {
      // TODO replace with actual end-point 11/2/2021
      const refreshTokenEndPoint = `${baseURL}/auth/token`;
      const originalRequest = error.config;
      if (originalRequest.url === refreshTokenEndPoint) {
        return Promise.reject(error);
      }
      // TODO replace with actual error code 11/2/2021
      if (
        error.response &&
        error.response.status === "Exipred Token Code" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const refreshToken = getRefreshToken();
        const apiRes = await axios.post(refreshTokenEndPoint, { refreshToken });
        await setNewBearerToken(apiRes.data.data);
        originalRequest.headers.Authorization = `Bearer ${apiRes.data.data}`;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};
