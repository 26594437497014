import { FlatList, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import React from "react";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import SearchFilterSeondary from "@socion-cordio/common/src/components/molecules/searchFilterSecondary";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

const renderEmptyComponent = () => (
  <Text
    fontWeight={FontWeight.Regular}
    testId="internal"
    textSize={TextSize.Small}
    style={[styles.textIcon, { fontSize: 12, color: colorPallete.textBlack, alignSelf: "center" }]}
  >
    No Country Found
  </Text>
);
interface Props {
  data: any;
  onPress: any;
  extraData: any;
  filterCountryCodes: any;
  mainContainerStyle?: ViewStyle | undefined;
}
const CountryCodeDropdown = (props: Props) => {
  const { data, onPress, extraData, filterCountryCodes, mainContainerStyle = {} } = props;
  return (
    <View style={[styles.flatListContainer, mainContainerStyle]}>
      <View style={{ margin: 10 }}>
        <SearchFilterSeondary
          placeHolder={"Search Country / code"}
          customWidthStyle={styles.searchStyle}
          filterList={filterCountryCodes}
        />
      </View>
      <FlatList
        data={data}
        renderItem={({ item }) => (
          <TouchableOpacity onPress={() => onPress(item)}>
            <View style={styles.flatview}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={styles.flatListText}
              >
                {item.country}({item.code})
              </Text>
            </View>
          </TouchableOpacity>
        )}
        ListEmptyComponent={renderEmptyComponent}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.sequence}
        extraData={extraData}
      />
    </View>
  );
};

export default CountryCodeDropdown;

const styles = StyleSheet.create({
  flatListText: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  flatview: {
    paddingTop: 0,
    borderRadius: 2,
    paddingLeft: 10
  },
  flatListContainer: {
    borderWidth: 1,
    height: 300,
    width: 304,
    borderTopWidth: 0,
    borderRadius: 0,
    top: -3,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderColor: colorPallete.cordioRed,
    backgroundColor: colorPallete.white
  },
  searchStyle: {
    width: "100%",
    height: 35
  },
  textIcon: {
    color: colorPallete.textLight,
    fontSize: 14,
    fontFamily: FontFamily.Regular
  }
});
