import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "store/interfaces";
import {
  BUNDLE,
  QUALFICATIONPACKLIST
} from "@socion-cordio/common/src/modules/qualificationPacks/actions/actionTypes";
import { QualificationPackActions } from "@socion-cordio/common/src/modules/qualificationPacks/actions/actions";
import { ViewBundle } from "@socion-cordio/common/src/models/qualificationPacks";
import { QualificationPackList } from "@socion-cordio/common/src/models/qualificationPackList";
import { QualificationPackRepository } from "@socion-cordio/common/src/repositories/qualificationPack/qualificationPack";

function* getQualificationPackWorkerSaga(value: IFluxStandardAction<{ bundleId: number }>) {
  try {
    const { payload }: any = value;
    const response: ViewBundle = yield call(QualificationPackRepository.getBundle, payload);
    yield put(QualificationPackActions.getBundleSuccess(response));
  } catch (error) {
    yield put(QualificationPackActions.getBundleError(error));
  }
}

function* getQualificationPackListWorkerSaga(value: IFluxStandardAction<{ programId: number }>) {
  try {
    const { payload }: any = value;
    const response: QualificationPackList = yield call(
      QualificationPackRepository.getQualificationPackList,
      payload
    );
    yield put(QualificationPackActions.getQualficationPackListSuccess(response));
  } catch (error) {
    yield put(QualificationPackActions.getQualficationPackListError(error));
  }
}

export default function* QualificatioPackWatcherSaga() {
  yield takeLatest(BUNDLE.GET_BUNDLE.LOADING, getQualificationPackWorkerSaga);
  yield takeLatest(
    QUALFICATIONPACKLIST.GET_QUALIFICATIONPACKLIST.LOADING,
    getQualificationPackListWorkerSaga
  );
}
