import LoginForm from "@socion-cordio/common/src/components/organisms/login/login";
// import LoginForm from "@socion-cordio/common/src/components/organisms/loginForm";
import { Login } from "@socion-cordio/common/src/models/login";
import { LoginActions } from "@socion-cordio/common/src/modules/login/actions/actions";
import {
  ILoginError,
  ILoginLoading
} from "@socion-cordio/common/src/modules/login/reducers/loginReducer";
import { LoginSelectors } from "@socion-cordio/common/src/modules/login/selectors/selectors";
import { IState } from "@socion-cordio/common/src/store/interfaces";
import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import {
  setNewBearerToken,
  setNewUser,
  setSuperAdmin
} from "@socion-cordio/common/src/network/authHelper";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import { LoginRepository } from "@socion-cordio/common/src/repositories/login/login";

interface IProps {
  loginSuccessData: Login;
  loginError: ILoginError;
  loginLoading: ILoginLoading;
  postCredentials: Function;
  postCredentialsError: Function;
  postCredentialsSucess: Function;
}

export function LoginPage(props: IProps): ReactElement {
  const history: any = useHistory();
  const [selectedCountryObject, setSelectedCountryObject] = useState(null);
  const [emailVerification, setEmailVerification] = useState(false);

  const submitCredentials = async (data: {
    countryCode: string;
    password: string;
    userName: string;
  }) => {
    let aesUtil: AesUtil = new AesUtil();
    let payload: any = {
      countryCode: data.countryCode,
      userName: data.userName,
      password: aesUtil.encrypt(data.password)
    };
    props.postCredentialsSucess();
    let response = LoginRepository.postCredentials(payload);
    response
      .then((loginData) => {
        if (loginData?.responseCode === HTTP_STATUS_CODES.ok) {
          props.postCredentials(loginData?.response?.accessTokenResponseDTO?.accessToken);
          setNewBearerToken(loginData?.response?.accessTokenResponseDTO?.accessToken);
          setNewUser(loginData?.response?.userDetails);
          getPayload(loginData?.response?.accessTokenResponseDTO?.accessToken);
          history.push(`${routeNames.app}${routeNames.workspace}`);
          LocalStorage.setStorage("isSidePanelCollapsed", false);
          LocalStorage.setStorage("countryCodeObj", selectedCountryObject);
          LocalStorage.setStorage("countryCode", +selectedCountryObject.code.replace("+", ""));
          LocalStorage.setStorage("IsEmailVerification", emailVerification);
          updateTelemetry();
        } else {
          let message = data?.userName?.includes("@") ? "email" : "phone number";
          if (
            loginData?.responseCode === HTTP_STATUS_CODES.badRequest ||
            loginData?.responseCode === HTTP_STATUS_CODES.notFound
            // ||
            // loginData?.responseCode === HTTP_STATUS_CODES.unauthorized
          ) {
            toast.error(
              `This is not a registered ${message}. You should sign up first and then login to the system.`
            );
          } else if (loginData?.responseCode === HTTP_STATUS_CODES.unauthorized) {
            toast.error(loginData?.message);
          } else {
            toast.error(loginData?.message);
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const updateTelemetry = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Login Via Password",
      id: user?.userId
    };
    AddTelemetryService(body, undefined, user);
    googleWebAnalytics("Login", "Telemetry_Event", "Login");
  };

  function getPayload(token: string) {
    //var payload = window.atob(token.split('.')[1]);
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let payload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    if (payload?.realm_access) {
      const { roles } = payload.realm_access;
      const isAdmin = roles.includes("admin");
      setSuperAdmin(isAdmin);
    }
  }

  // useEffect(() => {
  //   if (props.loginError?.postCredentials) {
  //     // toast.error(props.loginError?.postCredentials);
  //   }
  //   if (props.loginSuccessData) {
  //     history.push(`${routeNames.app}${routeNames.workspace}`);
  //     LocalStorage.setStorage("isSidePanelCollapsed", false);
  //   }
  // }, [props.loginSuccessData]);

  return (
    <React.Fragment>
      <LoginForm
        onLoginSubmit={submitCredentials}
        setSelectedCountryObject={setSelectedCountryObject}
        setEmailVerification={setEmailVerification}
      />
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state: IState) => {
  return {
    // selectors go here
    loginSuccessData: LoginSelectors.selectLoginData(state),
    loginError: LoginSelectors.selectLoginError(state),
    loginLoading: LoginSelectors.selectLoading(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  //actions go here

  return { ...bindActionCreators({ ...LoginActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
