import { applyMiddleware, createStore, compose, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import { ConfigHelper } from "@socion-cordio/common/src/utils/loadEnv.util";
import combineReducers from "@socion-cordio/common/src/store/combineReducers";
import rootSaga from "@socion-cordio/common/src/store/saga";
import { IState } from "@socion-cordio/common/src/store/interfaces";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "root",
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, combineReducers);

const reduxDevTools =
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();

const sagaMiddleware = createSagaMiddleware();

export const store = (): Store<IState> => {
  let store =
    ConfigHelper.getNodeEnv() === "development" && (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? createStore(persistedReducer, compose(applyMiddleware(sagaMiddleware), reduxDevTools))
      : createStore(persistedReducer, compose(applyMiddleware(sagaMiddleware)));
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return store;
};

export default store;
