import React, { ReactElement, useState, useEffect, useRef } from "react";
import { StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { topNavbarEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import SocionModal from "@socion-cordio/common/src/components/atoms/modal";
import ConfirmationPopupModal from "@socion-cordio/common/src/components/organisms/confirmationPopupModal";
import { enableScroll, disableScroll } from "@socion-cordio/common/src/utils/scrollToTop";
import _ from "lodash";

interface Props {
  onClose?: Function;
}

export default function NotificationModal(props: Props): ReactElement {
  var groups: any = [];
  var pageValue: any = 0;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListUpdated, setNotificationListUpdated] = useState([]);
  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    pageSize: 10
  });
  const [endReached, setEndReached] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaders, setLoader] = useState(true);
  const [pagination, setPagination] = useState(0);
  const collapseRef = useRef(false);
  const scrollRef: any = useRef();

  useEffect(() => {
    if (confirmationModal) disableScroll();
    // else enableScroll();
  }, [confirmationModal]);

  useEffect(() => {
    getNotificationDetails(1);
  }, []);

  useEffect(() => {
    updateNotificationDetails();
  }, [paginationData.pageNo]);

  const updateNotificationDetails = () => {
    if (notificationData !== null) {
      if (notificationData.total !== notificationData.notifications.length) {
        getNotificationDetails(paginationData.pageNo);
        setLoading(false);
        setEndReached(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getNotificationDetails = async (pageNumber: number) => {
    const payload = {
      timeZone: new Date().toTimeString().slice(9)
    };
    try {
      const NotificationResponse = await ApiClient.post(
        topNavbarEndPoints.postNotifications(pageNumber, paginationData.pageSize),
        payload
      );
      if (NotificationResponse.responseCode === HTTP_STATUS_CODES.ok) {
        let list: any = [];
        setNotificationListUpdated((prevList) => {
          list = [...prevList, ...NotificationResponse.response.notifications];
          return [...prevList, ...NotificationResponse.response.notifications];
        });
        const listWithoutDuplicate = _.uniqBy(list, "notificationId");
        const dataNew: any = {
          pageNumber: NotificationResponse.response.pageNumber,
          pageSize: NotificationResponse.response.pageSize,
          total: NotificationResponse.response.total,
          notifications:
            pageNumber === 1 ? NotificationResponse.response.notifications : listWithoutDuplicate
        };
        setNotificationData(dataNew);
        extractDataHandler(dataNew);
        setLoader(false);
      } else {
        toast.error(NotificationResponse.message);
        setLoader(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteReadNotification = async (
    notificationId: number,
    deletedValue: boolean,
    notificationDate?: string
  ) => {
    setLoader(deletedValue); // to show loader, if true - show main loader,
    const payload = {
      isDeleted: deletedValue,
      isRead: true,
      notificationId: notificationId
    };
    let deleteResponse = await ApiClient.put(topNavbarEndPoints.deleteReadNotification(), payload);
    if (deleteResponse.responseCode === HTTP_STATUS_CODES.ok) {
      if (deletedValue) {
        deleteNotificationHandler(deleteResponse.message);
      } else {
        setReadNotificationHandler(notificationId, notificationDate);
      }
      setLoader(false);
    } else {
      toast.error(deleteResponse.message);
      setLoader(false);
    }
  };

  const deleteNotificationHandler = (message: string) => {
    setNotificationData(null);
    setNotificationList([]);
    // extractDataHandler(null);
    toast.success(message);
    setPaginationData({
      pageNo: 1,
      pageSize: 10
    });
    setTimeout(() => {
      getNotificationDetails(1);
    }, 1000);
  };

  const setReadNotificationHandler = (notificationId: number, notificationDate: string) => {
    const notificationListTemp: any = [...notificationList];
    const list = notificationListTemp[0].filter((item: any) => item.date === notificationDate);
    const selectedNotification = list[0].array.filter(
      (item: any) => item.notificationId === notificationId
    );
    selectedNotification[0].isRead = true;
    setNotificationList(notificationListTemp);
  };

  const isCloseToBottom = (x: any) => {
    const extraOffSet: number = 150;
    return (
      Math.ceil(x.layoutMeasurement.height + x.contentOffset.y + extraOffSet) >=
      x.contentSize.height
    );
  };

  const handleScroll = (e: any) => {
    const closeToBottom = isCloseToBottom(e.nativeEvent);
    if (closeToBottom) {
      setEndReached(true);
      setLoading(true);
      setTimeout(() => {
        setPaginationData({ ...paginationData, pageNo: paginationData.pageNo + 1 });
      }, 400);
    }
  };

  const extractDataHandler = (notificationData: any) => {
    setNotificationList([]);
    let data = { ...notificationData };
    let list: any = [];
    data?.notifications?.forEach(function (val: any) {
      var date = val.dateTime != null && val.dateTime.split(" ")[0];
      var dateValue = 0;
      groups.map((item: any, index: number) => {
        if (item.date === date) {
          item.array.push(val);
          dateValue = 1;
        }
      });
      if (dateValue === 0) {
        groups.push({ date: date, array: [val] });
      }
    });
    list.push(groups);
    setNotificationList(list);
  };

  const calculateDateHandler = (date: any) => {
    const dateNew = new Date(date);
    var timeOffsetInMS: number = dateNew.getTimezoneOffset() * 60000;
    dateNew.setTime(dateNew.getTime() - timeOffsetInMS);
    return dateNew.toLocaleTimeString("en-US").toLowerCase();
  };

  const iconNotificationHandler = (notificationType: string) => {
    switch (notificationType) {
      case "USER":
        return (
          <Icon
            testID="close"
            name={IconNames.trainee}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );
      // break;

      case "ATTENDANCE":
        return (
          <Icon
            testID="close"
            name={IconNames.scanAttendance}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );
      // break;

      case "SESSION":
        return (
          <Icon
            testID="close"
            name={IconNames.sessionIcon}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );
      // break;

      case "TASK":
        return (
          <Icon
            testID="close"
            name={IconNames.taskIcon}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );
      // break;

      case "ATTESTATION":
        return (
          <Icon
            testID="close"
            name={IconNames.attestationIcon}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );

      case "PROGRAM":
        return (
          <Icon
            testID="program"
            name={IconNames.program}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );

      case "TOPIC":
        return (
          <Icon
            testID="topics"
            name={IconNames.topics}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );
      // break;

      case "BADGE":
        return (
          <Icon testID="close" name={IconNames.badges} size={20} color={colorPallete.cordioTaupe} />
        );

      case "QP":
        return (
          <Icon testID="close" name={IconNames.qpIcon} size={20} color={colorPallete.cordioTaupe} />
        );
      // break;

      default:
        return (
          <Icon
            testID="close"
            name={IconNames.trainee}
            size={20}
            color={colorPallete.cordioTaupe}
          />
        );
      // break;
    }
  };

  return (
    <>
      <ToastContainer />
      <>
        <View style={[styles.header, styles.container]}>
          <View style={{}}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.miniContainerHeaderText}
            >
              {"Notifications"}
            </Text>
          </View>
          <View style={{}}>
            <Icon
              testID="close"
              name={IconNames.crossCircle}
              customStyle={styles.crossIcon}
              onPress={() => props.onClose()}
            />
          </View>
        </View>
        {loaders ? (
          <Loader customLoadingContainer={styles.modalLoader} />
        ) : (
          <>
            {notificationList[0]?.length !== 0 ? (
              <ScrollView
                nativeID={"textAreaId"}
                style={[styles.container, styles.alignContainer]}
                // showsVerticalScrollIndicator={false}
                ref={scrollRef}
                onScrollBeginDrag={() => (collapseRef.current = false)}
                onScroll={(e) => handleScroll(e)}
                scrollEventThrottle={1}
              >
                {notificationList.map((prop: any, indexValue: number) => {
                  return prop.map((notification: any, key: number) => {
                    return (
                      <View style={loading && { paddingBottom: 20 }}>
                        <View style={[styles.header, { marginBottom: 10 }]}>
                          {((notificationList &&
                            notificationList.length >= 2 &&
                            notificationList[notificationList.length - 2][
                              notificationList[notificationList.length - 2].length - 1
                            ].date !== notification.date) ||
                            indexValue === 0) && (
                            <View>
                              <Text
                                fontWeight={FontWeight.Light}
                                testId="text"
                                textSize={TextSize.Small}
                                textStyle={[
                                  styles.containerHeaderText
                                  //  styles.dateTime
                                ]}
                              >
                                {new Date(notification.date) === new Date()
                                  ? "Today"
                                  : new Date(notification.date) === new Date(Date.now() - 864e5)
                                  ? "Yesterday"
                                  : Moment(notification.date).format("DD MMM YY")}
                              </Text>
                            </View>
                          )}
                          {notificationList[0]?.length !== 0 ? (
                            <View style={{}}>
                              {notificationList[0][0].date === notification.date && (
                                <View style={{}}>
                                  <Text
                                    fontWeight={FontWeight.Light}
                                    testId="ClearAllText"
                                    textSize={TextSize.Small}
                                    textStyle={[
                                      // styles.clearText,
                                      styles.containerHeaderText
                                    ]}
                                    onPress={() => setConfirmationModal(true)}
                                  >
                                    {"Clear all"}
                                  </Text>
                                </View>
                              )}
                            </View>
                          ) : (
                            <View></View>
                          )}
                        </View>
                        {notification.array.map((value: any, index: number) => {
                          return (
                            <>
                              <TouchableOpacity
                                onPress={() =>
                                  deleteReadNotification(
                                    value.notificationId,
                                    false,
                                    notification.date
                                  )
                                }
                              >
                                <View key={key} style={styles.subContainer}>
                                  <View
                                    style={
                                      value.isRead
                                        ? [styles.miniContainer, styles.miniContainerReadBorder]
                                        : [styles.miniContainer, styles.miniContainerBorder]
                                    }
                                  >
                                    {iconNotificationHandler(value.notificationType)}
                                    {/* <Icon
                                      testID="trainee"
                                      name={IconNames.trainee}
                                      customStyle={[styles.alignContainerButton]}
                                    /> */}
                                    <View style={styles.descriptionContainer}>
                                      <Text
                                        fontWeight={FontWeight.Regular}
                                        testId="headerText"
                                        textSize={TextSize.Small}
                                        textStyle={[styles.headerText]}
                                      >
                                        {value.description}
                                      </Text>
                                      <View style={styles.timeContainer}>
                                        <Text
                                          fontWeight={FontWeight.Light}
                                          testId="timeText"
                                          textSize={TextSize.Small}
                                          textStyle={styles.headerText}
                                        >
                                          {calculateDateHandler(value.dateTime)}
                                        </Text>
                                      </View>
                                    </View>
                                    <View style={styles.miniIconContainer}>
                                      <TouchableOpacity
                                        onPress={() =>
                                          deleteReadNotification(value.notificationId, true)
                                        }
                                      >
                                        <Icon
                                          testID="delete"
                                          name={IconNames.deleteFile}
                                          customStyle={[styles.alignContainerButton]}
                                        />
                                      </TouchableOpacity>
                                    </View>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </>
                          );
                        })}
                      </View>
                    );
                  });
                })}
              </ScrollView>
            ) : (
              <View style={styles.noNotificationCointainer}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="headerText"
                  textSize={TextSize.Small}
                  textStyle={styles.miniContainerHeaderText}
                >
                  {"There are no notifications for you."}
                </Text>
              </View>
            )}
          </>
        )}

        {loading && notificationData.total !== notificationData.notifications.length && (
          <View style={styles.loadingContainer}>
            <Text
              fontWeight={FontWeight.Bold}
              testId="headerText"
              textSize={TextSize.Small}
              textStyle={styles.miniContainerHeaderText}
            >
              {"Loading..."}
            </Text>
          </View>
        )}
      </>

      {confirmationModal && (
        <View>
          <SocionModal
            modalVisible={confirmationModal}
            setModalVisible={() => setConfirmationModal(!confirmationModal)}
            component={
              <ConfirmationPopupModal
                onClose={() => setConfirmationModal(false)}
                getNotificationDetails={getNotificationDetails}
              />
            }
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 500,
    padding: 10
  },
  alignContainer: {
    paddingTop: 0,
    marginBottom: 15,
    marginRight: 5,
    paddingLeft: 15
  },
  dateTime: {
    marginLeft: 205,
    marginBottom: 10
  },
  button: {
    width: 140,
    height: 50,
    borderRadius: 10
  },
  buttonFont: {
    fontSize: 12
  },
  timeContainer: {
    marginTop: 6
  },
  clearText: {
    marginLeft: 320,
    textDecorationLine: "underline",
    marginRight: 18,
    marginBottom: 20
  },
  descriptionContainer: {
    width: 325,
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  subContainer: {
    flexDirection: "row",
    marginBottom: 15
    // width: 680
    // backgroundColor:'red'
  },
  crossIcon: {
    fontSize: 15
    // marginBottom: 22,
    // marginTop: 22,
    // marginLeft: 350,
    // marginRight: 20
  },
  headerText: {
    color: colorPallete.textLight,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 17
  },
  miniContainer: {
    padding: 20,
    paddingVertical: 10,
    width: 475,
    // marginLeft: 205,
    // backgroundColor:'cyan',
    // width: "439px",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  miniContainerReadBorder: {
    borderColor: colorPallete.cordioTaupe
  },
  miniContainerBorder: {
    borderColor: colorPallete.cordioOrange
  },
  miniContainerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 14,
    fontFamily: FontFamily.Medium,
    lineHeight: 20
  },
  miniIconContainer: {
    flexDirection: "row"
  },
  alignContainerButton: {
    fontSize: 18,
    color: colorPallete.cordioTaupe,
    marginRight: 5
  },
  notificationrHeaderText: {
    marginTop: 22,
    marginLeft: 240
  },
  containerHeaderText: {
    color: colorPallete.textBlack,
    fontSize: 12,
    fontFamily: FontFamily.Regular,
    lineHeight: 20
  },
  loader: {
    height: 50,
    width: 50,
    marginLeft: 200
  },
  dateContainer: {
    marginRight: 435
  },
  modalLoader: {
    height: 100,
    width: 100,
    marginTop: 280,
    marginRight: 200
  },
  loadingContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center"
  },
  noNotificationCointainer: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center"
  }
});
