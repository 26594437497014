import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { useState } from "react";

export const useTogglePasswordVisibility = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [rightIcon, setRightIcon] = useState(IconNames.passwordHidden);

  const handlePasswordVisibility = () => {
    if (rightIcon === IconNames.passwordHidden) {
      setRightIcon(IconNames.passwordVisible);
      setPasswordVisibility(!passwordVisibility);
    } else if (rightIcon === IconNames.passwordVisible) {
      setRightIcon(IconNames.passwordHidden);
      setPasswordVisibility(!passwordVisibility);
    }
  };

  return {
    passwordVisibility,
    rightIcon,
    handlePasswordVisibility
  };
};
