import React, { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { Provider } from "react-redux";
// import MainRouter from "@socion-cordio/web/src/navigation/mainRouter";
import store from "@socion-cordio/common/src/store/store";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { StoreProviderService } from "@socion-cordio/common/src/services/StoreProviderService";
import { I18nService } from "@socion-cordio/common/src/services/internalization/I18nextService";
import { SupportedLanguages } from "@socion-cordio/common/src/services/internalization/constants";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/styles/rsuite-default.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import { I18nextProvider } from "react-i18next";
// import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { debounce } from "utils/debounce";
import LoginRouter from "modules/login/navigation/loginRouter";
import { pRoutes } from "navigation/publicRouter";
import StaticPage from "@socion-cordio/common/src/components/atoms/staticPage";

const MainRouter = lazy(() => import("@socion-cordio/web/src/navigation/mainRouter"));

const firebaseConfig = {
  apiKey: "AIzaSyDP6pOrBjyqb5GdwbOEcM6fB20dC5CXT88",
  authDomain: "socion-85e59.firebaseapp.com",
  databaseURL: "https://socion-85e59.firebaseio.com",
  projectId: "socion-85e59",
  storageBucket: "socion-85e59.appspot.com",
  messagingSenderId: "392963983023",
  appId: "1:392963983023:web:e0285ceeb0b0b8eb4fb65a",
  measurementId: "G-T2ND0QH6N2"
};

var app = initializeApp(firebaseConfig);
var analytics = getAnalytics(app);

// Initialize store
// StoreProviderService.init(store);
// const reduxStore = StoreProviderService.getStore();

const App = () => {
  const [isLocalizationInitialised, setLocale] = useState(false);

  let publicRoutes: Array<any> = [...LoginRouter(), ...pRoutes()];
  publicRoutes.pop();

  const onWindowOrTabSwitch = useMemo(() => debounce((e: any) => visibilityChangeHandler(e)), []);

  const visibilityChangeHandler = (e: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const pathname = window.location.pathname;
    let filteredPath;

    if (pathname.includes("/attestation")) {
      filteredPath = publicRoutes.filter((route) => {
        return route.path.includes("/attestation");
      });
    } else {
      filteredPath = publicRoutes.filter((route) => {
        return pathname.includes(route.path);
      });
    }

    const matches = filteredPath.filter((route) => {
      return !pathname.includes("/new/app");
    });

    if (!accessToken && matches.length === 0) {
      console.log("No Token Found. Redirecting to /login...");
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/new/iam/login`;
    }
  };

  document.addEventListener("visibilitychange", onWindowOrTabSwitch, false);

  useEffect(() => {
    initLang().then();
  }, []);

  const initLang = async (): Promise<void> => {
    const storedLang: SupportedLanguages = await LocalStorage.getStorage("lang");

    if (!storedLang) {
      LocalStorage.setStorage("lang", SupportedLanguages.English).then();
      I18nService.init(SupportedLanguages.English).then();
    } else {
      await I18nService.init(storedLang);
    }
    setLocale(true);
  };

  if (!isLocalizationInitialised) return null;

  return (
    // <Provider store={reduxStore}>
    <I18nextProvider i18n={I18nService.instance}>
      <React.Fragment>
        <Suspense fallback={<StaticPage />}>
          <MainRouter />
        </Suspense>
      </React.Fragment>
    </I18nextProvider>
    // </Provider>
  );
};

export default App;
