import { googleWebAnalytics } from "@socion-cordio/web/src/utils/firebaseAnalytics";
import React, { ReactElement } from "react";
import { StyleSheet, View, Text } from "react-native";

export default function TermsOfUse(): ReactElement {
  return (
    <View>
      <View>
        <View>
          <View>
            <View style={styles.profileCol}>
              <View style={styles.textCenter}>
                <Text style={styles.heading}>TERMS OF USE</Text>
                <Text style={styles.subHeading}>WITH EFFECT FROM OCT 10, 2019</Text>
              </View>
              <br />
              <View>
                <Text
                  style={{ fontFamily: "times new roman", lineHeight: "24px", fontSize: "16px" }}
                >
                  This is a legal agreement (<b>“Agreement”</b>) between You (the person accessing,
                  viewing, using, or installing the Application, and later referred to as{" "}
                  <b>“you”</b>, <b>“User”</b> or
                  <b>“Subscriber”</b> as per the context) and Socion Advisors LLP. (
                  <b>“we”, “us” or “our”</b>).
                  <br />
                  <br />
                  We request you to carefully go through these terms and conditions for use of the
                  PDA Trainer Application, PDA Participant Application, and PDA Program
                  Administration Portal, Socion’s website and all content and Socion products and
                  services available at or through these websites and applications (together called
                  “Application”) (“Terms of Use” or “Terms”) before you decide to avail the Services
                  (defined below) of Socion. These Terms of Use apply to your visit to and your use
                  of the Application to avail the Services whether through a computer or in a mobile
                  phone, as well as to all information provided by you on the App. By downloading or
                  accessing the Application to avail our Services (defined below), you irrevocably
                  accept all the obligations stipulated in these Terms of Use as well as the
                  Socion’s{" "}
                  <a
                    href="/new/privacy-policy"
                    target="_blank"
                    style={{ textDecorationLine: "none" }}
                    onClick={() =>
                      googleWebAnalytics(
                        "Terms_Of_Use_PrivacyPolicy_HyperLink",
                        "Button",
                        "Terms_Of_Use"
                      )
                    }
                  >
                    Privacy Policy
                  </a>
                  , as may be amended from time to time and agree to abide by them. These Terms of
                  Use supersede all previous oral and written terms and conditions (if any)
                  communicated to you.
                </Text>
                <View style={{ color: "black" }}>
                  <br />
                  <ol>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      OVERVIEW
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          The Company is engaged in the business of providing technology enabled
                          services that makes it possible for organizations and inViewiduals to
                          interact with each other during training or other sessions conducted by
                          the organization, and to exchange information, content, and digitally
                          verifiable attestations or data relating to those interactions (
                          <b>“Services”</b>).
                        </li>
                        <br />
                        <li>
                          We reserve the right to modify or terminate any portion of the Application
                          or the Services offered by Socion for any reason, without notice and
                          without liability to you or any third party. To make sure you are aware of
                          any changes, please review these Terms of Use periodically. Additionally,
                          the new Terms of Use may be displayed on the Application screen and you
                          may be required to read and accept them to continue your use of the
                          Application and/or the Services We reserve the right, at our sole
                          discretion, to modify or replace these Terms at any time. If a revision is
                          material, we will provide at least 30 days’ notice prior to any new terms
                          taking effect. What constitutes a material change will be determined at
                          our sole discretion.
                        </li>
                        <br />
                        <li>
                          By continuing to access or use our Services after any revisions become
                          effective, you agree to be bound by the revised terms. If you do not agree
                          to the new terms, you are no longer authorized to use the Service.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      REGISTRATION
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          For accessing the App, you will have to register on the Application as a
                          user (<b>“Application User” or “User”</b>). To register as a User in the
                          App, you will have to provide your First & last name, mobile phone number,
                          and email address. You may also be given the option to provide additional
                          information at your discretion to improve your experience, For example
                          profile picture.
                        </li>
                        <br />
                        <li>
                          Registration as a User is only a one-time process and if you have been
                          previously registered, you shall login / sign into your account using the
                          same credentials as provided by you during the registration process (
                          <b>“Account”</b>). You are responsible for maintaining the confidentiality
                          of your Account and password, including but not limited to restricting
                          access to your computer (or mobile device) and/or Account. You agree to
                          accept responsibility for any and all activities or actions that occur
                          under your Account. You must notify us immediately upon becoming aware of
                          any breach of security or unauthorized use of your Account.
                        </li>
                        <br />
                        <li>
                          You may not use as a username which is the name of another person or
                          entity or that is not lawfully available for use, a name or trademark that
                          is subject to any rights of another person or entity other than you,
                          without appropriate authorization. You may not use as a username any name
                          that is offensive, vulgar or obscene. You represent and warrant that all
                          Users of your Account are above the age of 18, and that the information
                          provided on or through your Account is accurate, complete, and current at
                          all times. Providing inaccurate, incomplete, or obsolete information may
                          result in the immediate termination of your Account.
                        </li>
                        <br />
                        <li>
                          When you create an account, we will consider that to be an inquiry about
                          our products and services, which means that we may contact you to discuss
                          further details about what we have to offer. However, if you are not
                          interested in learning more, you may choose to opt out of the
                          communication.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      SPECIFIC SERVICE TERMS
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          Applications and Accounts
                          <br />
                          <br />
                          <ol>
                            <li>
                              Participant Application allows users to set up and manage their
                              account details and personal profile, use the scan functionality to
                              scan-in and scan-out of sessions that are set up by a trainer using
                              Trainer App, receive attestations for sessions that the user
                              participates in, view attestation details including topic content,
                              download such content as they see fit, and share attestation or
                              content with others.
                            </li>
                            <br />
                            <li>
                              Trainer Application can be used by trainers to set up sessions using
                              topics available in the Program Administration Portal (for which they
                              need access – which is granted by the program administrators),
                              interact with session participants to track who participated in each
                              session, and trigger a system generated attestation to both trainers
                              and participants that also provides a link to the content from each
                              session.
                            </li>
                            <br />
                            <li>
                              Program Administration Portal is available to entities that oversee
                              capacity building or other programs and conduct frequent sessions for
                              training, mentoring or dissemination of information or knowledge. The
                              portal allows each entity to fully control who has access to the
                              portal and its features and is responsible for vetting these
                              inViewiduals and monitoring their efforts to add, edit and update
                              programs, topics and content. Designated program administrators also
                              have the ability to enroll trainers, who will then have the ability to
                              use the topics and content from the program portal to set up and run
                              sessions on behalf of the program or entity.
                            </li>
                            <br />
                            <li>
                              We also offer a set of services and applications that are used by
                              programs and participants of such programs, to interact with each
                              other, track participation or attendance, distribute or access
                              training content and so on (<b>“Participating Program”</b>). Every
                              time you participate in a (training or other) session run by a program
                              using the Application in order to track interactions with participants
                              (For example; attending a training session), you are providing us
                              consent to share your personal information, with such Participating
                              Programs. Such information once shared with the programs will be
                              outside the control of the Company and will no longer be subject to
                              this Privacy Policy or these Terms of Use. Any use of such data, once
                              shared with each program or in the possession of such program after
                              each session, is subject to the privacy agreement between you, the
                              participant, and the entities running that program.
                            </li>
                            <br />
                            <li>
                              While some Services are currently free, and we offer paid plans for
                              the Program administration portal. An account created on the
                              Application also allows you to sign-in to the other Services using the
                              same account credentials.
                            </li>
                            <br />
                            <li>
                              Our Service are designed to give you as much control and ownership
                              over what information or content you or your representatives put on
                              the platform through your account. You own all content you post on the
                              platform and associated mobile applications or websites. However, be
                              responsible in what you publish. In particular, make sure that none of
                              the prohibited items (such as inappropriate content, spam, viruses, or
                              serious threats of violence) appear on your website. If you find any
                              content that you believe violates these Terms, please notify the
                              respective program administrator. In the event you do not receive a
                              satisfactory response from the program administrator, you may contact
                              the Grievance Officer noted below.
                            </li>
                            <br />
                            <li>
                              One key element of our Services is to democratize access to knowledge
                              and training content required to solve societal challenges. All the
                              content (including Training Content) published on the Application by
                              the Participating Program, their representatives and partners will be
                              openly available for use, may be shared openly without any limitations
                              of use, and is licensed under the creative commons license framework
                              (CC-BY 4.0) as an open learning resource. If you do not wish to openly
                              share, or have reason to believe that any content you intend to use in
                              your training session should not be shared openly, DO NOT upload any
                              such information or training content into this application. You can
                              still use and take advantage of the services minus such content.
                            </li>
                            <br />
                          </ol>
                        </li>
                        <br />
                        <li>
                          By using App, you also grant to Socion a license to collect, analyze and
                          share aggregated data regarding the access to and use of such content.
                          Please review the Privacy Policy for more details
                        </li>
                        <br />
                        <li>
                          If you delete Content, we will use reasonable efforts to remove it from
                          public view (or from view by the authorized visitors) on the App, however,
                          if such content was already shared, used in a session, downloaded or
                          cached , you acknowledge that caching, copies or references to the Content
                          may not be made unavailable.
                        </li>
                        <br />
                        <li>
                          By using the App, you represent and warrant that your Content and conduct
                          do not violate the Privacy Policy.
                        </li>
                        <br />
                        <li>
                          Users of the Program Administration Portal should ensure that Paid
                          Services are renewed periodically. Users of the Participant Application
                          and Trainer Application can continue to use the respective App, but may
                          experience some service restrictions (as determined by Socion from time to
                          time) if they are not an active user. An Active user is one that has
                          participated in a session conducted by a Participating Program, or
                          conducted a session as a trainer of a Participating Program, at least once
                          in the preceding 12 calendar months. As a policy, all Application users
                          will be able to view or download content that was received by them
                          (through their attestations) within the preceding 24 calendar months at
                          any time.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      CONDITIONS TO USE
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          The Services will be provided on best efforts basis. Socion will make
                          reasonable efforts and shall endeavour that you are able to use the
                          Services without undue disruption, interruption or delay.
                        </li>
                        <br />
                        <li>
                          By creating an Account on the App, you may receive updates, promotional
                          materials and other information we may send with regards to the Service,
                          or new services we may offer. You may opt out of receiving any, or all, of
                          these communications from us by following the unsubscribe link or
                          instructions provided in any email we send. You will continue to receive
                          important updates with regards to your Account.
                        </li>
                        <br />
                        <li>
                          Our Service may allow you to post, link, store, share and otherwise make
                          available certain information, text, graphics, videos, or other material (
                          <b>"Content"</b>). You are responsible for the Content that you post on or
                          through the Service, including its legality, reliability, and
                          appropriateness.
                        </li>
                        <br />
                        <li>
                          By posting Content on or through the Service, you represent and warrant
                          that: (i) the Content is owned by you and/or you have the right to use it
                          and the right to grant us the rights and license as provided in these
                          Terms, and (ii) that the posting of your Content on or through the Service
                          does not violate the privacy rights, publicity rights, copyrights,
                          contract rights or any other rights of any person or entity. We reserve
                          the right to terminate the Account of anyone found to be in breach of
                          these Terms, or other laws, rules or regulations, including intellectual
                          property laws.
                        </li>
                        <br />
                        <li>
                          You retain any and all of your rights to any Content you submit, post or
                          display on or through the Service and you are responsible for protecting
                          those rights. We take no responsibility and assume no liability for
                          Content you or any third-party posts on or through the Service. However,
                          by posting Content using the Service you grant us the right and license to
                          use, modify, publicly perform, publicly display, reproduce, and distribute
                          such Content on and through the Service. You agree that this license
                          includes our right to make your Content available to other users of the
                          Service, and such users may also use, modify, or view your Content subject
                          to these Terms.
                        </li>
                        <br />
                        <li>
                          We do not endorse any Content nor represent that Content is accurate,
                          useful, or non-harmful. Content could be offensive, indecent, or
                          objectionable; include technical inaccuracies, typographical mistakes, or
                          other errors; or violate or infringe the privacy, publicity rights,
                          intellectual property rights or other proprietary rights of third parties.
                          We disclaim any responsibility for any harm resulting from anyone’s use,
                          viewing, or downloading of Content. If you access or use any Content, you
                          are responsible for taking precautions as necessary to protect yourself
                          and your computer systems from viruses, worms, Trojan horses, and other
                          harmful or destructive content. Any Content offered through any of our
                          Services are the Content provider or Content creator’s (the person,
                          persons or entity that was responsible for uploading, adding or sharing
                          the content or materials) sole responsibility, and you agree that Socion
                          shall not be liable for any damages that may result from your use of the
                          Content.
                        </li>
                        <br />
                        <li>
                          Socion has the right but not the obligation to monitor and edit all
                          Content provided by you. In addition, certain content found on or through
                          this Service is the property of Socion or Socion has a license to use such
                          content (<b>“Socion Content”</b>). You may not distribute, modify,
                          transmit, reuse, download, re-post, copy, or use Socion Content, whether
                          in whole or in part, for commercial purposes or for personal gain, without
                          express advance written permission from us.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      YOUR RESPONSIBILITIES
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          By using the App, you represent and warrant that:
                          <br />
                          <br />
                          <ol>
                            <li>
                              You are 18 years of age or older and that your use of the Application
                              shall not violate any applicable law or regulation;
                            </li>
                            <br />
                            <li>
                              You have fully read and understood the Terms of Use and Privacy Policy
                              of the Application and consent to them.
                            </li>
                            <br />
                            <li>
                              You shall ensure that the requisite information provided by you to us
                              for the Services on the App, is absolutely true, up-to-date and
                              correct and nothing material has been concealed, and that you agree to
                              maintain the accuracy of such information. Socion will not be liable
                              for any incident occurring due to incorrect or insufficient
                              information provided by you;
                            </li>
                            <br />
                            <li>
                              Will not use the Services for any unlawful purposes, to publish
                              illegal content, or in furtherance of illegal activities;
                            </li>
                            <br />
                            <li>
                              Will not infringe or misappropriate the intellectual property rights
                              of any third party;
                            </li>
                            <br />
                            <li>
                              Will not overburden the Application systems, as determined by us in
                              our sole discretion;
                            </li>
                            <br />
                            <li>Will not be used to send spam or bulk unsolicited messages;</li>
                            <br />
                            <li>
                              Will not interfere with, disrupt, or attack any service or network;
                            </li>
                            <br />
                            <li>
                              Will not be used to create, distribute, or enable material that is –
                              or that facilitates or operates in conjunction with – malware,
                              spyware, adware, or other malicious programs or code;
                            </li>
                            <br />
                            <li>
                              You will not disclose sensitive personal information of others; and
                            </li>
                            <br />
                            <li>
                              You will not use the Application or the Services in violation of these
                              Terms or the Privacy Policy.
                            </li>
                            <br />
                          </ol>
                        </li>
                        <br />
                        <li>
                          We are and we will remain the owner of the Application at all times, and
                          you must not otherwise copy, transmit, distribute, reproduce (for
                          compensation or otherwise), license, alter, adapt or modify the whole or
                          any part of the Application in any way. You acknowledge that all
                          information, content and material contained on the Application is our
                          intellectual property. All trademarks, services marks, trade names, and
                          trade dress are proprietary to Socion. No information, content or material
                          from the Application may be copied, reproduced, republished, uploaded,
                          posted, transmitted or distributed in any way without our express written
                          permission
                        </li>
                        <br />
                        <li>
                          You acknowledge that rights in the Application are licensed (not sold) to
                          you, and that you have no rights in, or to, the Application or the
                          technology used or supported by the Application or any Services other than
                          the right to use each of them in accordance with the terms of these Terms
                          of Use.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      DISCLAIMER
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          You expressly understand and agree that:
                          <br />
                          <br />
                          <ol type="a" id="list-alpha">
                            <li>
                              The information, content and materials on the Application and / or
                              Services is provided on an “as is” and “as available” basis. Socion
                              and its subsidiaries, affiliates, officers, employees, agents,
                              partners and licensors disclaim all warranties of any kind, either
                              express or implied, including but not limited to, implied warranties
                              on merchantability, fitness for a particular purpose and
                              non-infringement;
                            </li>
                            <br />
                            <li>
                              Socion does not warrant that the functions contained in content,
                              information and materials on the Application and / or Services,
                              including, without limitation any third party sites or services linked
                              to the Application and / or Service will be uninterrupted, timely or
                              error-free, that the defects will be rectified, or that the
                              Application or the servers that make such content, information and
                              materials available are free of viruses or other harmful components;
                            </li>
                            <br />
                            <li>
                              Any material downloaded or otherwise obtained through the Application
                              are accessed at your own risk, and you will be solely responsible for
                              any damage or loss of data that results from such download to your
                              computer system. Please note that additional third party terms and
                              conditions may apply to the downloading, copying, purchase, or use of
                              Content;
                            </li>
                            <br />
                            <li>
                              We also have not reviewed, and cannot review, all of the material,
                              including data or content made available through the websites and web
                              pages that link to, or are linked from our Services. For example:
                            </li>
                            <br />
                            <li>
                              We do not have any control over those websites and are not responsible
                              for their contents or their use.
                            </li>
                            <br />
                            <li>
                              The existence of a link to or from one of our Services does not
                              represent or imply that we endorse such website.
                            </li>
                            <br />
                            <li>
                              You are responsible for taking precautions as necessary to protect
                              yourself and your computer systems from viruses, worms, Trojan horses,
                              and other harmful or destructive content.
                            </li>
                            <br />
                            <li>
                              We disclaim any responsibility for any harm resulting from non-Socion
                              or third party websites. and
                            </li>
                            <br />
                            <li>
                              If any open-source software is included in the Application or any
                              Services, the terms of an open-source license may override some of the
                              terms of these Terms of Use.
                            </li>
                            <br />
                          </ol>
                        </li>
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      FEES AND PAYMENT
                      <br />
                      <br />
                      <ol
                        type="a"
                        id="list-alpha"
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          Some of our Services are offered for a fee, such as Program Administration
                          Portal (collectively, <b>“Paid Services”</b>). By using a Paid Service,
                          you agree to pay the specified fees on time (this is governed by a
                          separate contract at this time).
                        </li>
                        <br />
                        <li>
                          To the extent permitted by law, or unless explicitly stated otherwise, all
                          fees are exclusive of applicable federal, provincial, state, local or
                          other governmental sales, goods and services, harmonized or other taxes,
                          fees, or charges now in force or enacted in the future (<b>“Taxes”</b>).
                          You are responsible for payment of all applicable Taxes relating to your
                          use of our Services, your payments, or your purchases. If we are obligated
                          to pay or collect Taxes on the Fees you’ve paid or will pay, you are
                          responsible for such Taxes, and we may collect payment for such Taxes.
                        </li>
                        <br />
                        <li>
                          If your payment fails or if Paid Services are otherwise not paid for or
                          paid for on time, we may immediately cancel or revoke your access to the
                          Paid Services
                        </li>
                        <br />
                        <li>
                          To ensure uninterrupted service, you need to ensure that Paid Services are
                          renewed periodically. This means that unless you renew a Paid Service
                          before the end of the applicable subscription period, it will
                          automatically be terminated. You authorize us to invoice you or use any
                          payment mechanism we have on record for you to collect the applicable
                          subscription fee (as well as any Taxes).
                        </li>
                        <br />
                        <li>
                          We may change our fees at any time, or start charging fees for Services
                          that were previously free. When applicable, we may give you advance notice
                          of the fee changes. If you don’t agree with the fee changes, you must
                          cancel your Paid Service.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      INDEMNIFICATION
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          You agree to defend, indemnify and hold harmless Socion and its licensees
                          and licensors, employees, contractors, agents, officers and directors,
                          from and against any and all claims, damages, obligations, losses,
                          liabilities, costs or debt, and expenses (including but not limited to
                          attorney's fees), resulting from or arising out of
                          <br />
                          <br />
                          <View>
                            <p>
                              (f) &nbsp;your use and access of the Service, by you or any person
                              using your Account and password;
                            </p>
                            <p>(g) &nbsp;a breach of or non-compliance with these Terms, or</p>
                            <p>(h) &nbsp;any Content posted on the Service.</p>
                          </View>
                        </li>
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      LIMITATION OF LIABILITY
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          We will not be liable or responsible for:
                          <br />
                          <br />
                          <View>
                            <p>
                              (i) &nbsp;any failure to perform, or delay in performance of, any of
                              our obligations under these Terms of Use that is caused by any act or
                              event beyond our reasonable control, including Force Majeure Events;
                            </p>
                            <p>
                              (j) &nbsp;any failure to perform, or delay in performance of, any of
                              our obligations under these Terms of Use that is caused by an assigned
                              partner(s) or a third-party service;
                            </p>
                            <p>
                              (k) &nbsp;the Application not meeting your inViewidual requirements or
                              the Application containing defects or errors, as the Application has
                              not been developed specifically for you. It is your responsibility to
                              ensure that you use the Application if the facilities and functions of
                              the Application meet your requirements;
                            </p>
                            <p>
                              (l) &nbsp;any loss or damage caused by a distributed
                              denial-of-service, viruses attack, or other technologically harmful
                              material that may infect your device, data or other proprietary
                              material due to your use of the Application or the Services
                            </p>
                            <p>
                              (m) &nbsp;in aggregate, any amounts that exceed the fees paid by you
                              to Socion under the Agreement during the twelve (12) month period
                              prior to the cause of action.
                            </p>
                          </View>
                        </li>
                        <br />
                        <li>
                          Additionally, by using the Application or any Services, you acknowledge
                          and agree that internet transmissions are never completely private or
                          secure. You understand that any message or information you send using the
                          Application or any Services may be read or intercepted by others, even if
                          there is a special notice that a particular transmission is encrypted.
                        </li>
                        <br />
                        <li>
                          Nothing in these Terms of Use shall limit or exclude your liability for
                          death or personal injury resulting from your negligence, fraud or
                          fraudulent misrepresentation, and any other liability that cannot be
                          excluded or limited by law.
                        </li>
                        <br />
                        <li>
                          To the fullest extent permitted by law, in no event will Socion or its
                          affiliates be liable for any direct, indirect, special, incidental,
                          punitive, exemplary or consequential damages whether based on warranty,
                          contract, tort (including negligence) or any other legal theory, whether
                          or not Socion has been warned of the possibility of such damages. You
                          expressly understand that under no circumstances, including negligence,
                          shall make Socion be liable to you or any other person or entity for any
                          direct, indirect, incidental, special, remote or consequential damages,
                          including, but not limited to damages for loss of profits, goodwill, use,
                          data or other intangible losses, resulting from circumstances, including:
                          <br />
                          <br />
                          <ol type="a" id="list-alpha">
                            <li>the use or the inability to use the Service; or</li>
                            <br />
                            <li>
                              the cost of procurement of substitute services resulting from any
                              data, information or services purchased or obtained or messages
                              received through or from the Application and / or Service; or
                            </li>
                            <br />
                            <li>
                              unauthorized access to or alteration of your transmissions or data;
                            </li>
                            <br />
                            <li>any other matter relating to the Application and / or Service.</li>
                            <br />
                          </ol>
                          <br />
                        </li>
                      </ol>
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      COPYRIGHT AND TRADEMARKS
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          Copyright in works contained in the App, including but not limited to all
                          Content, features, functionality software, design, text, sound recordings
                          and images, are our exclusive property, or licensed by us, except as
                          otherwise expressly stated. You may access the Application only for your
                          use of the Services offered.
                        </li>
                        <br />
                        <li>
                          You must:
                          <br />
                          <br />
                          <ol type="a" id="list-alpha">
                            <li>
                              not copy, transmit, display, perform, distribute (for compensation or
                              otherwise), license, alter, store or otherwise use the Application or
                              any of its components.
                            </li>
                            <br />
                            <li>
                              not delete or modify any content of the Application and / or the
                              Services, including but not limited to, legal notices, disclaimers or
                              proprietary notices such as copyright or trademark symbols, logos,
                              that you do not own or have express permission to modify;
                            </li>
                            <br />
                            <li>
                              not make false or malicious statements against the Services or the
                              Application or us;
                            </li>
                            <br />
                            <li>
                              not use the Application or any Services in any unlawful manner, for
                              any unlawful purpose, or in any manner inconsistent with these Terms
                              of Use, or act fraudulently or maliciously, for example, by hacking
                              into or inserting malicious code, including viruses, or harmful data,
                              into the App, any Services or any operating system;
                            </li>
                            <br />
                            <li>
                              not engage in any form of antisocial, disrupting, or destructive acts,
                              including "flaming," "spamming," "flooding," "trolling," “phishing”
                              and "griefing" as those terms are commonly understood and used on the
                              Internet;
                            </li>
                            <br />
                            <li>
                              not use the Application or any Services in a way that could damage,
                              disable, overburden, impair or compromise the App, our systems or
                              security or interfere with other Users/Subscribers;
                            </li>
                            <br />
                            <li>
                              not carry out reverse engineering in respect of the Application or any
                              part thereof; and
                            </li>
                            <br />
                            <li>
                              comply with any applicable law and regulation relating to downloading,
                              using or otherwise exporting the technology used or supported by the
                              Application or any Services, which may include but is not limited to
                              not using the Application in a country where the use of the
                              Application or any Services is unlawful
                            </li>
                            <br />
                          </ol>
                        </li>
                        <br />
                      </ol>
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      THIRD PARTY LINKS
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          The Application may include links to third parties web sites. You
                          acknowledge that when you access a link that leaves our website or the
                          App, the site you will enter into is not controlled by us and that
                          different terms of use and privacy policies may apply. By accessing links
                          to other sites, you acknowledge that we are not responsible for those
                          sites. Any transaction you undertake on such third-party web sites, would
                          be solely at your own risk, and we shall not be liable for it any way. We
                          reserve the right to disable links from third-party sites to the App,
                          although we are under no obligation to do so. You acknowledge and agree
                          that Socion shall not be responsible or liable, directly or indirectly,
                          for any damage or loss caused or alleged to be caused by or in connection
                          with use of or reliance on any such content, goods or services available
                          on or through any such third-party web sites or services. We strongly
                          advise you to read the terms and conditions and privacy policies of any
                          third-party web sites or services that you visit.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      TERMINATION
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          We reserve the right, in the event you breach these Terms of Use or the
                          Privacy Policy to suspend or terminate your access to Application with or
                          without notice to you. Any suspected illegal, fraudulent or abusive
                          activity will also be grounds for terminating your access to the App. Upon
                          suspension or termination, your right to avail the Services shall
                          immediately cease and we reserve the right to remove or delete your
                          information that is available with us, including but not limited to login,
                          account information and information posted by you.
                        </li>
                        <br />
                        <li>
                          We reserve the right to (a) cease operating the Application or any of the
                          Services at any time without notice, and/or (b) to terminate these Terms
                          of Use.
                        </li>
                        <br />
                        <li>
                          If you wish to terminate your Account, you may simply discontinue using
                          the Service and logout from the App.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      GOVERNING LAW AND DISPUTE RESOLUTION
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          These Terms of Use shall be read and interpreted in accordance with the
                          laws of India.
                        </li>
                        <br />
                        <li>
                          Arbitration
                          <br />
                          <br />
                          <ol type="a" id="list-alpha">
                            <li>
                              Any dispute, claim or controversy arising out of or relating to these
                              Terms of Use or the breach, termination, enforcement, interpretation
                              or validity thereof, including the determination of the scope or
                              applicability of these Terms of Use to arbitrate, or to your use of
                              the Application or the Services or information to which it gives
                              access, shall be determined by arbitration in India, before a sole
                              arbitrator.
                            </li>
                            <br />
                            <li>
                              Arbitration shall be conducted in accordance with the Arbitration and
                              Conciliation Act, 1996.
                            </li>
                            <br />
                            <li>The seat of such arbitration shall be Bangalore.</li>
                            <br />
                            <li>
                              All proceedings of such arbitration, including, without limitation,
                              any awards, shall be in the English language. The award shall be final
                              and binding on the Parties.
                            </li>
                            <br />
                          </ol>
                        </li>
                        <br />
                      </ol>
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      SEVERABILITY
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          If any provision of these Terms of Use is held by a court of competent
                          jurisdiction or arbitral tribunal to be unenforceable under applicable
                          law, then such provision shall be excluded from these Terms of Use and the
                          remainder of these Terms of Use shall be interpreted as if such provision
                          were so excluded and shall be enforceable in accordance with its terms;
                          provided however that, in such event these Terms of Use shall be
                          interpreted so as to give effect, to the greatest extent consistent with
                          and permitted by applicable law, to the meaning and intention of the
                          excluded provision as determined by such court of competent jurisdiction
                          or arbitral tribunal.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      CONTACT INFORMATION
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          Should you have questions about this Terms of Use or Socion’s information
                          collection, use and disclosure practices, you may contact us at:
                          PDA-Contact@socion.io . We will use reasonable efforts to respond promptly
                          to requests, questions or concerns you may have regarding our use of
                          Personal Information about you. Except where required by law, the Company
                          cannot ensure a response to questions or comments regarding topics
                          unrelated to this policy or Company’s privacy practices
                          <br />
                          <br />
                          The name and contact details of the Grievance Officer are provided below:
                          <br />
                          <br />
                          <Text>
                            <b>Name: </b>Ramakrishna Kalluri
                          </Text>
                          <br />
                          <Text>
                            <b>Designation: </b>Partner
                          </Text>
                          <br />
                          <Text>
                            <b>Contact Number: </b>
                            <span>+91-9900221154</span>
                          </Text>
                          <br />
                          <Text>
                            <b>Email Id: </b>
                            <span>PDA-Grievances@socion.io</span>
                          </Text>
                        </li>
                        <br />
                      </ol>
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      WAIVER
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          No term of these Terms of Use shall be deemed waived and no breach
                          excused, unless such waiver or consent shall be in writing and signed by
                          Socion. Any consent by Socion to, or waiver of a breach by you, whether
                          expressed or implied, shall not constitute consent to, waiver of, or
                          excuse for any other different or subsequent breach.
                        </li>
                        <br />
                      </ol>
                      <br />
                    </li>
                    <li style={{ fontWeight: "bold", fontSize: 18, fontFamily: "times new roman" }}>
                      COMPLETE UNDERSTANDING
                      <br />
                      <br />
                      <ol
                        style={{
                          fontSize: 16,
                          fontWeight: "normal",
                          lineHeight: "1.5",
                          fontFamily: "times new roman"
                        }}
                      >
                        <li>
                          These Terms of Use contain the entire understanding of the Parties, and
                          there are no other written or oral understandings or promises between the
                          Parties with respect to the subject matter of these Terms of Use other
                          than those contained or referenced in these Terms of Use.
                        </li>
                        <br />
                      </ol>
                    </li>
                  </ol>
                  <Text
                    style={{
                      textAlign: "center",
                      textDecorationLine: "underline",
                      fontSize: "16px",
                      fontFamily: "times new roman",
                      fontWeight: "bold"
                    }}
                  >
                    YOU HAVE READ THESE TERMS OF USE AND AGREE TO ALL OF THE PROVISIONS CONTAINED
                    ABOVE
                  </Text>
                </View>
                <br />
                <br />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  profileCol: {
    marginTop: "20px",
    width: "84%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  textCenter: {
    textAlign: "center"
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: "35px"
  },
  subHeading: {
    fontSize: 16,
    fontWeight: "bold"
  },
  lineHeight: {
    lineHeight: 24
  },
  listHeading: {
    fontSize: 18,
    fontWeight: "bold"
  },
  listContent: {
    fontSize: 16,
    fontWeight: "normal"
  }
});
