// import { cleanEnv, str } from "envalid";
// import { config } from "dotenv";
// export default () => {
//   /***
//    * Load env into process.env
//    */
//   config();

//   /**
//    * Validate env variables are set
//    */
//   cleanEnv(process.env, {
//     NODE_ENV: str({ default: "development" }),
//     REACT_APP_SCHEME: str(),
//     REACT_APP_BASE_URL: str(),
//     REACT_APP_VERSION: str(),
//     REACT_APP_PATH: str()
//   });
// };

import Config from "react-native-config";
import PlatformUtils from "@socion-cordio/common/src/utils/platformUtils";

export enum AppModes {
  DEBUG = "DEBUG",
  RELEASE = "RELEASE"
}

// For WEB
const {
  NODE_ENV = "development",
  REACT_APP_SCHEME = "",
  REACT_APP_BASE_URL = "",
  REACT_APP_VERSION = "",
  REACT_APP_PATH = ""
} = process.env;

// For MOBILE
const {
  RN_NODE_ENV,
  REACT_NATIVE_SCHEME,
  REACT_NATIVE_BASE_URL,
  REACT_NATIVE_VERSION,
  REACT_NATIVE_PATH
} = Config;

class ConfigHelper {
  private readonly nodeEnv: string;
  private readonly baseUrl: string;
  private readonly scheme: string;
  private readonly version: string;
  private readonly path: string;

  constructor() {
    this.nodeEnv = NODE_ENV;
    this.baseUrl = REACT_APP_BASE_URL;
    this.scheme = REACT_APP_SCHEME;
    this.version = REACT_APP_VERSION;
    this.path = REACT_APP_PATH;

    if (PlatformUtils.isMobile()) {
      this.nodeEnv = RN_NODE_ENV;
      this.baseUrl = REACT_NATIVE_BASE_URL;
      this.scheme = REACT_NATIVE_SCHEME;
      this.version = REACT_NATIVE_VERSION;
      this.path = REACT_NATIVE_PATH;
    }
  }

  public getBaseUrl = (): string => this.baseUrl;

  public getNodeEnv = (): string => this.nodeEnv;

  public getScheme = (): string => this.scheme;

  public getVersion = (): string => this.version;

  public getPath = (): string => this.path;
}

const configHelper = new ConfigHelper();
export { configHelper as ConfigHelper };
