import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { useTranslation } from "react-i18next";
import { selectionsData } from "@socion-cordio/common//src/components/organisms/qpWebView/completion/constants";
import { dataHelper } from "@socion-cordio/common/src/utils/dataHelper";

interface Props {
  data?: any;
  showSelections?: any;
  setShowSelections?: Function;
}

export default function Selection(props: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { data, showSelections, setShowSelections } = props;

  const showHandleSelections = (index: number) => {
    let temp = [...showSelections];
    temp[index] = !temp[index];
    setShowSelections(temp);
  };

  return (
    <>
      <View style={styles.mt8}>
        {data?.length > 0 &&
          data?.map((i: any, key: number) => (
            <View>
              <View style={[styles.mt12, styles.flexContainer]}>
                <Text
                  testId="qualificationPack"
                  fontWeight={FontWeight.ExtraBold}
                  textSize={TextSize.Large}
                  style={[styles.cardHeader]}
                >
                  Selection {i.length == 1 ? i[0].selectionId : key + 1}
                </Text>
                <View style={[styles.alignLeftContainer, { marginRight: 12 }]}>
                  <TouchableOpacity onPress={() => showHandleSelections(key)}>
                    <Icon
                      name={IconNames.down}
                      size={10}
                      customStyle={showSelections[key] ? styles.cardIconInverted : styles.cardIcon}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              {showSelections[key] ? (
                <View style={{ marginTop: 14 }}>
                  <View
                    style={{
                      borderRadius: 10,
                      backgroundColor: colorPallete.white,
                      width: "100%",
                      paddingHorizontal: 14,
                      paddingBottom: 30
                    }}
                  >
                    {i?.map((subData: any, index: number) => (
                      <>
                        <View style={styles.pt14}>
                          <Text
                            testId="qualificationPack"
                            fontWeight={FontWeight.Light}
                            textSize={TextSize.Regular}
                            style={[styles.topicName]}
                          >
                            {subData.topicName}
                          </Text>
                        </View>
                        <View style={{ marginTop: 5 }}>
                          <Text
                            testId="qualificationPack"
                            fontWeight={FontWeight.Light}
                            textSize={TextSize.Regular}
                            style={[styles.topicCompletionDate]}
                          >
                            {dataHelper.formatDate(subData.topicCompletionDate)}
                          </Text>
                        </View>
                        {index + 1 !== i.length ? <View style={styles.divider}></View> : null}
                      </>
                    ))}
                  </View>
                </View>
              ) : null}
              {/* <View style={styles.divider}></View> */}
            </View>
          ))}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  alignLeftContainer: {
    marginLeft: "auto"
  },
  cardHeader: {
    fontFamily: FontFamily.Bold,
    fontSize: 16,
    lineHeight: 22,
    color: colorPallete.cordioTaupe
  },
  cardIcon: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6
  },
  cardIconInverted: {
    color: colorPallete.cordioRed,
    height: 12,
    width: 6,
    transform: [{ rotate: "180deg" }]
  },
  mt8: {
    marginTop: 8
  },
  mt12: {
    marginTop: 12
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row"
  },
  mr12: {
    marginRight: 12
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginTop: 14
  },
  topicName: {
    fontFamily: FontFamily.Medium,
    fontSize: 12,
    lineHeight: 16,
    color: colorPallete.textBlack
  },
  topicCompletionDate: {
    fontFamily: FontFamily.Regular,
    fontSize: 10,
    lineHeight: 14,
    color: colorPallete.textLight
  },
  mt14: {
    marginTop: 14
  },
  pt14: {
    paddingTop: 14
  }
});
