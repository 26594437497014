import { deserialize, serialize } from "@socion-cordio/common/src/mappers/apiResMapper";
// import { Topic } from "@socion-cordio/common/src/models/topic";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { programEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import { UserRolesProgram } from "@socion-cordio/common/src/models/userRolesProgram";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
class UserRolesProgramRepository {
  getUserRolesProgram = async (programId: number): Promise<UserRolesProgram> => {
    let isSuperAdmin = await LocalStorage.getStorage("isSuperAdmin");
    let data = await ApiClient.get(programEndPoints.getUserRolesProgram(), { programId });
    if (
      data.responseCode === 200 &&
      window.location.pathname !== `${routeNames.app}${routeNames.workspace}` &&
      window.location.pathname !==
        `${routeNames.app}${routeNames.PROFILE}${routeNames.PERSONALINFO}`
    ) {
      if (data.response.length === 0 && !isSuperAdmin) {
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/new/app/workspace`;
      }
    }
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };
}
const userRolesProgramRepository = new UserRolesProgramRepository();
export { userRolesProgramRepository as UserRolesProgramRepository };
