import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";
import { Profile } from "models/profile";
import profileWatcherSaga from "../saga/saga";

const prefix = "profile";
const PROFILE = {
  /**
   * Action Types returned
   * profile/GET_PROFILE_LOADING
   * profile/GET_PROFILE_SUCCESS
   * profile/GET_PROFILE_ERROR
   */
  GET: ActionHelper.actionTypesCreator(prefix, "GET_PROFILE"),
  GET_USER_PROFILE: ActionHelper.actionTypesCreator(prefix, "GET_USER_PROFILE"),
  UPDATE: ActionHelper.actionTypesCreator(prefix, "UPDATE_PROFILE"),
  FILE_UPLOAD: ActionHelper.actionTypesCreator(prefix, "FILE_UPLOAD"),
  GET_USER_ROLE: ActionHelper.actionTypesCreator(prefix, "GET_USER_ROLE"),
  GET_USER_QUALIFICATION: ActionHelper.actionTypesCreator(prefix, "GET_USER_QUALIFICATION"),
  GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE: ActionHelper.actionTypesCreator(
    prefix,
    "GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE"
  ),
  GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION: ActionHelper.actionTypesCreator(
    prefix,
    "GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION"
  ),
  GET_USER_ELIGIBLE_PROGRAMS_FOR_BADGES: ActionHelper.actionTypesCreator(
    prefix,
    "GET_USER_ELIGIBLE_PROGRAMS_FOR_BADGES"
  )
};

export { PROFILE };
