import React, { ReactElement } from "react";
import {
  TextStyle,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
  GestureResponderEvent,
  View
} from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { IconProps } from "react-native-vector-icons/Icon";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";

interface ICheckboxProps {
  selected: boolean;
  type: CheckboxType;
  textSize: TextSize;
  text: string;
  testId: string;
  iconSize?: number;
  iconSelectedColor?: string;
  iconUnselectedColor?: string;
  textFontWeight: FontWeight;
  onPress?: (event?: GestureResponderEvent) => void;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  noOfLines?: number;
}
export enum CheckboxType {
  Square = "Square"
}
interface IIconStyle {
  selected: Record<CheckboxType, IconProps>;
  unselected: Record<CheckboxType, IconProps>;
}
export interface ICheckboxStyle {
  container: ViewStyle;
  icon: IIconStyle;
  text: TextStyle;
}
export const Checkbox = (props: ICheckboxProps): ReactElement => {
  const {
    selected = false,
    containerStyle,
    type,
    testId,
    textSize,
    text,
    textStyle,
    textFontWeight,
    iconStyle,
    onPress,
    iconSize,
    iconSelectedColor,
    iconUnselectedColor,
    noOfLines
  } = props;
  const styles = getStyles();
  const { icon } = styles;
  const { name } = selected ? icon.selected[type] : icon.unselected[type];
  const color = selected
    ? iconSelectedColor ?? icon.selected[type].color
    : iconUnselectedColor ?? icon.unselected[type].color;
  return (
    <View style={[styles.container, containerStyle]} testID={`chk${testId}`}>
      <TouchableOpacity onPress={onPress} testID={`to${testId}`}>
        <Icon
          name={name as IconNames}
          size={iconSize ?? 24}
          color={color}
          customStyle={iconStyle}
          testID={`ico${testId}`}
        />
      </TouchableOpacity>
      <Text
        testId={`txt${testId}`}
        textSize={textSize}
        fontWeight={textFontWeight}
        textStyle={[styles.text, textStyle]}
        numberOfLines={noOfLines ?? null}
      >
        {text}
      </Text>
      {/* <Button
        title="Outline button"
        type="outline"
      /> */}
    </View>
  );
};
Checkbox.defaultProps = {
  containerStyle: {},
  textStyle: {},
  iconStyle: {},
  iconSize: undefined
};
const getStyles = (): ICheckboxStyle => {
  return {
    icon: {
      selected: {
        [CheckboxType.Square]: {
          color: colorPallete.cordioRed,
          name: IconNames.checkBoxTicked
        }
      },
      unselected: {
        [CheckboxType.Square]: {
          color: colorPallete.cordioTaupeLight3,
          name: IconNames.checkBoxUnTicked
        }
      }
    },
    container: {
      flexDirection: "row",
      alignItems: "center"
    },
    text: {
      marginStart: 4,
      fontSize: 12,
      fontFamily: FontFamily.Medium
    }
  };
};
