import React, { ReactElement, useState, useEffect } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Otp } from "@socion-cordio/common/src/components/atoms/otp";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { SignupRepository } from "@socion-cordio/common/src/repositories/signup/signup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Moment from "moment";
import {
  setNewBearerToken,
  setNewUser,
  setSuperAdmin
} from "@socion-cordio/common/src/network/authHelper";
import { LoginActions } from "@socion-cordio/common/src/modules/login/actions/actions";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";

interface Props {
  fetchSignupOtp?: Function;
  history?: any;
}

export default function SignupFormNewOtp(props: Props): ReactElement {
  const history: any = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const saveSignupOtp = async (values: { otp: number }) => {
    setLoading(true);
    const aesUtil: AesUtil = new AesUtil();
    let payload = {
      userName: location?.state?.userName,
      otp: aesUtil.encrypt(values.otp.toString()),
      typeOfOtp: "Registration-OTP",
      countryCode: location?.state?.countryCode,
      verificationType: location?.state?.verificationType
    };

    try {
      let res = await SignupRepository.validateNewUserOtp(payload);
      if (res.responseCode === HTTP_STATUS_CODES.ok) {
        const payload = {
          userName: location?.state?.userName,
          password: aesUtil.encrypt(location?.state?.password),
          countryCode: location?.state?.countryCode,
          verificationType: location?.state?.verificationType
        };
        let response = await SignupRepository.submitSignUpDetails(payload);
        if (response?.responseCode === HTTP_STATUS_CODES.ok) {
          loginHandler(response?.response);
        } else {
          toast.error("Something went wrong!");
          setLoading(false);
        }
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const loginHandler = (loginData: any) => {
    dispatch(LoginActions.postCredentials(loginData?.accessTokenResponseDTO?.accessToken));
    setNewBearerToken(loginData?.accessTokenResponseDTO?.accessToken);
    setNewUser(loginData?.userDetails);
    getPayload(loginData?.accessTokenResponseDTO?.accessToken);
    LocalStorage.setStorage("countryCodeObj", location?.state?.selectedCountryObject);
    LocalStorage.setStorage(
      "countryCode",
      +location?.state?.selectedCountryObject.code.replace("+", "")
    );
    LocalStorage.setStorage("IsEmailVerification", location?.state?.isEmailVerification);
    updateTelemetry(loginData?.userDetails, "Login");
    updateTelemetry(loginData?.userDetails, "Sign-up");
    setLoading(false);
    history.push(`${routeNames.app}${routeNames.workspace}`);
  };

  const updateTelemetry = async (userDetails: any, eventType: string) => {
    const timestamp = Moment().toLocaleString();
    const body = {
      timestamp: timestamp,
      createdAt: timestamp,
      deleted: "false",
      updatedAt: timestamp,
      eventType: eventType,
      id: userDetails?.userId
    };
    AddTelemetryService(body, undefined, userDetails);
  };

  function getPayload(token: string) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let payload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    if (payload?.realm_access) {
      const { roles } = payload.realm_access;
      const isAdmin = roles.includes("admin");
      setSuperAdmin(isAdmin);
    }
  }

  const resendOtpHandler = () => {
    // let response = SignupRepository.registerNewUser(location?.state?.signupPayload);
    setSeconds(45);
    let payload = {
      userName: location?.state?.userName,
      countryCode: location?.state?.countryCode,
      verificationType: location?.state?.verificationType,
      registrationKey: location?.state?.registrationKey
    };
    let params = {
      userName: location?.state?.userName,
      registerKey: location?.state?.registrationKey
    };
    const response = SignupRepository.resendNewUserOtp(payload, params);

    response
      .then((res) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          toast.success("OTP sent successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!values.otp || values?.otp?.length !== 6) errors.otp = "Please enter Verification code";
    if (values.otp && !regex.test(values?.otp)) {
      errors.otp = "Invalid characters";
    }
    return errors;
  };

  const encodeEmailHandler = (email: string) => {
    let newEmail = email.split("@");
    let str = newEmail[0].substr(0, newEmail[0].length - 1);
    let updateEmail = str.replace(/./g, "*") + newEmail[0].slice(-1) + "@" + newEmail[1];
    return updateEmail;
  };

  return (
    <View>
      <WrapperForm
        footerText={t("common:alreadyHaveAnAccount")}
        footerTextType={t("common:loginText")}
        onPressFooterTextType={() => history.push("/new/iam/login")}
        component={
          <View style={styles.container}>
            <View style={styles.subContainer}>
              <View style={styles.mainHeading}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="program"
                  textSize={TextSize.Regular}
                  textStyle={styles.subHeading}
                >
                  {t("common:pdaProgram")}
                </Text>
                <View style={{ marginTop: 10 }}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="login"
                    textSize={TextSize.Large}
                    textStyle={{ fontSize: 26 }}
                  >
                    {t("common:signUpSecondary")}
                  </Text>
                </View>
              </View>

              <View style={{ alignItems: "center", marginTop: 100 }}>
                <View style={{ marginBottom: 5 }}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Large}
                    textStyle={styles.subHeading}
                  >
                    {t("common:enterOtp")}
                  </Text>
                </View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="forgot"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  {location?.state?.verificationType === "EMAIL_ID"
                    ? ` Please enter the code that was sent to email \n ${encodeEmailHandler(
                        location?.state?.userName
                      )}`
                    : `Please enter the code that was sent to \n ${location?.state?.userName?.replace(
                        /\d(?=\d{4})/g,
                        "*"
                      )}`}
                </Text>
                <Formik
                  initialValues={{ otp: null }}
                  onSubmit={(values, { setSubmitting }) => {
                    saveSignupOtp(values);
                    setSubmitting(false);
                  }}
                  validate={handleValidate}
                  enableReinitialize={true}
                >
                  {(
                    formikProps: FormikProps<{
                      otp: number;
                    }>
                  ) => (
                    <Form>
                      <View style={styles.otpContainer}>
                        <Otp
                          id="otp"
                          name="otp"
                          handleTextChange={formikProps.handleChange("otp")}
                          handleBlur={() => formikProps.setFieldTouched}
                          formikPropsTouched={
                            formikProps.touched.otp === undefined ? false : formikProps.touched.otp
                          }
                          customChange={() => formikProps.setFieldTouched("otp", false)}
                        />
                      </View>

                      <View style={styles.footerContainer}>
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.button}
                          title="Verify and proceed"
                          onPress={() => {
                            formikProps.handleSubmit();
                          }}
                          disabled={loading}
                        />
                        <View style={styles.resendOtpContainer}>
                          <View style={styles.resendTextAlign}>
                            {seconds === 0 ? (
                              <TouchableOpacity>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="resendOtp"
                                  textSize={TextSize.Small}
                                  textStyle={styles.resendOtpText}
                                  onPress={() => resendOtpHandler()}
                                >
                                  {t("common:resendOtp")}
                                </Text>
                              </TouchableOpacity>
                            ) : (
                              <View style={{ flexDirection: "row" }}>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="resendOtp"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.resendOtpTextDisable, { marginRight: 10 }]}
                                >
                                  {t("common:resendOtp")}
                                </Text>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="resendOtp"
                                  textSize={TextSize.Small}
                                  textStyle={styles.counterText}
                                >
                                  {seconds}
                                </Text>
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    </Form>
                  )}
                </Formik>
              </View>
            </View>
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colorPallete.white,
    width: "100%",
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  mainHeading: {
    alignItems: "center"
    // marginTop: 50,
    // marginLeft: 80
  },
  subContainer: {
    // marginTop: 20,
    alignItems: "center",
    marginVertical: 20
  },
  heading: {
    fontSize: 26,
    marginBottom: 120
  },
  subHeading: {
    fontSize: 18
  },
  headerText: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center"
  },
  otpContainer: {
    marginTop: 60,
    marginBottom: 80
  },
  resendText: {
    fontSize: 14,
    fontWeight: "500"
  },
  resendOtpText: {
    fontSize: 14,
    fontWeight: "500",
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  footerContainer: {
    alignItems: "center"
  },
  button: {
    height: 50,
    width: 304
  },
  resendOtpContainer: {
    flexDirection: "row",
    marginTop: 10
  },
  resendTextAlign: {
    marginLeft: 5
  },
  resendOtpTextDisable: {
    fontSize: 14,
    fontWeight: "500",
    color: colorPallete.lightGreyTwo
  },
  counterText: {
    fontSize: 14,
    fontWeight: "500",
    color: colorPallete.cordioRed
  }
});
