import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import _ from "lodash";

// const profile = require("@socion-cordio/common/src/assets/images/profile.png");

const styles = StyleSheet.create({
  message: {
    fontSize: 12,
    fontFamily: "Montserrat-Medium",
    color: colorPallete.textBlack
  },
  container: {
    overflow: "hidden",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 14,
    paddingBottom: 12,
    paddingTop: 12,
    paddingRight: 14
  },
  mainContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10,
    marginBottom: 20
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginRight: 14,
    marginLeft: 14
  }
});

interface IProps {
  contents?: any;
  name?: String;
  role?: String;
  email?: String;
  location?: String;
  attestationData?: any;
}

export default function MemberList(props: IProps): ReactElement {
  const { attestationData } = props;

  const formatRoles = (data: any) => {
    let bool: boolean = false;
    let formatString = "";
    let otherText = "";
    Object.fromEntries(
      Object.entries(data).filter(([key, value]) => {
        if (key === "trainer" && value === true) {
          formatString += "Trainer ";
        }
        if (key === "admin" && value === true) {
          formatString += "Admin ";
        }
        if (key === "other" && value === true) {
          bool = true;
        }
        if (bool === true) {
          if (key === "otherRoleNames" && value !== null) {
            otherText += `${value} `;
          }
        }
      })
    );
    let newString = formatString.split(" ").join(", ").replace(/,\s*$/, "");
    let finalString = newString !== "" ? `${newString},${otherText}` : otherText;
    return otherText == "" ? newString : finalString;
  };

  const renderContentList = (item: any, index: any, array: any) => {
    return (
      <View>
        <View style={styles.container}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ marginTop: 5, marginRight: 10 }}>
              <Image
                source={
                  item?.photo || require("@socion-cordio/common/src/assets/images/user_circle.svg")
                }
                testId={"profile-image"}
                imageStyle={{
                  width: item?.photo ? 36 : 45,
                  height: item?.photo ? 36 : 45,
                  borderRadius: 25
                }}
              />
            </View>
            <View style={{ width: 200 }}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={[styles.message]}
              >
                {item.name}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={[
                  styles.message,
                  { marginBottom: 5, color: colorPallete.cordioTaupeLight2 }
                ]}
              >
                {formatRoles(item?.roles)}
              </Text>
            </View>
          </View>
        </View>
        {index === array.length - 1 ? null : <View style={styles.divider} />}
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      {attestationData?.members?.length === 0 ? (
        <Text
          fontWeight={FontWeight.Regular}
          testId="forgot"
          textSize={TextSize.Small}
          textStyle={[styles.message, { marginBottom: 5 }]}
        >
          No content
        </Text>
      ) : (
        attestationData?.members?.map((item: any, index: any, array: any) => {
          return renderContentList(item, index, array);
        })
      )}
    </View>
  );
}
