import { BadgeTypes } from "@socion-cordio/common/src/models/badgeType";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { badgesEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class BadgesRepository {
  getBadgesTypes = async (programId: any): Promise<BadgeTypes> => {
    let data = await ApiClient.get(badgesEndPoints.getAllBadgeTypes(programId));
    return data;
  };

  getBadges = async (badgeClassTypeId: any): Promise<BadgeTypes> => {
    let data = await ApiClient.get(badgesEndPoints.getBadges(badgeClassTypeId));
    return data;
  };

  addBadge = async (body: Object): Promise<BadgeTypes> => {
    let data = await ApiClient.post(badgesEndPoints.addBadgeToType(), body);
    return data;
  };

  addBadgeType = async (body: Object): Promise<BadgeTypes> => {
    let data = await ApiClient.post(badgesEndPoints.addBadgeType(), body);
    return data;
  };

  updateBadgeType = async (body: Object, badgeTypeId: number): Promise<BadgeTypes> => {
    let data = await ApiClient.put(badgesEndPoints.updateBadgeType(badgeTypeId), body);
    return data;
  };

  publishBadgeType = async (badgeTypeId: number): Promise<BadgeTypes> => {
    let data = await ApiClient.put(badgesEndPoints.publishBadgeType(badgeTypeId), {});
    return data;
  };

  deactivateBadgeType = async (badgeTypeId: number): Promise<BadgeTypes> => {
    let data = await ApiClient.put(badgesEndPoints.deactivateBadgeType(badgeTypeId), {});
    return data;
  };

  activateBadgeType = async (badgeTypeId: number): Promise<BadgeTypes> => {
    let data = await ApiClient.put(badgesEndPoints.activateBadgeType(badgeTypeId), {});
    return data;
  };

  deleteBadge = async (badgeTypeId: number): Promise<any> => {
    let data = await ApiClient.delete(badgesEndPoints.deleteSelectedBadge(badgeTypeId), {});
    return data;
  };
}
const badgesRepository = new BadgesRepository();
export { badgesRepository as BadgesRepository };
