import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useParams } from "react-router-dom";
import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { qualificationPackEndPoints } from "@socion-cordio/common/src/repositories/endPoints";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import UserCardData from "@socion-cordio/common/src/components/organisms/qpWebView/completion/userCard";
import QPDetails from "@socion-cordio/common/src/components/organisms/qpWebView/completion/qpDetails";
import Selection from "@socion-cordio/common/src/components/organisms/qpWebView/completion/selection";
import { toast } from "react-toastify";

export default function QPCompletion(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [qpData, setQpData] = useState(null);
  const [selecetionQpData, setSelecetionQpData] = useState(null);
  const [showSelections, setShowSelections] = useState([]);
  const { t } = useTranslation();
  const { bundleId, userId } = useParams();

  useEffect(() => {
    getQpQrDetails();
  }, []);

  const getQpQrDetails = async () => {
    try {
      setIsLoading(true);
      let response = await ApiClient.get(
        qualificationPackEndPoints.getQpUserDetails(bundleId, userId)
      );
      if (response?.responseCode === HTTP_STATUS_CODES.ok) {
        setIsLoading(false);
        setQpData(response?.response);
        let idArray = response?.response?.userBundleDetails[0]?.qpSelectionDetails.map((i: any) => {
          return i.selectionId;
        });
        idArray = [...new Set(idArray)];
        let result: any = [];
        idArray.map((i: number) => {
          let temp = response?.response?.userBundleDetails[0]?.qpSelectionDetails?.filter(
            (k: any) => k.selectionId == i && k.topicCompletionDate !== null
          );
          if (temp.length > 0) {
            result.push(temp);
          }
        });
        response.response.formattedBundleMapping = result;
        setSelecetionQpData(response?.response?.formattedBundleMapping);
        if (response?.response?.formattedBundleMapping?.length > 0) {
          setShowSelections(Array(response?.response?.formattedBundleMapping?.length).fill(true));
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <View>
      <View style={styles.headerConatiner}>
        <View style={styles.headingContainer}>
          <View style={styles.flexConatiner}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="details"
              textSize={TextSize.Small}
              style={styles.headingStyles}
            >
              Qualification Pack Completion
            </Text>
          </View>
          <UserCardData data={qpData?.userDetails} />
        </View>
      </View>
      <View style={styles.bodyStyles}>
        <QPDetails
          data={qpData?.bundleDetails}
          programDetails={qpData?.programDetails}
          qpData={qpData?.userBundleDetails[0]}
        />
        <Selection
          data={selecetionQpData}
          showSelections={showSelections}
          setShowSelections={setShowSelections}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headerConatiner: {
    backgroundColor: "#B7504A",
    height: 146,
    paddingRight: 16
  },
  headingContainer: {
    position: "absolute",
    top: 16,
    width: "100%",
    paddingHorizontal: 16
  },
  headingStyles: {
    color: colorPallete.white,
    fontSize: 18,
    fontFamily: FontFamily.Bold
  },
  bodyStyles: {
    backgroundColor: "#EEE5DA",
    zIndex: -1,
    paddingLeft: 16,
    paddingRight: 16,
    // height: "100vh",
    paddingTop: 80,
    paddingBottom: 40
  },
  flexConatiner: {
    display: "flex",
    flexDirection: "row"
  },
  closeIcon: {
    color: colorPallete.white,
    fontSize: 18
  },
  mr22: {
    marginRight: 22
  }
});
