import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
interface Props {
  data?: any;
}

export default function SelectionDetails(props: Props): ReactElement {
  const { data } = props;
  return (
    <View>
      {data?.map((i: any, key: number) => (
        <>
          <View style={styles.mb9}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="details"
              textSize={TextSize.Small}
              style={styles.selectionText}
            >
              Selection {i.length == 1 ? i[0].selectionId : key + 1}
            </Text>
          </View>
          <View style={styles.card}>
            {i?.length > 1 ? (
              i?.map((j: any) => <View style={styles.selectionData}>{j?.topicName}</View>)
            ) : (
              <View style={styles.selectionData}>{i[0].topicName}</View>
            )}
          </View>
        </>
        // ))
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    paddingHorizontal: 14,
    paddingTop: 20,
    backgroundColor: colorPallete.white,
    width: "100%",
    borderRadius: 10,
    marginBottom: 20,
    paddingBottom: 13
  },
  selectionData: {
    backgroundColor: colorPallete.cordioBeige,
    borderRadius: 40,
    paddingVertical: 5,
    paddingLeft: 13,
    width: "100%",
    marginBottom: 7
  },
  selectionText: {
    fontSize: 16,
    fontFamily: FontFamily.Bold,
    color: colorPallete.cordioTaupe
  },
  mb9: {
    marginBottom: 9
  },
  mt140: {
    marginTop: 140
  }
});
