import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ConfigHelper } from "@socion-cordio/common/src/utils/loadEnv.util";
import { IApiClient } from "@socion-cordio/common/src/network/interfaces";
import { v4 as uuidv4 } from "uuid";
import {
  bearerTokenReqInterceptor,
  refreshTokenInterceptor
} from "@socion-cordio/common/src/network/interceptors";
import { errorHandler, successHandler } from "@socion-cordio/common/src/network/responseHandlers";
import PlatformUtils from "@socion-cordio/common/src/utils/platformUtils";

const getAxiosInstance = (baseUrl: string, client: IApiClient): AxiosInstance => {
  let axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 1200000,
    headers: {
      "Trace-Id": uuidv4(),
      "Content-Type": "application/json",
      offset: -330
    }
  });
  // Add Request Interceptor to add Authorization header
  axiosInstance = bearerTokenReqInterceptor(axiosInstance);
  // Add Response Interceptor for Authorization token expiry
  // axiosInstance = refreshTokenInterceptor(axiosInstance, baseURL);
  return axiosInstance;
};

class ApiClient implements IApiClient {
  private client: AxiosInstance;
  constructor(baseUrl: string) {
    this.client = getAxiosInstance(baseUrl, this);
  }
  private async request(config: AxiosRequestConfig) {
    try {
      // telemetry function here.
      // every api call here.

      // config check put or post call here.
      // and from here call telemetry
      // console.log("CONFIGG:::",config);

      let response = await this.client.request(config); //main api call.
      let handledResponse = await successHandler(response);
      // console.log("APICLIENT::::",response);
      // console.log("HANDLE RESPONSE::::",handledResponse); Promise.resolve
      return Promise.resolve(handledResponse);
    } catch (error) {
      let handledError = await errorHandler(error);
      return Promise.reject(handledError);
    }
  }
  public async get(url: string, params?: Object) {
    return this.request({ method: "GET", url, params });
  }
  public async put(url: string, data: Object, params?: Object) {
    return this.request({ method: "PUT", url, data, params });
  }
  public async post(url: string, data: Object, params?: Object) {
    return this.request({ method: "POST", url, data, params });
  }
  public async delete(url: string, data?: Object, params?: Object) {
    return this.request({ method: "DELETE", url, data, params });
  }
  public async patch(url: string, data: Object, params?: Object) {
    return this.request({ method: "PATCH", url, data, params });
  }
}

// const baseURL = ConfigHelper.getNodeEnv() === "development" ? "/api/v1/" : `${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PATH}/${process.env.REACT_APP_VERSION}`;
// const baseURL = `${config.REACT_APP_SCHEME}://${config.REACT_APP_BASE_URL}/${config.REACT_APP_PATH}/${config.REACT_APP_VERSION}`;
// const baseURL = `${config.REACT_APP_SCHEME}://${config.REACT_APP_BASE_URL}`;
const baseURL = process.env.REACT_APP_API_BASE_URL;
const apiClient = new ApiClient(baseURL);
export { apiClient as ApiClient };
