export const colorPallete = {
  cordioRed: '#B7504A',
  cordioRedLight1: '#C5736E',
  cordioRedLight2: '#E2B9B7',
  cordioRedLight3: '#F1DCDB',
  cordioRedLight4: '#F8EEED',
  cordioRedDark1: '#6E302C',
  cordioRedDark2: '#25100F',
  cordioRedDark3: "#CC0B00",
  cordioRedDark4: '#F05B66',
  cordioRedDark5: '#DB4437',
  cordioBeige: '#EEE5DA',
  cordioBeigeLight1: '#F1EAE1',
  cordioBeigeLight2: '#F8F5F0',
  cordioBeigeLight3: '#FCFAF8',
  cordioBeigeLight4: '#FDFCFB',
  cordioBeigeDark1: '#8F8983',
  cordioBeigeDark2: '#302E2C',
  cordioTaupe: '#675850',
  cordioTaupeLight1: '#857973',
  cordioTaupeLight2: '#A49B96',
  cordioTaupeLight3: '#C2BCB9',
  cordioTaupeLight4: '#E1DEDC',
  cordioTaupeDark1: '#3E3530',
  cordioTaupeDark2: '#292320',
  cordioTaupeDark3: '#575757',
  cordioBlue1: '#215AD6',
  cordioBlue2: '#225AD6',
  textBlack: '#231F20',
  textLight: '#7B7979',
  white: '#ffffff',
  black: '#000000',
  cordioGreen: "#3FA202",
  cordioOrange: "#E87D44",
  lightGreen: '#3FA202',
  lightGreen2: '#E5FFE5',
  lightGrey: '#F8F8F8',
  lightGrey2: '#eceaea',
  gray: '#979797',
  disabledGrey: '#5c5b5b',
  lightGreyTwo: '#B1B1B1',
  lightGreyThree: 'rgba(103,88,80,0.1)',
  cordiolightGreyFour: "rgba(255, 255, 255, 0.15)",
  cordioLightGreyFive: '#C4C4C4',
  cordioLightGreySix: '#E2E2E2',
  darkGreyOne: '#a29f9f99',
  yellowOne: '#FDE509',
  cordioGray: "#646363",
  cordioBlue3: "#457DE8"
};