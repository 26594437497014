import { ApiClient } from "@socion-cordio/common/src/network/apiClient";
import { telemetryUploadEndPoints } from "@socion-cordio/common/src/repositories/endPoints";

class TelementryRepository {
  updateTelemetry = async (body: Object): Promise<any> => {
    let data = await ApiClient.post(telemetryUploadEndPoints.telemetryWrapper(), body);
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };

  updateBadgesTelemetry = async (body: Object): Promise<any> => {
    let data = await ApiClient.post(telemetryUploadEndPoints.telemetryBadgeUpload(), body);
    // const topicsData: Topic = deserialize(Topic, data);
    return data;
  };
}
const telemetryRepository = new TelementryRepository();
export { telemetryRepository as TelementryRepository };
