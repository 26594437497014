import { BADGES } from "@socion-cordio/common/src/modules/badges/actions/actionTypes";
import { BadgeTypes } from "@socion-cordio/common/src/models/badgeType";
import { IFluxStandardAction } from "@socion-cordio/common/src/store/interfaces";

const getBadgeTypes = (data: { programId: number }): IFluxStandardAction<{ programId: number }> => {
  return {
    type: BADGES.GET_BADGES_TYPES.LOADING,
    payload: data
  };
};

const getBadgeTypesSuccess = (data: BadgeTypes): IFluxStandardAction<BadgeTypes> => {
  data.response.forEach((topic: any, index: number) => {
    topic.serialNo = `${index + 1}.`;
  });
  return {
    type: BADGES.GET_BADGES_TYPES.SUCCESS,
    payload: data
  };
};

const getBadgeTypesError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: BADGES.GET_BADGES_TYPES.ERROR,
    payload: error
  };
};

export const BadgesActions = {
  getBadgeTypes,
  getBadgeTypesSuccess,
  getBadgeTypesError
};
