import React, { ReactElement, useState, useEffect } from "react";
import { Form, Formik, FormikProps } from "formik";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { Otp } from "@socion-cordio/common/src/components/atoms/otp";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { useTranslation } from "react-i18next";
import { LoginRepository } from "@socion-cordio/common/src/repositories/login/login";
import { useDispatch } from "react-redux";
import { LoginActions } from "@socion-cordio/common/src/modules/login/actions/actions";
import {
  setNewBearerToken,
  setNewUser,
  setSuperAdmin
} from "@socion-cordio/common/src/network/authHelper";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";

interface Props {}

export default function LoginOtpForm(props: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history: any = useHistory();
  const location: any = useLocation();

  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const saveSignupOtp = async (values: { otp: number }) => {
    setLoading(true);
    const aesUtil: AesUtil = new AesUtil();
    let payload = {
      userName: location?.state?.userName,
      otp: aesUtil.encrypt(values.otp.toString()),
      typeOfOtp: "LOGIN-OTP",
      countryCode: location?.state?.countryCode,
      verificationType: location?.state?.verificationType
    };
    let message = location?.state?.IsEmailVerification ? "email" : "phone number";
    let response = LoginRepository.validateLoginUserOtp(payload);
    dispatch(LoginActions.postCredentialsSucess(null));
    response
      .then((res: any) => {
        if (res.responseCode === HTTP_STATUS_CODES.ok) {
          dispatch(
            LoginActions.postCredentials(res?.response?.accessTokenResponseDTO?.accessToken)
          );
          setNewBearerToken(res?.response?.accessTokenResponseDTO?.accessToken);
          setNewUser(res?.response?.userDetails);
          getPayload(res?.response?.accessTokenResponseDTO?.accessToken);
          LocalStorage.setStorage("countryCodeObj", location?.state?.selectedCountryCodeData);
          LocalStorage.setStorage(
            "countryCode",
            +location?.state?.selectedCountryCodeData.code.replace("+", "")
          );
          LocalStorage.setStorage("IsEmailVerification", location?.state?.IsEmailVerification);
          history.push(`${routeNames.app}${routeNames.workspace}`);
          LocalStorage.setStorage("isSidePanelCollapsed", false);
          updateTelemetry();
        } else {
          if (
            res?.responseCode === HTTP_STATUS_CODES.notFound ||
            res?.responseCode === HTTP_STATUS_CODES.badRequest
            // ||
            // res?.responseCode === HTTP_STATUS_CODES.unauthorized
          ) {
            // toast.error(
            //   `This is not a registered ${message}. You should sign up first and then login to the system`
            // );
            toast.error(res.message);
            setLoading(false);
          } else {
            toast.error(res?.message);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong.");
        setLoading(false);
      });
  };

  const updateTelemetry = async () => {
    const user: any = await LocalStorage.getStorage("user");
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: "Login Via OTP",
      id: user?.userId
    };
    AddTelemetryService(body, undefined, user);
  };

  function getPayload(token: string) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let payload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    if (payload?.realm_access) {
      const { roles } = payload.realm_access;
      const isAdmin = roles.includes("admin");
      setSuperAdmin(isAdmin);
    }
  }

  const resendOtpHandler = () => {
    setSeconds(45);
    let payload = {
      userName: location?.state.userName,
      countryCode: location?.state.countryCode,
      verificationType: location?.state?.IsEmailVerification ? "EMAIL_ID" : "PHONE_NUMBER"
    };
    let response = LoginRepository.loginUser(payload);
    response.then((res) => {
      if (res.responseCode === HTTP_STATUS_CODES.ok) {
        toast.success("OTP sent successfully");
      } else {
        toast.error(res.messageDetails);
      }
    });
  };

  const handleValidate = (values: any) => {
    let errors: any = {};
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
    if (!values.otp || values?.otp?.length !== 6) errors.otp = "Please enter Verification code";
    if (values.otp && !regex.test(values?.otp)) {
      errors.otp = "Invalid characters";
    }
    return errors;
  };

  const encodeEmailHandler = (email: string) => {
    let newEmail = email.split("@");
    let str = newEmail[0].substr(0, newEmail[0].length - 1);
    let updateEmail = str.replace(/./g, "*") + newEmail[0].slice(-1) + "@" + newEmail[1];
    return updateEmail;
  };

  return (
    <View>
      <WrapperForm
        footerText={t("common:dontHaveAnAccount")}
        footerTextType={t("common:signUpText")}
        onPressFooterTextType={() => history.push("/new/iam/signup")}
        component={
          <View style={styles.container}>
            <View style={styles.subContainer}>
              <View style={styles.mainHeading}>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="program"
                  textSize={TextSize.Regular}
                  textStyle={styles.subHeading}
                >
                  {t("common:pdaProgram")}
                </Text>
                <View style={{ marginTop: 10 }}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="login"
                    textSize={TextSize.Large}
                    textStyle={{ fontSize: 26 }}
                  >
                    {t("common:loginText")}
                  </Text>
                </View>
              </View>

              <View style={{ alignItems: "center" }}>
                <View style={{ marginBottom: 5 }}>
                  <Text
                    fontWeight={FontWeight.Bold}
                    testId="signup"
                    textSize={TextSize.Large}
                    textStyle={styles.subHeading}
                  >
                    {t("common:enterOtp")}
                  </Text>
                </View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="forgot"
                  textSize={TextSize.Small}
                  textStyle={styles.headerText}
                >
                  {location?.state?.verificationType === "EMAIL_ID"
                    ? ` Please enter the code that was sent to email \n ${encodeEmailHandler(
                        location?.state?.userName
                      )}`
                    : `Please enter the code that was sent to \n ${location?.state?.userName?.replace(
                        /\d(?=\d{4})/g,
                        "*"
                      )}`}
                </Text>
                <Formik
                  initialValues={{ otp: null }}
                  onSubmit={(values, { setSubmitting }) => {
                    saveSignupOtp(values);
                    setSubmitting(false);
                  }}
                  validate={handleValidate}
                  enableReinitialize={true}
                >
                  {(
                    formikProps: FormikProps<{
                      otp: number;
                    }>
                  ) => (
                    <Form>
                      <View style={styles.otpContainer}>
                        <Otp
                          id="otp"
                          name="otp"
                          handleTextChange={formikProps.handleChange("otp")}
                          handleBlur={() => formikProps.setFieldTouched}
                          formikPropsTouched={
                            formikProps.touched.otp === undefined ? false : formikProps.touched.otp
                          }
                          customChange={() => formikProps.setFieldTouched("otp", false)}
                        />
                      </View>

                      <View style={styles.footerContainer}>
                        <Button
                          type={ButtonType.Primary}
                          buttonStyles={styles.button}
                          title="Verify and Login"
                          onPress={() => {
                            formikProps.handleSubmit();
                          }}
                          disabled={loading}
                        />
                        <View style={styles.resendOtpContainer}>
                          <View style={styles.resendTextAlign}>
                            {seconds === 0 && (
                              <TouchableOpacity>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="resendOtp"
                                  textSize={TextSize.Small}
                                  textStyle={styles.resendOtpText}
                                  onPress={() => resendOtpHandler()}
                                >
                                  {t("common:resendOtp")}
                                </Text>
                              </TouchableOpacity>
                            )}
                            {seconds > 0 && (
                              <View style={{ flexDirection: "row" }}>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="resendOtp"
                                  textSize={TextSize.Small}
                                  textStyle={[styles.resendOtpTextDisable, { marginRight: 10 }]}
                                >
                                  {t("common:resendOtp")}
                                </Text>
                                <Text
                                  fontWeight={FontWeight.Regular}
                                  testId="resendOtp"
                                  textSize={TextSize.Small}
                                  textStyle={styles.counterText}
                                >
                                  {seconds}
                                </Text>
                              </View>
                            )}
                          </View>
                        </View>
                      </View>
                    </Form>
                  )}
                </Formik>
              </View>
            </View>
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: colorPallete.white,
    width: "100%",
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  subContainer: {
    alignItems: "center",
    marginVertical: 20
  },
  mainHeading: {
    alignItems: "center",
    marginBottom: 85
  },
  heading: {
    fontSize: 26,
    marginBottom: 120
  },
  subHeading: {
    fontSize: 18
  },
  headerText: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "center"
  },
  otpContainer: {
    marginTop: 60,
    marginBottom: 80
  },
  resendText: {
    fontSize: 14,
    fontWeight: "500"
  },
  resendOtpText: {
    fontSize: 14,
    fontWeight: "500",
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  footerContainer: {
    alignItems: "center"
  },
  button: {
    height: 50,
    width: 304
  },
  resendOtpContainer: {
    flexDirection: "row",
    marginTop: 10
  },
  resendTextAlign: {
    marginLeft: 5
  },
  resendOtpTextDisable: {
    fontSize: 14,
    fontWeight: "500",
    color: colorPallete.lightGreyTwo
  },
  counterText: {
    fontSize: 14,
    fontWeight: "500",
    color: colorPallete.cordioRed
  }
});
