import React, { ReactElement, useState } from "react";
import { Alert, StyleSheet, Touchable, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import moment from "moment";
import { ScrollContainer } from "@socion-cordio/common/src/components/organisms/scrollWrapper";
// const profile = require("@socion-cordio/common/src/assets/images/profile.png");

const styles = StyleSheet.create({
  otpContainer: {
    marginTop: 20,
    marginBottom: 40,
    marginRight: 112
  },
  message: {
    fontSize: 12,
    fontFamily: FontFamily.Bold,
    borderRightColor: "#675850"
  },
  container: {
    flexDirection: "row",
    marginBottom: 10
  },
  textIcon: {
    color: colorPallete.cordioTaupe,
    fontSize: 12,
    fontFamily: "Montserrat-Regular",
    marginRight: 30
  },
  mainContainer: {
    width: "100%",
    backgroundColor: colorPallete.white,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 14,
    paddingRight: 14,
    borderRadius: 10,
    flexDirection: "row"
  },
  imageStyles: {
    width: 90,
    height: 90,
    borderRadius: 10,
    marginRight: 14
  },
  blankContainer: {
    width: 90
  },
  subContainer: {
    flex: 1
    // marginLeft: 14
  }
});

interface IProps {
  attestationData?: any;
  isUiChanged?: boolean;
}

export default function SessionInfo(props: IProps): ReactElement {
  const { attestationData, isUiChanged } = props;

  const renderSessionInfo = (title: any, value: any, isPrimaryUi?: boolean) => {
    return (
      <View style={isPrimaryUi ? { flexDirection: "column" } : { flexDirection: "row" }}>
        <View style={{ width: "35%", paddingRight: 5 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="forgot"
            textSize={TextSize.Small}
            textStyle={styles.message}
          >
            {title}
          </Text>
        </View>
        <ScrollContainer style={{ flex: 3, maxHeight: 100 }}>
          <Text
            fontWeight={FontWeight.Regular}
            testId="forgot"
            textSize={TextSize.Small}
            textStyle={[styles.message, { fontFamily: FontFamily.Regular }]}
          >
            {value}
          </Text>
        </ScrollContainer>
      </View>
    );
  };

  const renderSessionInfoSecondaryUi = () => (
    <View style={styles.mainContainer}>
      {(attestationData?.topicInfo?.program?.programLogo !== null ||
        attestationData?.topicInfo?.program?.programLogo !== "") && (
        <View style={styles.blankContainer} />
      )}
      <View style={styles.subContainer}>
        {renderSessionInfo("Session Name", attestationData?.sessionName)}
        {attestationData?.attestationDate &&
          renderSessionInfo(
            "Attestation Issue Date",
            moment(attestationData?.attestationDate).format("DD - MMM - YYYY")
          )}
        {renderSessionInfo("Description", attestationData?.sessionDescription)}
      </View>
    </View>
  );

  const renderSessionInfoPrimaryUi = () => (
    <View style={[styles.mainContainer, { height: 200 }]}>
      {(attestationData?.topicInfo?.program?.programLogo !== null ||
        attestationData?.topicInfo?.program?.programLogo !== "") && (
        <View>
          <Image
            source={attestationData?.topicInfo?.program?.programLogo}
            testId={"attestation-image"}
            imageStyle={styles.imageStyles}
          />
        </View>
      )}
      <View style={styles.subContainer}>
        {renderSessionInfo("Entity", attestationData?.topicInfo?.program?.entityName, true)}
        {renderSessionInfo("Program", attestationData?.topicInfo?.program?.name, true)}
        {/* {renderSessionInfo("Description", attestationData?.sessionDescription, true)} */}
      </View>
    </View>
  );

  return isUiChanged ? renderSessionInfoPrimaryUi() : renderSessionInfoSecondaryUi();
}
