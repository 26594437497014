import React, { ReactElement, useState } from "react";
import { Alert, StyleSheet, TouchableOpacity, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import _ from "lodash";

// const profile = require("@socion-cordio/common/src/assets/images/profile.png");

const styles = StyleSheet.create({
  message: {
    fontSize: 12,
    fontFamily: FontFamily.Medium,
    color: colorPallete.textBlack
  },
  container: {
    overflow: "hidden",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 14,
    paddingBottom: 12,
    paddingTop: 12,
    paddingRight: 14
  },
  mainContainer: {
    backgroundColor: colorPallete.white,
    borderRadius: 10
  },
  divider: {
    border: "1px solid #675850",
    opacity: 0.1,
    marginRight: 14,
    marginLeft: 14
  }
});

interface IProps {
  contents?: any;
  name?: String;
  role?: String;
  email?: String;
  location?: String;
  attestationData?: any;
}

export default function ContentList(props: IProps): ReactElement {
  const { attestationData } = props;

  const onDownloadPress = (url: any, name: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
      .catch(console.error);
  };

  const renderContentList = (item: any, index: any, array: any) => {
    return (
      <View>
        <View style={styles.container}>
          <View style={{ width: 250 }}>
            <Text
              fontWeight={FontWeight.Regular}
              testId="forgot"
              textSize={TextSize.Small}
              textStyle={[styles.message, { marginBottom: 5 }]}
            >
              {item.name}
            </Text>
            <Text
              fontWeight={FontWeight.Regular}
              testId="forgot"
              textSize={TextSize.Small}
              textStyle={[
                styles.message,
                { marginBottom: 5, color: colorPallete.cordioTaupeLight2 }
              ]}
            >
              {item.size}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Icon
              testID="close"
              name={IconNames.downloadContent}
              size={20}
              onPress={() => onDownloadPress(item?.url, item?.name)}
              color={"#675850"}
              customStyle={{
                paddingHorizontal: 14,
                borderRightColor: "#675850"
              }}
            />
          </View>
        </View>
        {index === array.length - 1 ? null : <View style={styles.divider} />}
      </View>
    );
  };

  return (
    <View style={styles.mainContainer}>
      {attestationData?.topicInfo?.content?.length === 0 ? (
        <Text
          fontWeight={FontWeight.Regular}
          testId="forgot"
          textSize={TextSize.Small}
          textStyle={[styles.message, { paddingHorizontal: 14, paddingVertical: 5 }]}
        >
          No content
        </Text>
      ) : (
        attestationData?.topicInfo?.content?.map((item: any, index: any, array: any) => {
          return renderContentList(item, index, array);
        })
      )}
    </View>
  );
}
