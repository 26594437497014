import React, { ReactElement, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Loader from "@socion-cordio/common/src/components/atoms/loader";
import { useTranslation } from "react-i18next";
import { Image } from "@socion-cordio/common/src/components/atoms/image";

interface Props {
  data?: any;
}

export default function UserCardData(props: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { data } = props;

  return isLoading ? (
    <Loader />
  ) : (
    <View>
      <View style={{ marginTop: 41 }}>
        <View style={styles.container}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ marginLeft: 14, marginTop: 20 }}>
              <Image
                source={
                  data?.photo === ""
                    ? require("@socion-cordio/common/src/assets/images/user_circle.svg")
                    : { uri: data?.photo }
                }
                testId={"profile-image"}
                imageStyle={{ width: 90, height: 90, borderRadius: 10 }}
              />
            </View>
            <View
              style={{
                flexDirection: "column",
                marginLeft: 14,
                justifyContent: "space-between"
              }}
            >
              <View>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="forgot"
                  textSize={TextSize.Small}
                  textStyle={[styles.message, { marginBottom: 5, marginTop: 20, width: "70%" }]}
                >
                  {data?.name}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="forgot"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.message,
                    {
                      fontFamily: "Montserrat-Regular",
                      fontSize: 12,
                      color: colorPallete.textLight
                    }
                  ]}
                >
                  {data?.emailId}
                </Text>
                <Text
                  fontWeight={FontWeight.Regular}
                  testId="forgot"
                  textSize={TextSize.Small}
                  textStyle={[
                    styles.message,
                    {
                      fontFamily: "Montserrat-Regular",
                      fontSize: 12,
                      color: colorPallete.textLight
                    }
                  ]}
                >
                  {data?.city}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headerConatiner: {
    backgroundColor: "#B7504A",
    height: 135,
    paddingRight: 16
  },
  logoStyle: {
    color: colorPallete.white,
    marginTop: 10,
    marginLeft: "auto"
  },
  partnerContainer: {
    marginTop: 4,
    marginRight: 55,
    marginLeft: "auto"
  },
  textIcon: {
    color: colorPallete.white,
    fontSize: 10,
    fontFamily: FontFamily.Light
  },
  headingContainer: {
    position: "absolute",
    top: 45,
    paddingLeft: 16,
    width: "100%",
    paddingRight: 16
  },
  headingStyles: {
    color: colorPallete.white,
    fontSize: 18,
    fontFamily: FontFamily.Bold
  },
  bodyStyles: {
    backgroundColor: "#EEE5DA",
    zIndex: -1,
    paddingLeft: 16,
    paddingRight: 16,
    height: "100vh",
    paddingTop: 80
  },
  contentText: {
    color: colorPallete.cordioTaupe,
    fontSize: 16,
    fontFamily: FontFamily.Bold
  },
  otpContainer: {
    marginTop: 20,
    marginBottom: 40,
    marginRight: 112
  },
  message: {
    fontSize: 14,
    fontFamily: "Montserrat-Bold",
    paddingRight: 20
  },
  container: {
    borderWidth: 0,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    overflow: "hidden",
    height: 130
  }
});
