import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "store/interfaces";
import { BADGES } from "@socion-cordio/common/src/modules/badges/actions/actionTypes";
import { BadgeTypes } from "@socion-cordio/common/src/models/badgeType";
import { BadgesActions } from "@socion-cordio/common/src/modules/badges/actions/actions";
import { BadgesRepository } from "@socion-cordio/common/src/repositories/badges/badges";

function* getBadgeTypesWorkerSaga(value: IFluxStandardAction<{ programId: number }>) {
  try {
    const { payload } = value;
    const response: BadgeTypes = yield call(BadgesRepository.getBadgesTypes, payload);
    yield put(BadgesActions.getBadgeTypesSuccess(response));
  } catch (error) {
    yield put(BadgesActions.getBadgeTypesError(error));
  }
}

export default function* BadgesWatcherSaga() {
  yield takeLatest(BADGES.GET_BADGES_TYPES.LOADING, getBadgeTypesWorkerSaga);
}
