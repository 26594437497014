import Moment from "moment";

const formatDate = (date: string, format?: string) => {
  let dateFormat = format ? format : "DD MMM YY";
  return Moment(date).format(dateFormat);
};

const formatDateYear = (date: string, format?: string) => {
  let dateFormat = format ? format : "DD-MMM-YYYY";
  return Moment(date).format(dateFormat);
};

const formatDateTime = (date: string, format?: string) => {
  let dateFormat = format ? format : "DD MMM YY, hh:mm a";
  return Moment(date).format(dateFormat);
};

const formatDateTimeUtc = (date: string, format?: string) => {
  var converted_date_data = Moment(date).format("DD MMM YYYY HH:mm:ss");
  return Moment(Date.parse(converted_date_data + " UTC")).format("DD MMM YYYY hh:mm A");
};

const formatLicenseUsedDateTime = (date: string, format?: string) => {
  var converted_date_data = Moment(date).format("DD MMM YYYY HH:mm:ss");
  return Moment(Date.parse(converted_date_data)).format("DD MMM YYYY hh:mm A");
};

const capitalize = (str: string) => {
  if (typeof str !== "string") {
    return "";
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

const isEmptyArray = (array: any) => {
  if (!Array.isArray(array)) {
    return true;
  }
  if (array.length == 0) {
    return true;
  }
  return false;
};

export const dataHelper = {
  formatDate,
  capitalize,
  isEmptyArray,
  formatDateTime,
  formatDateTimeUtc,
  formatDateYear,
  formatLicenseUsedDateTime
};
