import { call, put, all, take, takeLatest, fork, takeEvery } from "redux-saga/effects";
import { PROFILE } from "@socion-cordio/common/src/modules/profile/actions/actionTypes";
import { ProfileActions } from "@socion-cordio/common/src/modules/profile/actions/actions";
import { ProfileRepository } from "@socion-cordio/common/src/repositories/profile/profile";
import { Profile } from "@socion-cordio/common/src/models/profile";
import { UserProfile } from "@socion-cordio/common/src/models/userProfile";
import { UserRole } from "@socion-cordio/common/src/models/userRole";
import { UserQualification } from "@socion-cordio/common/src/models/userQualification";
import { IFluxStandardAction } from "store/interfaces";
import { EligibleProgramsForRole } from "@socion-cordio/common/src/models/eligibleProgramsForRole";
import { EligibleProgramsForQualification } from "@socion-cordio/common/src/models/eligibleProgramsForQualification";
import { EligibleProgramsForBadges } from "@socion-cordio/common/src/models/eligibleProgramsForBadges";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { toast } from "react-toastify";

function* getProfileWorkerSaga(value: IFluxStandardAction<{ emailId: string }>) {
  try {
    const { payload } = value;
    const response: Profile = yield call(ProfileRepository.fetchProfile, payload);
    yield put(ProfileActions.getProfileSuccess(response));
  } catch (error) {
    yield put(ProfileActions.getProfileError(error));
  }
}

function* getUserProfileWorkerSaga() {
  try {
    const response: UserProfile = yield call(ProfileRepository.getUserProfile);
    if (
      response?.message === "User not found with this EmailId." ||
      response.responseCode === 401
    ) {
      updateTelemetry();
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/new/iam/login`;
      LocalStorage.removeStoredKeys();
    } else if (!window.location.href?.includes("/new/iam/login")) {
      LocalStorage.setStorage("updatedProfileInfo", response?.response);
      yield put(ProfileActions.getUserProfileSuccess(response));
    }
  } catch (error) {
    yield put(ProfileActions.getUserProfileError(error));
  }
}

function* updateProfileWorkerSaga(value: IFluxStandardAction<Profile>) {
  try {
    const { payload } = value;
    const response: Profile = yield call(ProfileRepository.updateProfile, payload);
    yield put(ProfileActions.updateProfileSuccess(response));
  } catch (error) {
    yield put(ProfileActions.updateProfileError(error));
  }
}
function* fileUploadWorkerSaga(value: IFluxStandardAction<FormData>) {
  try {
    const { payload } = value;
    const response: { message: string } = yield call(ProfileRepository.uploadFile, payload);
    yield put(ProfileActions.uploadFileSuccess(response.message));
  } catch (error) {
    yield put(ProfileActions.uploadFileError(error));
  }
}
function* getUserRoleWorkerSaga() {
  try {
    const response: UserRole = yield call(ProfileRepository.getUserRole);
    yield put(ProfileActions.getUserRoleSuccess(response));
  } catch (error) {
    yield put(ProfileActions.getUserRoleError(error));
  }
}

function* getUserQualificationWorkerSaga() {
  try {
    const response: UserQualification = yield call(ProfileRepository.getUserQualification);
    yield put(ProfileActions.getUserQualificationSuccess(response));
  } catch (error) {
    yield put(ProfileActions.getUserQualificationError(error));
  }
}

function* getUserEligibleProgramsForRoleWorkerSaga(
  value: IFluxStandardAction<{ acceptingType: string }>
) {
  try {
    const { payload } = value;
    const response: EligibleProgramsForRole = yield call(
      ProfileRepository.getUserEligiblePrgramsForRole,
      payload
    );
    yield put(ProfileActions.getUserEligibleProgramsForRoleSuccess(response));
  } catch (error) {
    yield put(ProfileActions.getUserEligibleProgramsForRoleError(error));
  }
}

function* getUserEligibleProgramsForQualificationWorkerSaga(
  value: IFluxStandardAction<{ acceptingType: string }>
) {
  try {
    const { payload } = value;
    const response: EligibleProgramsForQualification = yield call(
      ProfileRepository.getUserEligiblePrgramsForQualification,
      payload
    );
    yield put(ProfileActions.getUserEligibleProgramsForQualificationSuccess(response));
  } catch (error) {
    yield put(ProfileActions.getUserEligibleProgramsForQualificationError(error));
  }
}

function* getUserEligibleProgramsForBadgesWorkerSaga(
  value: IFluxStandardAction<{ acceptingType: string }>
) {
  try {
    const { payload } = value;
    const response: EligibleProgramsForBadges = yield call(
      ProfileRepository.getUserEligibleProgramsForBadges,
      payload
    );
    yield put(ProfileActions.getUserEligibleBadgeProgramSuccess(response));
  } catch (error) {
    yield put(ProfileActions.getUserEligibleBadgeProgramError(error));
  }
}

const updateTelemetry = async () => {
  const user: any = await LocalStorage.getStorage("user");
  const body = {
    timestamp: Moment().toLocaleString(),
    createdAt: Moment().toLocaleString(),
    deleted: "false",
    updatedAt: Moment().toLocaleString(),
    eventType: "Logout",
    id: user?.userId
  };
  AddTelemetryService(body, undefined, user);
};

export default function* profileWatcherSaga() {
  // while (true) {
  // const value = yield take(PROFILE.GET_PROFILE);
  // yield call(getProfileWorkerSaga, value);
  yield takeLatest(PROFILE.GET.LOADING, getProfileWorkerSaga);
  yield takeLatest(PROFILE.GET_USER_PROFILE.LOADING, getUserProfileWorkerSaga);
  yield takeLatest(PROFILE.UPDATE.LOADING, updateProfileWorkerSaga);
  yield takeLatest(PROFILE.FILE_UPLOAD.LOADING, fileUploadWorkerSaga);
  yield takeLatest(PROFILE.GET_USER_ROLE.LOADING, getUserRoleWorkerSaga);
  yield takeLatest(PROFILE.GET_USER_QUALIFICATION.LOADING, getUserQualificationWorkerSaga);
  yield takeLatest(
    PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_ROLE.LOADING,
    getUserEligibleProgramsForRoleWorkerSaga
  );
  yield takeLatest(
    PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_QUALIFICATION.LOADING,
    getUserEligibleProgramsForQualificationWorkerSaga
  );
  yield takeLatest(
    PROFILE.GET_USER_ELIGIBLE_PROGRAMS_FOR_BADGES.LOADING,
    getUserEligibleProgramsForBadgesWorkerSaga
  );
  // }
}
