import { call, put, takeLatest } from "redux-saga/effects";
import {
  EXTERNALREDIRECTION,
  PROGRAMS,
  SELECTED_PROGRAM_DETAILS
} from "@socion-cordio/common/src/modules/program/actions/actionTypes";
import { ProgramActions } from "@socion-cordio/common/src/modules/program/actions/actions";
import { ProgramRepository } from "@socion-cordio/common/src/repositories/program/program";
import { Program } from "@socion-cordio/common/src/models/program";
import { EntityInvite } from "@socion-cordio/common/src/models/entityInvite";
import { LicenseAndPricing } from "@socion-cordio/common/src/models/licensePricing";
import { LicenseAndPricingCredits } from "@socion-cordio/common/src/models/licenseModelCredits";
import { LicenseAndPricingDebits } from "@socion-cordio/common/src/models/licenseModelDebits";
import { LicenseAndPricingAll } from "@socion-cordio/common/src/models/licenseModelAll";
import { IFluxStandardAction } from "store/interfaces";

function* getProgramWorkerSaga(value: IFluxStandardAction<{ allProgramsVal: boolean }>) {
  try {
    const { payload } = value;
    const response: Program = yield call(ProgramRepository.getPrograms, payload);
    yield put(ProgramActions.getProgramsSucess(response));
  } catch (error) {
    yield put(ProgramActions.getProgramsError(error));
  }
}

function* getEntityInviteWorkerSaga() {
  try {
    const response: EntityInvite = yield call(ProgramRepository.getEntityInvite);
    yield put(ProgramActions.getEntityInviteSuccess(response));
  } catch (error) {
    yield put(ProgramActions.getEntityInviteError(error));
  }
}

function* getLicenseAndPricingWorkerSaga(value: IFluxStandardAction<{ programId: number }>) {
  try {
    const { payload } = value;
    const response: LicenseAndPricing = yield call(ProgramRepository.getLicenseAndPricing, payload);
    yield put(ProgramActions.getLicenseAndPricingSuccess(response));
  } catch (error) {
    yield put(ProgramActions.getLicenseAndPricingError(error));
  }
}

function* getLicenseAndPricingCreditsWorkerSaga(value: IFluxStandardAction<{ licenseId: number }>) {
  try {
    const { payload } = value;
    const response: LicenseAndPricingCredits = yield call(
      ProgramRepository.getLicenseAndPricingCredits,
      payload
    );
    yield put(ProgramActions.getLicenseAndPricingCreditsSuccess(response));
  } catch (error) {
    yield put(ProgramActions.getLicenseAndPricingCreditsError(error));
  }
}

function* getLicenseAndPricingDebitsWorkerSaga(value: IFluxStandardAction<{ licenseId: number }>) {
  try {
    const { payload } = value;
    const response: LicenseAndPricingDebits = yield call(
      ProgramRepository.getLicenseAndPricingDebits,
      payload
    );
    yield put(ProgramActions.getLicenseAndPricingDebitsSuccess(response));
  } catch (error) {
    yield put(ProgramActions.getLicenseAndPricingDebitsError(error));
  }
}

function* getLicenseAndPricingAllWorkerSaga(value: IFluxStandardAction<{ licenseId: number }>) {
  try {
    const { payload } = value;
    const response: LicenseAndPricingAll = yield call(
      ProgramRepository.getLicenseAndPricingAll,
      payload
    );
    yield put(ProgramActions.getLicenseAndPricingAllSuccess(response));
  } catch (error) {
    yield put(ProgramActions.getLicenseAndPricingAllError(error));
  }
}

function* getNewSessionAddedWorkerSaga(value: any) {
  try {
    yield put(ProgramActions.getExternalRedirectionDataSuccess(value.payload));
  } catch (error) {}
}

function* getSelectedProgramDetailsWorkerSaga(value: any) {
  try {
    yield put(ProgramActions.getSelectedProgramDataSuccess(value.payload));
  } catch (error) {
    // yield put(AttestationsActions.getExternalTaskError(error));
  }
}

export default function* ProgramWatcherSaga() {
  yield takeLatest(PROGRAMS.GET_PROGRAMS.LOADING, getProgramWorkerSaga);
  yield takeLatest(PROGRAMS.GET_ENTITY_INVITE.LOADING, getEntityInviteWorkerSaga);
  yield takeLatest(PROGRAMS.GET_LICENSE_AND_PRICING.LOADING, getLicenseAndPricingWorkerSaga);
  yield takeLatest(
    PROGRAMS.GET_LICENSE_AND_PRICING_CREDITS.LOADING,
    getLicenseAndPricingCreditsWorkerSaga
  );
  yield takeLatest(
    PROGRAMS.GET_LICENSE_AND_PRICING_DEBITS.LOADING,
    getLicenseAndPricingDebitsWorkerSaga
  );
  yield takeLatest(PROGRAMS.GET_LICENSE_AND_PRICING_ALL.LOADING, getLicenseAndPricingAllWorkerSaga);
  yield takeLatest(
    EXTERNALREDIRECTION.GET_EXTERNAL_REDIRECTION.LOADING,
    getNewSessionAddedWorkerSaga
  );
  yield takeLatest(
    SELECTED_PROGRAM_DETAILS.GET_SELECTED_PROGRAM_DETAILS.LOADING,
    getSelectedProgramDetailsWorkerSaga
  );
}
