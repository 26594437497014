import React, { useRef } from "react";
import { useField } from "formik";
import {
  TextInput,
  View,
  StyleSheet,
  StyleProp,
  TextStyle,
  ViewStyle,
  Pressable
} from "react-native";
import Icon, { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import {
  FontWeight,
  Label,
  TextSize,
  Text,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import PlatformUtils from "@socion-cordio/common/src/utils/platformUtils";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { useTogglePasswordVisibility } from "../../utils/passwordVisibilityHook";

interface IProps {
  defaultValue?: any;
  disabled?: boolean;
  editable?: boolean;
  enteredValue?: any;
  errorStyle?: StyleProp<TextStyle>;
  fieldStyle?: StyleProp<ViewStyle>;
  focusStyle?: {};
  formikPropsTouched?: boolean;
  id: string;
  inputStyle?: StyleProp<TextStyle>;
  isNumeric?: boolean;
  isSecure?: boolean;
  label: string;
  labelStyle?: StyleProp<TextStyle>;
  multiline?: boolean;
  name: string;
  noFormik?: boolean;
  numberOfLines?: number;
  onChange?: any;
  placeholder?: string;
  search?: boolean;
  styling?: Object;
  textIconStyles?: StyleProp<TextStyle>;
  textValue: string;
  textValueIcon?: IconNames;
  toggler?: boolean;
  userStyle?: {};
  value?: any;

  customChange?: Function;
  handleBlur?: Function;
  handleChange?: Function;
  handleKeyPress?: Function;
  manageFocus?: (arg: {}) => void;
}
export const PasswordField = ({
  label,
  placeholder = "",
  id = "",
  isSecure = false,
  disabled = false,
  handleBlur,
  handleChange,
  value,
  enteredValue,
  textValue,
  textValueIcon,
  textIconStyles,
  userStyle,
  onChange,
  ...props
}: IProps): React.ReactElement => {
  const {
    labelStyle,
    fieldStyle,
    manageFocus,
    focusStyle,
    styling,
    toggler = false,
    search = false,
    inputStyle,
    errorStyle,
    handleKeyPress,
    multiline,
    numberOfLines,
    noFormik,
    editable,
    isNumeric = false,
    defaultValue,
    formikPropsTouched
  } = props;

  let field, meta, hasError, error;
  if (!noFormik) {
    [field, meta] = useField(props);
    hasError = meta.touched && meta.error;
    error = meta.error;
  }

  const { passwordVisibility, handlePasswordVisibility, rightIcon } = useTogglePasswordVisibility();
  const inputRef = useRef<TextInput>(null);
  const onClickFocus = () => {
    toggler && inputRef.current?.focus();
  };

  onClickFocus();

  const onFocus = () => {
    // search && (PlatformUtils.isWeb() ? manageFocus(styling) : manageFocus(styling)); // same is happening in both conditions
    search && PlatformUtils.isWeb() && manageFocus(styling);
  };
  return (
    <View style={[userStyle]}>
      <View>
        {textValue && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginVertical: 10
            }}
          >
            <Icon
              testID="home"
              name={textValueIcon}
              //   customStyle={[styles.icon, iconStyle]}
            />
            <Text
              fontWeight={FontWeight.Regular}
              testId="internal"
              textSize={TextSize.Small}
              style={[styles.textIcon, textIconStyles]}
            >
              {" "}
              {textValue}
              {""}
            </Text>
          </View>
        )}
      </View>
      <View style={styles.container}>
        {/* {!search && (
        <Label
          testId={id}
          textSize={TextSize.Small}
          fontWeight={FontWeight.Regular}
          textStyle={[styles.label, labelStyle]}
        >
          {label}
        </Label>
      )} */}
        <View style={styles.elementsContainer}>
          <TextInput
            nativeID={id}
            ref={inputRef}
            placeholder={placeholder}
            secureTextEntry={passwordVisibility}
            style={
              search
                ? toggler && PlatformUtils.isWeb
                  ? focusStyle
                  : [fieldStyle, focusStyle]
                : [styles.inputBox, inputStyle]
            }
            editable={editable}
            value={value}
            multiline={multiline}
            numberOfLines={numberOfLines}
            defaultValue={defaultValue}
            onChangeText={(e) => {
              // CUSTOM HANDLE CHANGE GOES hERE console.log(e);
              if (props.customChange) {
                props.customChange(e);
              }
              if (e.trim()?.length == 0) {
                e = e.trim();
              }

              let data = e.replace(/[^0-9]/g, "");
              return handleChange(isNumeric ? data : e);
            }}
            onBlur={handleBlur(props.name)}
            onFocus={() => onFocus()}
            onKeyPress={(e) => handleKeyPress && handleKeyPress(e)}
            onChange={onChange}
            autoCompleteType="off"
          />
          <Pressable onPress={handlePasswordVisibility}>
            <Icon
              testID="passwordEye"
              customStyle={[
                { paddingRight: 5 },
                rightIcon === IconNames.passwordHidden ? { fontSize: 12 } : { fontSize: 16 }
              ]}
              name={(rightIcon as IconNames.passwordVisible) || IconNames.passwordHidden}
            />
          </Pressable>
          {error && (formikPropsTouched === undefined ? true : formikPropsTouched) ? (
            <Text
              testId={id}
              textSize={TextSize.Small}
              fontWeight={FontWeight.Regular}
              textStyle={[styles.error, errorStyle]}
            >
              {(formikPropsTouched === undefined ? true : formikPropsTouched) && error}
            </Text>
          ) : null}
        </View>
      </View>
    </View>
  );
};
export default PasswordField;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    width: 304,
    height: 60
  },
  elementsContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderWidth: 1,
    borderColor: colorPallete.cordioTaupeLight3,
    backgroundColor: colorPallete.white,
    borderRadius: 5
  },
  label: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15
  },
  textinput_focused_mobile: {
    width: "100%",
    height: 50
  },
  inputBox: {
    outlineWidth: 0,
    paddingLeft: 10,
    fontFamily: FontFamily.Regular,
    height: 38
  },
  error: {
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1
    // marginTop: 20
  },
  textIcon: {
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    marginLeft: 5
  }
});
