import React, { ReactElement, useEffect, useState, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import {
  Text,
  TextSize,
  FontWeight,
  FontFamily
} from "@socion-cordio/common/src/components/atoms/text";
import Button, { ButtonType } from "@socion-cordio/common/src/components/atoms/button";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AesUtil from "@socion-cordio/common/src/utils/encryptionHelper";
import { HTTP_STATUS_CODES } from "@socion-cordio/common/src/network/constants";
import WrapperForm from "@socion-cordio/common/src/components/organisms/signup/signupNew/wrapperForm";
import { SignupRepository } from "@socion-cordio/common/src/repositories/signup/signup";
import UserInput from "@socion-cordio/common/src/components/molecules/userInput";
import { IconNames } from "@socion-cordio/common/src/components/atoms/icon";
import MobileInput from "@socion-cordio/common/src/components/molecules/mobileInput";
import { Checkbox, CheckboxType } from "@socion-cordio/common/src/components/atoms/checkBox";
import { useTranslation } from "react-i18next";
import { LocalStorage } from "@socion-cordio/common/src/services/storage/storageService";
import {
  setNewBearerToken,
  setNewUser,
  setSuperAdmin
} from "@socion-cordio/common/src/network/authHelper";
import Moment from "moment";
import AddTelemetryService from "@socion-cordio/common/src/services/telemetryService";
import { LoginActions } from "@socion-cordio/common/src/modules/login/actions/actions";
import { useDispatch } from "react-redux";
import { allRoutesNames as routeNames } from "@socion-cordio/web/src/navigation/allRouteNames";
interface Props {
  fetchSignupOtp?: Function;
  history?: any;
}

export default function SignupFormNewDetails(props: Props): ReactElement {
  const history: any = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState({ terms: false, consent: false });
  const [countryCode, setCountryCode] = useState(null);
  const [dropdownPlaceHolder, setDropdownPlaceHolder] = useState(null);
  const [password, setPassword] = useState(null);
  const [reEnterPassword, setReEnterPassword] = useState(null);
  const [customError, setCustomError] = useState({ password: "", reEnterPassword: "" });
  const [showValidation, setShowValidation] = useState(false);
  const [emailVerification, setEmailVerification] = useState(true);
  const [updateddropdownCountryCode, setUpdateddropdownCountryCode] = useState(91);
  const [dropdownVal, setdropdownVal] = useState(0);

  useEffect(() => {
    setCountryCode(location?.state?.countryCode);
    setDropdownPlaceHolder(location?.state?.countryCode);
    setUpdateddropdownCountryCode(location?.state?.countryCode);
    setdropdownVal(location?.state?.countryCode);
  }, [location]);

  const saveSignupDetails = async () => {
    let aesUtil: AesUtil = new AesUtil();
    // setShowValidation(true);
    let passwordError, reEnteredPasswordError;
    passwordError = password == null || password == "" ? "Please enter password" : "";

    reEnteredPasswordError =
      reEnterPassword === null || reEnterPassword === ""
        ? "Please confirm your password"
        : reEnterPassword !== password && password != null && reEnterPassword !== null
        ? "Passwords entered don't match"
        : "";
    setCustomError({
      ...customError,
      password: passwordError,
      reEnterPassword: reEnteredPasswordError
    });

    if (passwordError.length !== 0 || reEnteredPasswordError.length !== 0) {
      return;
    }

    if (checkboxValue.consent && checkboxValue.terms) {
      if (customError.password == "" && customError.reEnterPassword == "") {
        setLoading(true);
        let payload: any = {
          userName: location?.state?.userName,
          password: aesUtil.encrypt(password),
          countryCode: location?.state?.countryCode,
          verificationType: location?.state?.verificationType
        };
        let response = SignupRepository.submitSignUpDetails(payload);
        response
          .then((res) => {
            if (res?.responseCode === HTTP_STATUS_CODES.ok) {
              // toast.success(
              //   "You have successfully signed up to PDA application. Please login to access the application."
              // );
              loginHandler(res?.response);
              setLoading(false);
              // history.push("/new/iam/login");
            } else {
              toast.error(res.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      }
    } else {
      toast.error("Please tick the checkboxes before you proceed.");
    }
  };

  const loginHandler = (loginData: any) => {
    dispatch(LoginActions.postCredentials(loginData?.accessTokenResponseDTO?.accessToken));
    setNewBearerToken(loginData?.accessTokenResponseDTO?.accessToken);
    setNewUser(loginData?.userDetails);
    getPayload(loginData?.accessTokenResponseDTO?.accessToken);
    LocalStorage.setStorage("countryCodeObj", location?.state?.selectedCountryObject);
    LocalStorage.setStorage(
      "countryCode",
      +location?.state?.selectedCountryObject.code.replace("+", "")
    );
    LocalStorage.setStorage("IsEmailVerification", location?.state?.isEmailVerification);
    updateTelemetry(loginData?.userDetails, "Login");
    updateTelemetry(loginData?.userDetails, "Sign-up");
    history.push(`${routeNames.app}${routeNames.workspace}`);
  };

  const updateTelemetry = async (userDetails: any, eventType: string) => {
    const body = {
      timestamp: Moment().toLocaleString(),
      createdAt: Moment().toLocaleString(),
      deleted: "false",
      updatedAt: Moment().toLocaleString(),
      eventType: eventType,
      id: userDetails?.userId
    };
    AddTelemetryService(body, undefined, userDetails);
  };

  function getPayload(token: string) {
    //var payload = window.atob(token.split('.')[1]);
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let payload = JSON.parse(
      decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    if (payload?.realm_access) {
      const { roles } = payload.realm_access;
      const isAdmin = roles.includes("admin");
      setSuperAdmin(isAdmin);
    }
  }

  return (
    <View>
      <WrapperForm
        footerText={t("common:alreadyHaveAnAccount")}
        footerTextType={t("common:loginText")}
        onPressFooterTextType={() => history.push("/new/iam/login")}
        component={
          <View style={styles.mainContainer}>
            <View style={styles.mainHeading}>
              <Text
                fontWeight={FontWeight.Regular}
                testId="program"
                textSize={TextSize.Regular}
                textStyle={styles.subHeading}
              >
                {t("common:pdaProgram")}
              </Text>
              <View style={{ marginTop: 10 }}>
                <Text
                  fontWeight={FontWeight.Bold}
                  testId="login"
                  textSize={TextSize.Large}
                  textStyle={{ fontSize: 26 }}
                >
                  {t("common:signUpSecondary")}
                </Text>
              </View>
            </View>
            <View style={styles.formContainer}>
              <UserInput
                label=""
                editable={false}
                handleChange={() => {}}
                handleBlur={() => {}}
                userStyle={styles.name}
                textIcon={styles.userInputStyleHeader}
                icon={IconNames.userName}
                textValue="Name"
                name="name"
                placeholder="Enter name"
                value={location?.state?.name}
                id="name"
                // handleKeyPress={(e: any) => {
                //   if (e.key === "Enter") {
                //     formikProps.handleSubmit();
                //   }
                // }}
                showEditIcon={false}
                noFormik
              />
              {location?.state?.verificationType === "PHONE_NUMBER" ? (
                <MobileInput
                  label=""
                  editable={false}
                  disableDropDown={true}
                  handleChange={() => {}}
                  handleBlur={() => {}}
                  userInputStyleHeader={styles.userInputStyleHeader}
                  onChangeValue={(value) => {}}
                  icon={IconNames.mobile}
                  textValue="Mobile number"
                  name="mobileNumber"
                  placeholder="Enter number"
                  value={location?.state?.userName}
                  id="mobileNumber"
                  updateddropdownCountryCode={updateddropdownCountryCode}
                  noFormik
                />
              ) : (
                <UserInput
                  label=""
                  editable={false}
                  handleChange={() => {}}
                  handleBlur={() => {}}
                  userStyle={styles.user}
                  textIcon={styles.userInputStyleHeader}
                  icon={IconNames.email}
                  textValue="Email Id"
                  name="email"
                  placeholder="Enter Email"
                  value={location?.state?.userName}
                  id="email"
                  showEditIcon={false}
                  noFormik
                />
              )}
              <View>
                <UserInput
                  label=""
                  handleChange={(value: any) => {
                    setPassword(value);
                    setCustomError({
                      ...customError,
                      password: ""
                    });
                  }}
                  handleBlur={() => {}}
                  userStyle={styles.user}
                  textIcon={styles.userInputStyleHeader}
                  icon={IconNames.password}
                  textValue="Enter password"
                  name="enterPassword"
                  placeholder="Enter password"
                  value={password}
                  id="enterPassword"
                  // handleKeyPress={(e: any) => {
                  //   if (e.key === "Enter") {
                  //     formikProps.handleSubmit();
                  //   }
                  // }}
                  isSecure
                  noFormik
                />
              </View>
              {customError.password !== "" ? (
                <View style={styles.widthContainer}>
                  <Text
                    testId={"1"}
                    textSize={TextSize.Small}
                    fontWeight={FontWeight.Regular}
                    textStyle={[styles.error]}
                  >
                    {customError.password}
                  </Text>
                </View>
              ) : null}
              <UserInput
                label=""
                handleChange={(value: any) => {
                  setReEnterPassword(value);
                  setCustomError({
                    ...customError,
                    reEnterPassword: ""
                  });
                }}
                handleBlur={() => {}}
                userStyle={styles.user}
                textIcon={styles.userInputStyleHeader}
                icon={IconNames.password}
                textValue="Re-enter password"
                name="reEnterPassword"
                placeholder="Re-enter password"
                value={reEnterPassword}
                id="reEnterPassword"
                // handleKeyPress={(e: any) => {
                //   if (e.key === "Enter") {
                //     formikProps.handleSubmit();
                //   }
                // }}
                isSecure
                noFormik
              />

              {customError.reEnterPassword !== "" ? (
                <View style={styles.widthContainer}>
                  <Text
                    testId={"1"}
                    textSize={TextSize.Small}
                    fontWeight={FontWeight.Regular}
                    textStyle={[styles.error]}
                  >
                    {customError.reEnterPassword}
                  </Text>
                </View>
              ) : null}

              <View style={styles.checkBoxContainer}>
                <View style={styles.termsContainer}>
                  <Checkbox
                    testId=""
                    selected={checkboxValue.terms}
                    type={CheckboxType.Square}
                    text=""
                    textSize={TextSize.Large}
                    textFontWeight={FontWeight.Regular}
                    iconSize={15}
                    onPress={() =>
                      setCheckboxValue({ ...checkboxValue, terms: !checkboxValue.terms })
                    }
                  />
                  <Text
                    testId={"1"}
                    textSize={TextSize.Small}
                    fontWeight={FontWeight.Regular}
                    textStyle={styles.checkBoxTextStyles}
                  >
                    {"I Accept the "}
                    <a
                      style={{ textDecorationLine: "none" }}
                      href="/new/terms-of-use"
                      target="_blank"
                    >
                      {"Terms of Use"}
                    </a>
                  </Text>
                </View>
                <View style={styles.consentContainer}>
                  <Checkbox
                    testId=""
                    selected={checkboxValue.consent}
                    type={CheckboxType.Square}
                    text=""
                    textSize={TextSize.Large}
                    textFontWeight={FontWeight.Regular}
                    iconSize={15}
                    onPress={() =>
                      setCheckboxValue({ ...checkboxValue, consent: !checkboxValue.consent })
                    }
                    iconStyle={styles.checkBoxStyle}
                  />
                  <Text
                    testId={"1"}
                    textSize={TextSize.Small}
                    fontWeight={FontWeight.Regular}
                    textStyle={styles.checkBoxTextStyles}
                  >
                    I Consent to share my profile and participation information with the Programs I
                    am associated with
                  </Text>
                </View>
              </View>
              <Button
                type={ButtonType.Primary}
                buttonStyles={styles.button}
                title="Create account"
                // onPress={() => {
                //   formikProps.handleSubmit();
                // }}
                onPress={saveSignupDetails}
              />
            </View>
          </View>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: colorPallete.white,
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
    // marginTop: 30
  },
  mainHeading: {
    alignItems: "center",
    marginTop: 10
    // marginLeft: 80
  },
  heading: {
    fontSize: 26,
    marginTop: 5,
    marginBottom: 5
  },
  subHeading: {
    fontSize: 18
  },
  loaderContiner: {},
  formContainer: {
    width: "100%",
    alignItems: "center",
    marginTop: 30
  },
  button: {
    marginTop: 10,
    height: 50,
    width: 304,
    zIndex: -1,
    marginBottom: 10
  },
  name: {
    marginBottom: 10,
    marginTop: 0
  },
  user: {
    marginBottom: 5,
    marginTop: 10
  },
  mobile: {
    marginBottom: 0,
    marginTop: 10
  },
  forgot: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1
  },
  login: {
    fontWeight: "400",
    fontSize: 12,
    position: "relative",
    zIndex: -1,
    textDecorationLine: "underline",
    color: colorPallete.cordioRed
  },
  termsOfUseLink: {
    alignItems: "center",
    display: "flex"
  },
  widthContainer: {
    width: 304,
    alignItems: "flex-start"
  },
  checkBoxContainer: {
    width: 304,
    alignItems: "flex-start",
    marginTop: 10
  },
  userInputStyleHeader: {
    fontFamily: FontFamily.Bold,
    color: colorPallete.textBlack,
    marginLeft: 5
  },
  checkboxText: {
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  termsOfUseCheckbox: {
    alignItems: "center",
    display: "flex",
    fontSize: 14,
    fontFamily: FontFamily.Regular
  },
  checkBoxTextStyles: {
    fontSize: 12,
    marginLeft: 10,
    lineHeight: 18
  },
  termsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10
  },
  consentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  checkBoxStyle: {
    marginTop: 3
  },
  error: {
    position: "relative",
    color: "red",
    alignSelf: "flex-start",
    fontSize: 10,
    fontWeight: "400",
    zIndex: -1,
    marginTop: "0px"
  }
});
