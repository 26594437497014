import React, { ReactElement } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@socion-cordio/common/src/assets/styles/colors";
import { Text, TextSize, FontWeight } from "@socion-cordio/common/src/components/atoms/text";
import { Image } from "@socion-cordio/common/src/components/atoms/image";
// const profile = require("@socion-cordio/common/src/assets/images/profile.png");

const styles = StyleSheet.create({
  otpContainer: {
    marginTop: 20,
    marginBottom: 40,
    marginRight: 112
  },
  message: {
    fontSize: 14,
    fontFamily: "Montserrat-Bold",
    paddingRight: 20
  },
  container: {
    borderWidth: 0,
    borderRadius: 10,
    backgroundColor: colorPallete.white,
    overflow: "hidden",
    height: 130
  }
});

interface IProps {
  attestationData?: any;
}

export default function UserCard(props: IProps): ReactElement {
  const { attestationData } = props;

  const capitalize = (str: string) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <View style={styles.container}>
        <View style={{ flexDirection: "row" }}>
          <View style={{ marginLeft: 14, marginTop: 20 }}>
            <Image
              source={
                attestationData?.user?.photo ||
                require("@socion-cordio/common/src/assets/images/user_circle.svg")
              }
              testId={"profile-image"}
              imageStyle={{ width: 90, height: 90, borderRadius: 10 }}
            />
          </View>
          <View
            style={{
              flexDirection: "column",
              marginLeft: 14,
              justifyContent: "space-between"
            }}
          >
            <View>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={[styles.message, { marginBottom: 5, marginTop: 20 }]}
              >
                {attestationData?.user?.name}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={[
                  styles.message,
                  {
                    fontFamily: "Montserrat-Regular",
                    fontSize: 12,
                    color: colorPallete.textLight
                  }
                ]}
              >
                {attestationData?.user?.emailId}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={[
                  styles.message,
                  {
                    fontFamily: "Montserrat-Regular",
                    fontSize: 12,
                    color: colorPallete.textLight
                  }
                ]}
              >
                {capitalize(attestationData?.role?.toLowerCase())}
              </Text>
              <Text
                fontWeight={FontWeight.Regular}
                testId="forgot"
                textSize={TextSize.Small}
                textStyle={[
                  styles.message,
                  {
                    fontFamily: "Montserrat-Regular",
                    fontSize: 12,
                    color: colorPallete.textLight
                  }
                ]}
              >
                {attestationData?.user?.city}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}
