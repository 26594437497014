import { ActionHelper } from "@socion-cordio/common/src/utils/actionHelper";

const prefix = "bundle";
const BUNDLE = {
  GET_BUNDLE: ActionHelper.actionTypesCreator(prefix, "GET_BUNDLE")
};
const QUALFICATIONPACKLIST = {
  GET_QUALIFICATIONPACKLIST: ActionHelper.actionTypesCreator(prefix, "GET_QUALIFICATIONPACKLIST")
};

export { BUNDLE, QUALFICATIONPACKLIST };
